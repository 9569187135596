import React from 'react'
import { Alert, AlertIcon, 
    Box, Button, Flex, Icon, Spacer,
    Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, 
    Spinner, Text, Tooltip, useToast, 
    List,
    ListItem} from '@chakra-ui/react';
import { useBrandBg, useTextColor } from '../../theme/globalColorTheme';

const DocAISampleEmail = ({onEmailDrawerClose}) => {
    const brandBg = useBrandBg();

    return(
        <Flex w={'100%'}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth="1px">Translation Email Help</DrawerHeader>
                <DrawerBody>
                    <Flex w={'100%'} 
                        rounded={'lg'}
                        boxShadow={'lg'}
                        direction={'column'}
                        p={2} borderWidth={'0.5px'} borderColor={'gray.200'}>
                        <Text fontWeight="300"><strong>To:</strong> {"translate@" + window.location.hostname}</Text>
                        <Text fontWeight="300"><strong>Subject:</strong> Translate my documents</Text>
                        <Text mt={3}>
                            <strong>Body:</strong>
                            My access code is <strong>xxxxxxxx</strong>. <br />
                            Please translate my document from English to German and Spanish.
                            <br/>
                            Add proofreading for my file file1.pdf and file2.pdf does not need proofreading.
                        </Text>
                        <Text mt={3}> <strong>Attachments:</strong> file1.pdf, file2.pdf</Text>
                    </Flex>
                    <Flex w={'100%'} 
                        mt={2}
                        boxShadow={'lg'}
                        rounded={'lg'}
                        direction={'column'}
                        p={2} borderWidth={'0.5px'} borderColor={'gray.200'}>
                        <Text fontWeight="300"><strong>To:</strong> {"translate@" + window.location.hostname}</Text>
                        <Text fontWeight="300"><strong>Subject:</strong> Translate paragrah</Text>
                        <Text mt={3}>
                            <strong>Body:</strong>
                            My access code is <strong>xxxxxxxx</strong>. <br />
                            Please translate the following paragraph from English to German.
                            <br/>
                            <strong>Write your paragraph here...</strong>
                            <br/>
                            Change the translation tone to technical and make use of common words instead of complex. 
                        </Text>
                    </Flex>
                    <Flex w={'100%'} 
                        mt={5}
                        boxShadow={'lg'}
                        rounded={'lg'}
                        direction={'column'}
                        p={2} borderWidth={'0.5px'} borderColor={'gray.200'}>
                        <Text mb={4} fontWeight={700}>Document Translation Notes:</Text>
                        <List>
                            <ListItem>1. You must add Translation in your subject</ListItem>
                            <ListItem>2. You must add user access code in your email body.</ListItem>
                            <ListItem>3. You can upto 3 attachments and maximum 3 target languages.</ListItem>
                            <ListItem>4. If you want proofreading for your attached files, please specify for each file with the file name.</ListItem>
                        </List>
                    </Flex>
                </DrawerBody>
                <DrawerFooter>
                <Button variant="outline" mr={3} onClick={onEmailDrawerClose}>
                    Close
                </Button>
                </DrawerFooter>
            </DrawerContent>
        </Flex>
    )

}

export default DocAISampleEmail
