export const lsaData = {
    serviceTypes: [
        {
            id: 1,
            code: "S1"
        },
        {
            id: 2,
            code: "S2"
        }
    ],
    genders: [
        {
            id: 1,
            name: "M"
        },
        {
            id: 2,
            name: "F"
        }
    ]
}


export const lsaLangArr = [
    {
        id: 1,
        codef: "en",
        englishName: "English"
    },
    {
        id: 2,
        codef: "hi",
        englishName: "Hindi"
    }
]