import React, { useState, useEffect, useRef } from 'react';
import { useToast } from '@chakra-ui/react';
import { UserAgent, Registerer } from 'sip.js';

import { toastFunctionToaster } from '../toastFunction';

import Dailer from './components/Dailer';
// import AudioDeviceSelector from './components/AudioDeviceSelector';
import CallHandler from './components/CallHandler';

import { handleDTMF } from './lingoletSipHandlers/dtmfHandler';
import { acceptCall, rejectCall, makeCall, hangUpCall } from './lingoletSipHandlers/sipCallManager'; 
import { updateCallProgress } from './lingoletSipHandlers/sipMiscellaneousHandler';

/**
 * Custom hook to handle SIP client operations.
 * 
 * This hook requires a configuration object and returns several methods and states for handling SIP calls.
 * The configuration object should contain:
 * 
 * ```js
 * const CONFIG = {
 *   uri: 'sip:user1@192.168.1.36:8088',    // SIP URI (Change domain as needed)
 *   transportOptions: {
 *       server: 'ws://192.168.1.36:8088/ws',  // WebSocket server URI (Change domain as needed)
 *    },
 *   authorizationUsername: 'user1',                     // SIP Username
 *   authorizationPassword: 'user1',                     // SIP Password
 *   parameters: {
 *     codecPreferences: ['PCMU', 'opus']   // List of preferred codecs
 *   }
 * }
 * ```
 * 
 * Returns an object containing the following functions and states:
 * 
 * ### Functions:
 * - `startSip`: Starts the SIP client.
 * - `stopSip`: Stops the SIP client.
 * - `makeSipCall(destination: string)`: Initiates a SIP call to the specified `destination`.
 *    - **Example:** `makeSipCall('sip:user2@192.168.1.36:8088')`
 * - `hangUpSipCall`: Hangs up the current call.
 * - `acceptIncomingCall`: Accepts an incoming call.
 * - `rejectIncomingCall`: Rejects an incoming call.
 * - `sendDtmfTone(tone: string)`: Sends a DTMF tone to the current call.
 * 
 *  ### UI Components:
 * - `DialerUI`: A pre-built UI component for dialing calls.  
 *   - Displays a dial pad and controls for making, hanging up, and sending DTMF tones.
 * 
 * - `IncomingCallHandlerUI`: A pre-built UI component for handling incoming calls.  
 *   - Displays information about an incoming call with options to accept or reject it.
 * 
 * ### States:
 * - `session`: The current active SIP session.
 * - `incomingCall`: The incoming call details, if any.
 * - `isRegistered`: A boolean indicating whether the SIP client is registered.
 * - `isCallConnected`: A boolean indicating whether the current call is connected.
 * - `isCallConnecting`: A boolean indicating whether the current call is in the connecting state.
 * - `logMessages`: An array of log messages related to the SIP client.
 * - `dtmfHistory`: An array of DTMF (Dual-tone multi-frequency) signals sent during the current session.
 * - `userAgent`: The SIP User Agent instance.
 * 
 * ### Ref:
 * - `remoteAudio`: A ref that should be attached to an `<audio>` element for remote audio playback.
 * 
 * @param {Object} config - Configuration object for the SIP client.
 * @param {string} config.uri - SIP URI.
 * @param {string} config.transportOptions.server - WebSocket server URI.
 * @param {string} config.authorizationUsername - SIP Username.
 * @param {string} config.authorizationPassword - SIP Password.
 * @param {Object} config.parameters - Additional parameters like codec preferences.
 * @param {Array<string>} config.parameters.codecPreferences - List of preferred codecs for the call.
 * 
 * @returns {Object} An object containing the functions and states for the SIP client.
 * 
 * @example
 * const {
 *   startSip,
 *   stopSip,
 *   makeSipCall,
 *   hangUpSipCall,
 *   acceptIncomingCall,
 *   rejectIncomingCall,
 *   sendDtmfTone,
 *   DialerUI,
 *   IncomingCallHandlerUI,
 *   session,
 *   incomingCall,
 *   isRegistered,
 *   isCallConnected,
 *   isCallConnecting,
 *   logMessages,
 *   dtmfHistory,
 *   remoteAudio,
 *   userAgent
 * } = useSipClient(CONFIG);
 */
const useSipClient = (config) => {
    
    const [userAgent, setUserAgent] = useState(null);
    const [registerer, setRegisterer] = useState(null);

    const [session, setSession] = useState(null);                               
    const [incomingCall, setIncomingCall] = useState(null);

    const [isRegistered, setIsRegistered] = useState(false);                    
    const [isCallConnecting, setIsCallConnecting] = useState(false);            
    const [isCallConnected, setIsCallConnected] = useState(false);    

    const [logMessages, setLogMessages] = useState([]);                         
    const [dtmfHistory, setDtmfHistory] = useState([]);   

    const [inputDevice, setInputDevice] = useState(null);           
    const [outputDevice, setOutputDevice] = useState(null);

    const toast = useToast();

    const remoteAudio = useRef(null);                                           
    const isMounted = useRef(true);



    // Handle incoming calls
    const handleIncomingCall = (incomingSession) => {
        try {

            updateCallProgress(incomingSession, setIsCallConnecting, setIsCallConnected, setSession, setLogMessages, remoteAudio, toast);

            setSession(incomingSession);
            
            setIncomingCall({
                callerId: incomingSession.remoteIdentity.uri.normal.user || "Unknown Caller",
                session: incomingSession
            });           

        } catch (error) {
            console.error('Error accepting call:', error);
            setLogMessages((prev) => [...prev, `Error accepting call: ${error.message}`]);
            toast(
                toastFunctionToaster(
                    'Error accepting call.',
                    'error'
                )
            );
        }
    };

    const makeSipCall = async (destination) => {
        await makeCall(userAgent, inputDevice, remoteAudio, destination, toast, setLogMessages, setSession, updateCallProgress, setIsCallConnecting, setIsCallConnected);
    }

    const hangUpSipCall = () => {
        hangUpCall(session, setSession, setIsCallConnected, setLogMessages, setDtmfHistory, toast);
    }

    const acceptIncomingCall = async () => {
        await acceptCall(incomingCall, inputDevice, remoteAudio, setIncomingCall, setLogMessages, toast);
    }

    const rejectIncomingCall = () => {
        rejectCall(incomingCall, setIncomingCall, setLogMessages, toast);
    }

    const sendDtmfTone = (tone) => {
        handleDTMF(session, isCallConnected, tone, setLogMessages, setDtmfHistory, toast);
    }

    const selectOutputDevice = (deviceId) => {
        setOutputDevice(deviceId);
    }

    const selectInputDevice = (deviceId) => {
        setInputDevice(deviceId);
    }

    const DialerUI = () => {
        return (
            <Dailer
            onDial={makeSipCall}
            hangUpCall={hangUpSipCall}
            onDTMF={sendDtmfTone}
            disabled={!isRegistered}
            isCallConnecting={isCallConnecting}
            isCallConnected={isCallConnected}
            />
        )
    }

    const IncomingCallHandlerUI = () => {
        return (
            <CallHandler 
            call={incomingCall}
            onAccept={acceptIncomingCall}
            onReject={rejectIncomingCall}
            />
        );
    }

    const handleConfig = () => {
        config = JSON.parse(config);
        if (typeof config !== "object" || config === null || Array.isArray(config)) {
            console.error('Please provide a valid configuration object.');
            toast(
                toastFunctionToaster(
                    'Please provide a valid configuration object.',
                    'error'
                )
            )
            return;
        }
        const requiredProperties = [
            'uri', 
            'transportOptions', 
            'authorizationUsername', 
            'authorizationPassword',
          ];
        
        requiredProperties.forEach(property => {
            if (!(property in config)) {
                toast(toastFunctionToaster(`Missing required property: '${property}'`, 'error'));
                return;
            }
        });
    
        if (typeof config.uri !== "string" || !config.uri.trim()) {
            toast(toastFunctionToaster("Invalid config: 'uri' must be a non-empty string.", "error"));
            return;
        }
    
        if (typeof config.transportOptions !== "object" || config.transportOptions === null || Array.isArray(config.transportOptions)) {
            toast(toastFunctionToaster("Invalid config: 'transportOptions' must be a valid object.", "error"));
            return;
        }
        if (typeof config.transportOptions.server !== "string" || !config.transportOptions.server.trim()) {
            toast(toastFunctionToaster("Invalid config: 'server' in 'transportOptions' must be a non-empty string.", "error"));
        }
    
        if (typeof config.authorizationUsername !== "string" || !config.authorizationUsername.trim()) {
            toast(toastFunctionToaster("Invalid config: 'authorizationUsername' must be a non-empty string.", "error"));
            return;
        }
    
        if (typeof config.authorizationPassword !== "string" || !config.authorizationPassword.trim()) {
            toast(toastFunctionToaster("Invalid config: 'authorizationPassword' must be a non-empty string.", "error"));
            return;
        }
    
        if (config.parameters !== undefined && (typeof config.parameters !== "object" || Array.isArray(config.parameters))) {
            toast(toastFunctionToaster("Invalid config: 'parameters' must be an object.", "error"));
            return;
        }
    
        return config;      

    }

    const initializeUserAgent = () => {
        try {        
            const delegate = {
                // Handles successful registration
                onRegister: () => {
                    console.log('SIP Registration Successful');
                    setLogMessages((prev) => [...prev, 'SIP Registration Successful']);
                    setIsRegistered(true);
                },
            
                // Handles deregistration (when disconnected or deregistered)
                onDisconnect: (error) => {
                    if (error) {
                        console.error('Transport error on disconnect:', error);
                        setLogMessages((prev) => [...prev, `Transport error: ${error.message}`]);
                        toast(
                        toastFunctionToaster(
                            'Transport error, please check your connection settings.',
                            'error'
                            )
                        );
                    }
                    console.log('SIP Unregistered');
                    setLogMessages((prev) => [...prev, 'SIP Unregistered']);
                    setIsRegistered(false);
                },
            
                // Handles incoming invitation (e.g., incoming call)
                onInvite: (invitation) => {
                    console.log('Incoming Call Received');
                    setLogMessages((prev) => [...prev, 'Incoming Call Received']);
                    handleIncomingCall(invitation);
                },
            
                // Handle registration failures
                onRegisterRequest: (response) => {
                    console.error('Registration failed:', response);
                    setLogMessages((prev) => [
                        ...prev,
                        `Registration failed: ${response.message || 'Unknown error'}`,
                    ]);
                    toast(
                        toastFunctionToaster(
                        'Registration failed, please check your credentials or network connection.',
                        'error'
                        )
                    );
                },
            
                // Handles call invite failures
                onInviteRequest: (error) => {
                console.error('Call invite failed:', error);
                setLogMessages((prev) => [...prev, `Call invite failed: ${error.message}`]);
                toast(
                    toastFunctionToaster(
                    'Call invite failed, please try again.',
                    'error'
                    )
                );
                },
            };

            // config validation
            const CONFIG = handleConfig();

            if (!CONFIG) {
                toast(toastFunctionToaster('Invalid configuration.','error'));
                return;
            }
                
            
            const uri = UserAgent.makeURI(CONFIG.uri);
            if (!uri) {
                console.error('Invalid URI', uri);
                toast(
                    toastFunctionToaster(
                    'Invalid URI.',
                    'error'
                    )
                );
                return;
            }

            const config = {
                uri: uri,
                transportOptions: {
                    server: CONFIG.transportOptions.server,
                },
                authorizationUsername: CONFIG.authorizationUsername,
                authorizationPassword: CONFIG.authorizationPassword,
                delegate: delegate,
                parameters: CONFIG.parameters
            };

            const UA = new UserAgent(config);

            const registerer = new Registerer(UA);
            
            return {
                UA,
                registerer
            }

        } catch (error) {
            console.error('Error initializing UserAgent:', error);
            setLogMessages((prev) => [...prev, `Error initializing user agent: ${error.message}`]);
            toast(
                toastFunctionToaster(
                    'Failed to initialize SIP client. Please check your configuration and try again.',
                    'error'
                )
            );
            return null;
        }
               
    };


    const startUserAgent = (ua, registerer) => {
        if (!ua || !registerer) {
            console.error('UserAgent or Registerer is not initialized.');
            toast(
                toastFunctionToaster(
                'UserAgent or Registerer is not initialized.',
                'error'
                )
            );
            return;
        }

        ua.start().then( async () => {
            if (isMounted.current) {
                console.log('UserAgent started');
                setUserAgent(ua);
                toast(
                    toastFunctionToaster(
                    'SIP initialized.',
                    'success'
                    )
                );

                await registerer.register();
                setRegisterer(registerer);
                setIsRegistered(true);
            }
        }).catch((error) => {
            if (isMounted.current) {
                console.error('Failed to start UserAgent:', error);
                setLogMessages((prev) => [...prev, 'Failed to start user agent.']);
                toast(
                    toastFunctionToaster(
                    'Failed to start user agent.',
                    'error'
                    )
                );
            }
        });
    }

    const stopUserAgent = () => {
        try {
            if (userAgent) {
                userAgent.stop();
                setUserAgent(null);
                setRegisterer(null);
                setIsRegistered(false);
            } else {
                console.error('UserAgent is not initialized.');
                toast(
                    toastFunctionToaster(
                    'UserAgent is not initialized.',
                    'error'
                    )
                )
            }
        } catch (error) {
            console.error('Failed to stop UserAgent:', error);
            toast(toastFunctionToaster('Failed to stop user agent.','error'));
        }
    }


    const startSip = () => {
        const userAgentObject = initializeUserAgent();

        if (!userAgentObject) {
            console.error('Failed to initialize user agent.');
            toast(toastFunctionToaster('Failed to initialize user agent.','error'));
            return;
        }

        const { UA, registerer } = userAgentObject;
        startUserAgent(UA, registerer);
    };

    const stopSip = () => {
        stopUserAgent();
    }
    
    useEffect(() => {

        const audioElement = document.createElement("audio");
        const audioId = `sip-audio-${Date.now()}`;
        audioElement.id = audioId;
        audioElement.style.display = "none";
        document.body.appendChild(audioElement);

        remoteAudio.current = audioElement;

        return () => {

            if (audioElement) {
                const element = document.getElementById(audioId);
                if (element) {
                    document.body.removeChild(audioElement);
                }
            }
            remoteAudio.current = null;

            isMounted.current = false;
            stopSip();
        };
    }, []);


    return {
        startSip,
        stopSip,
        makeSipCall,
        hangUpSipCall,
        acceptIncomingCall,
        rejectIncomingCall,
        sendDtmfTone,
        DialerUI,
        IncomingCallHandlerUI,
        userAgent,
        session,
        incomingCall,
        isRegistered,
        isCallConnected,
        isCallConnecting,
        logMessages,
        dtmfHistory,
        remoteAudio,
    }

    // return (
    //     <Flex
    //     p={2}
    //     mx="auto"
    //     mt={4}
    //     bg="white"
    //     boxShadow="lg"
    //     borderRadius="lg"
    //     textAlign="center"
    //     transition="all 0.3s ease"
    //     pt={{ sm: "125px", lg: "75px" }}
    //     >        
    //         <Fade in={isRegistered}>
    //             <Text fontSize="lg" mb={4} color={isRegistered ? 'green.500' : 'red.500'}>
    //                 Status: {isRegistered ? 'Registered' : 'Not Registered'}
    //             </Text>
    //         </Fade>
    
    //         {/* Audio element for remote audio */}
    //         <audio ref={remoteAudio} autoPlay />
    
    //         {/* Layout for Dialer and Logs side by side */}
    //         <Flex direction="row" gap={4} mb={2}>
    //             {/* Device Selector */}
    //             <Flex>
    //                 <AudioDeviceSelector 
    //                     inputDevice={inputDevice}
    //                     setInputDevice={setInputDevice}
    //                     outputDevice={outputDevice}
    //                     setOutputDevice={setOutputDevice}
    //                     audioRef={remoteAudio}
    //                 />
    //             </Flex>

    //             {/* Dialer component */}
    //             <Flex>
    //                 <Dailer 
    //                 onDial={makeCall} 
    //                 hangUpCall={hangUpCall} 
    //                 onDTMF={handleDTMF} 
    //                 disabled={!isRegistered} 
    //                 isCallConnecting={isCallConnecting} 
    //                 isCallConnected={isCallConnected} 
    //                 />
    //             </Flex>

    //             {/* Vertical Separator */}
    //             <Box borderLeft="1px solid" borderColor="gray.300" h="100%" mx={4} />

    //             {/* Call Logs and DTMF History section (side by side) */}
    //             <Flex direction="column" gap={4}>
    //                 {/* Call Logs Section */}
    //                 <Flex direction="column" w="full">
    //                     <Text fontWeight="bold" mb={2}>Call Logs:</Text>
    //                     <Box
    //                         maxH={{ base: "120px", md: "200px", lg: "300px" }}
    //                         overflowY="auto"
    //                         p={2}
    //                         bg="gray.50"
    //                         borderRadius="md"
    //                         boxShadow="sm"
    //                         css={{
    //                             '&::-webkit-scrollbar': { width: '8px' },
    //                             '&::-webkit-scrollbar-thumb': {
    //                                 backgroundColor: 'rgba(0, 0, 0, 0.1)',
    //                                 borderRadius: '4px',
    //                                 transition: 'background-color 0.3s ease',
    //                             },
    //                             '&::-webkit-scrollbar-thumb:hover': {
    //                                 backgroundColor: 'rgba(0, 0, 0, 0.3)',
    //                             },
    //                             '&::-webkit-scrollbar-track': {
    //                                 backgroundColor: 'rgba(0, 0, 0, 0.1)',
    //                                 borderRadius: '4px',
    //                             },
    //                         }}
    //                     >
    //                         {logMessages.map((msg, index) => (
    //                             <Text key={index} fontSize="sm">{msg}</Text>
    //                         ))}
    //                     </Box>
    //                 </Flex>

    //                 {/* DTMF History Section */}
    //                 <Flex direction="column" w="full">
    //                     <Text fontWeight="bold" mb={2}>DTMF History:</Text>
    //                     <Box
    //                         maxH={{ base: "120px", md: "200px", lg: "300px" }}
    //                         overflowY="auto"
    //                         p={2}
    //                         bg="gray.50"
    //                         borderRadius="md"
    //                         boxShadow="sm"
    //                         css={{
    //                             '&::-webkit-scrollbar': { width: '8px' },
    //                             '&::-webkit-scrollbar-thumb': {
    //                                 backgroundColor: 'rgba(0, 0, 0, 0.1)',
    //                                 borderRadius: '4px',
    //                                 transition: 'background-color 0.3s ease',
    //                             },
    //                             '&::-webkit-scrollbar-thumb:hover': {
    //                                 backgroundColor: 'rgba(0, 0, 0, 0.3)',
    //                             },
    //                             '&::-webkit-scrollbar-track': {
    //                                 backgroundColor: 'rgba(0, 0, 0, 0.1)',
    //                                 borderRadius: '4px',
    //                             },
    //                         }}
    //                     >
    //                         {dtmfHistory.map((dtmf, index) => (
    //                             <Text key={index} fontSize="sm">
    //                                 {`Tone: ${dtmf.tone}, Time: ${dtmf.timestamp}`}
    //                             </Text>
    //                         ))}
    //                     </Box>
    //                 </Flex>
    //             </Flex>
    //         </Flex>

    
    //         {/* Call status */}
    //         <Fade in={isCallConnecting}>
    //             <Text fontSize="xl" fontWeight="bold" color="gray.600">
    //                 Call in progress...
    //             </Text>
    //         </Fade>

    //         <>
    //         {incomingCall && (
    //             <CallHandler
    //             call={incomingCall}
    //             onAccept={acceptCall}
    //             onReject={rejectCall}
    //             />
    //         )}
    //         </>
    //     </Flex>
    
    // );
};

export default useSipClient;