import React, { useState } from 'react'
import Card from '../../../../components/card/Card';
import { Badge, Box, Button, Tooltip, Flex, Icon, Image, Spacer, Text, useDisclosure } from '@chakra-ui/react';
import { AiOutlineCheckCircle, AiOutlineClockCircle, AiOutlineCopy, AiOutlineLink, AiOutlineStop, AiOutlineVideoCamera, AiTwotoneAudio } from 'react-icons/ai';
import { useBlackWhiteBg, useBlueButton, useBoxHoverHighlight, useBrandBg, useCardHeaderActiveBg, useCardHeaderNonActiveBg, useGradientColor, useGray500TextColor, useGrayShadeCardBg, useGrayShadeCardBorderBg, useGrayTextColor, useLinkColor, usePillButtonBg, usePillButtonHighLight, useTextColor } from '../../../../theme/globalColorTheme'
import moment from "moment";
import QRCode from 'react-qr-code';
import { Link, useNavigate } from 'react-router-dom';
import { formatSeconds } from '../../../../utils/TimeFunctions';
import LinkTags from './LinkTags';
import LinkPreferences from './LinkLanguagePreferences';
import LinkApp from './LinkApp';
import MultichannelBg from "../../../../assets/communicator/multichannelai.png";


const RenderDynamicLinks = ({ dynamicLinks, setModalQrCode, onOpenQr, setShowTooltip,
    availableLangs, subAppDetails, langPrefList, handleLink, type,
    accountId, locationId, isEnabled, onDrawerOpen, setLinkInfo }) => {

    const port_value = window.location.port ? ":" + window.location.port : '';
    const currentHost = window.location.hostname.includes("localhost")
        ? "https://lingolet.ai/"
        : window.location.protocol + "//" + window.location.hostname + port_value + "/";

    const launchManageLink = () => {
        if (window.location.pathname.includes('/account')) {
            return '/account/linkmanager';
        } else if (window.location.pathname.includes('/business')) {
            return '/business/linkmanager';
        }
        return null;
    }
    const navigate = useNavigate();

    return (

        <>
            {dynamicLinks !== null && dynamicLinks?.length > 0
                ? dynamicLinks?.map((item, index) => (


                    <Card
                        //bg={brandBg}
                        boxShadow={'base'}
                        key={index}
                        w={"100%"}
                        mb={2}
                        p="0px"
                        borderRadius='10px'
                    >

                        <Flex w={"100%"} direction={"column"} borderWidth={'0.5px'} borderColor={useGradientColor} borderRadius="10px" className="abc">
                            <Flex w={'100%'} p={2}
                                bg={item?.dead ? useCardHeaderNonActiveBg : useCardHeaderActiveBg}
                                //alignItems={'center'}
                                justifyContent={'space-between'}
                                roundedTop={'md'} borderBottomWidth={'0.5px'} borderColor={useGradientColor}>
                                <Text ml={1} fontSize={'lg'} mr={1}>{item?.title}</Text>
                                <Flex ml={5} w={'150px'} bg={item?.active ? 'green.100' : 'red.100'} rounded={'md'}>
                                    {item?.active === false || item?.dead
                                        ? <Box w={'150px'} color="black" align='center' rounded={'md'} borderWidth={'0.05px'} borderColor={'red.200'}>
                                            {item?.class === 1 ? <Text><Icon as={AiOutlineStop} color={"red.600"} mt={1} mr={1} /> Unlimited Link</Text> : null}
                                            {item?.class === 2 ? <Text><Icon as={AiOutlineStop} color={"red.600"} mt={1} mr={1} />Trial Link</Text> : null}
                                            {item?.class === 3 ? <Text><Icon as={AiOutlineStop} color={"red.600"} mt={1} mr={1} />Fixed Link</Text> : null}

                                            {item?.multichannel ? <Text><Icon as={AiOutlineStop} color={"red.600"} mt={1} mr={1} />Multichannel</Text> : null}
                                        </Box>
                                        : <Box w={'150px'} color="black" align='center' rounded={'md'} borderWidth={'0.05px'} borderColor={'green.200'}>
                                            {item?.class === 1 ? <Text><Icon as={AiOutlineCheckCircle} color={"gray.600"} mt={1} mr={1} />Unlimited Link</Text> : null}
                                            {item?.class === 2 ? <Text><Icon as={AiOutlineCheckCircle} color={"gray.600"} mt={1} mr={1} />Trial Link</Text> : null}
                                            {item?.class === 3 ? <Text><Icon as={AiOutlineCheckCircle} color={"gray.600"} mt={1} mr={1} />Fixed Link</Text> : null}
                                            {item?.multichannel ? <Text><Icon as={AiOutlineCheckCircle} color={"gray.600"} mt={1} mr={1} />Multichannel</Text> : null}
                                        </Box>}
                                </Flex>
                                {(item?.trial && item?.voiceCall !== 9999 && item?.videoCall !== 9999)
                                    ? <Badge ml={2} size={'xs'} color={useGrayTextColor} bg={'blue.100'}>Trial LingoLink</Badge> : null}
                                <Spacer />
                                {type === 'lingoCustomer' ? (
                                    <Button size="sm" bg={useBlueButton} borderRadius="5px"> Send Email </Button>
                                ) : (
                                    <>
                                        {isEnabled ? (
                                            <Button
                                                _hover={{ bg: usePillButtonHighLight, color: "black" }}
                                                color={useTextColor}
                                                bg={usePillButtonBg}
                                                onClick={() => {
                                                    navigate(launchManageLink(), {
                                                        state: {
                                                            linkUuid: item?.uuid,
                                                            linkCode: item?.code,
                                                        },
                                                    });
                                                }}
                                                size={"sm"}
                                                borderRadius={'10px'}>
                                                Manage Link
                                            </Button>
                                        ) : (
                                            <Button
                                                _hover={{ bg: usePillButtonHighLight, color: "black" }}
                                                color={useTextColor}
                                                bg={usePillButtonBg}
                                                onClick={() => {
                                                    console.log("clicked")
                                                    setLinkInfo(item)
                                                    onDrawerOpen()
                                                }}
                                                size={"sm"}
                                                borderRadius={'10px'}>
                                                Share Link
                                            </Button>
                                        )}
                                    </>
                                )}
                            </Flex>
                            <Flex w={'100%'} align={'start'}>
                                <Flex w={"100%"} direction={"column"} p={'10px'}>
                                    {(item?.trial && (item?.class === 2 || item?.class === 3))
                                        ? <Flex w={'100%'} direction={'column'}>
                                            <Flex w={"100%"} align={'start'}>
                                                <Text w={'200px'} align={'start'} color={useGray500TextColor}>
                                                    Link Start Time (UTC):
                                                </Text>
                                                <Text w={'300px'}>
                                                    {moment.utc(item?.starttime).local().format("MMM DD,YYYY,HH:mm a")}
                                                </Text>
                                                <Text w={'200px'} align={'start'} ml={2} color={useGray500TextColor}>
                                                    Link End Time (UTC):
                                                </Text>
                                                <Text w={'300px'}>
                                                    {moment.utc(item?.endtime).local().format("MMM DD,YYYY,HH:mm a")}
                                                </Text>
                                            </Flex>
                                            <Flex w={"100%"} mb="10px">
                                                <Text w={'200px'} align={'start'} color={useGray500TextColor}>
                                                    Link Assigned Time:
                                                </Text>
                                                <Text w={'300px'}>
                                                    {item?.hours} Hours
                                                </Text>
                                                <Text w={'200px'} align={'start'} ml={2} color={useGray500TextColor}>
                                                    Time Left:
                                                </Text>
                                                <Text>
                                                    {item?.time_left}
                                                </Text>
                                            </Flex>
                                        </Flex> : null}
                                    <Flex
                                        w={"100%"}
                                        p={1}
                                        direction={"column"}
                                        bg={useBlackWhiteBg}
                                        rounded={"md"}>
                                        <Flex w={"100%"} direction={"column"}>
                                            <Flex w={"100%"} mt={1} mb={0}>
                                                <Flex w={'90%'}>
                                                    <Text mt={2} mr={2} fontSize={"lg"} color={useLinkColor}>
                                                        {item?.url ? currentHost + item?.url : "No Url"}
                                                    </Text>
                                                </Flex>
                                                <Flex w={'10%'} align={'end'}>
                                                    <Tooltip
                                                        hasArrow
                                                        placement={"top"}
                                                        bg="teal.500"
                                                        onMouseEnter={() => setShowTooltip(true)}
                                                        onMouseLeave={() => setShowTooltip(false)}
                                                        color="white"
                                                        label="Copy Url">
                                                        <Box w={"35px"} ml={1}
                                                            mb={2}
                                                            _hover={{
                                                                //bg: usePillButtonHighLight,
                                                                cursor: "pointer",
                                                                color: 'black'
                                                            }}
                                                            color={useTextColor}
                                                            bg={usePillButtonBg}
                                                            pr={2} rounded={"md"}
                                                            mr={2}>
                                                            <Icon
                                                                onClick={() =>
                                                                    navigator.clipboard.writeText(currentHost + item?.url)
                                                                }
                                                                ml={2}
                                                                mt={1}
                                                                h={5}
                                                                w={5}
                                                                as={AiOutlineCopy}
                                                            />
                                                        </Box>
                                                    </Tooltip>
                                                    <Tooltip
                                                        hasArrow
                                                        placement={"top"}
                                                        bg="teal.500"
                                                        onMouseEnter={() => setShowTooltip(true)}
                                                        onMouseLeave={() => setShowTooltip(false)}
                                                        color="white"
                                                        label="Open Url in new Tab">

                                                        <a
                                                            p={1}
                                                            w={"35px"}
                                                            rounded={"md"}
                                                            mr={2}
                                                            align={"center"}
                                                            href={currentHost + item?.url}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            // style={{
                                                            //     display: "inline-block",
                                                            //     backgroundColor: usePillButtonBg,
                                                            //     color: useTextColor,
                                                            //     textDecoration: "none",
                                                            //     padding: "4px",
                                                            //     borderRadius: "6px",
                                                            // }}
                                                        >
                                                            <Icon
                                                                bg={usePillButtonBg}
                                                                ml={2}
                                                                // mt={1}
                                                                h={8} 
                                                                p={2}
                                                                rounded={'lg'}
                                                                _hover={{ cursor: "pointer" }}
                                                                w={8}
                                                                as={AiOutlineLink}
                                                            />
                                                        </a>


                                                    </Tooltip>
                                                </Flex>
                                            </Flex>
                                            <Flex
                                                w={"100%"}
                                                mt={1}
                                                mb={1}
                                                borderTopColor={useGradientColor}
                                                borderTopWidth={"0.5px"}>
                                                <Flex w={"90%"} mt={1}>
                                                    <Text mt={2} mr={2} fontSize={"lg"} color={useLinkColor}>
                                                        {item?.fullurl ? currentHost + item?.fullurl : "No Url"}
                                                    </Text>
                                                </Flex>
                                                <Flex w={"10%"} align={'end'} alignItems={'center'} mt={1}>
                                                    <Tooltip
                                                        hasArrow
                                                        placement={"top"}
                                                        bg="teal.500"
                                                        onMouseEnter={() => setShowTooltip(true)}
                                                        onMouseLeave={() => setShowTooltip(false)}
                                                        color="white"
                                                        label="Copy Url">
                                                        <Box
                                                            mt={1}
                                                            w={"35px"}
                                                            ml={1}
                                                            pr={2}
                                                            rounded={"md"}
                                                            mr={2}
                                                            mb={2}
                                                            _hover={{
                                                                //bg: usePillButtonHighLight,
                                                                cursor: "pointer",
                                                                color: 'black'
                                                            }}
                                                            color={useTextColor}
                                                            bg={usePillButtonBg}>
                                                            <Icon
                                                                onClick={() =>
                                                                    navigator.clipboard.writeText(currentHost + item?.fullurl)
                                                                }
                                                                ml={2}
                                                                mt={1}
                                                                h={5}
                                                                _hover={{ cursor: "pointer" }}
                                                                w={5}
                                                                as={AiOutlineCopy}
                                                            />
                                                        </Box>
                                                    </Tooltip>

                                                    <Tooltip
                                                        hasArrow
                                                        placement={"top"}
                                                        bg="teal.500"
                                                        onMouseEnter={() => setShowTooltip(true)}
                                                        onMouseLeave={() => setShowTooltip(false)}
                                                        color="white"
                                                        label="Open Url in new Tab">
                                                            <Box 
                                                                w={'30px'}>
                                                                <a
                                                                    // mt={1}
                                                                    p={1}
                                                                    w={"35px"}
                                                                    rounded={"md"}
                                                                    mr={2}
                                                                    align={"center"}
                                                                    href={currentHost + item?.fullurl}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    style={{
                                                                        display: "inline-block",
                                                                        backgroundColor: usePillButtonBg,
                                                                        color: useTextColor,
                                                                        textDecoration: "none",
                                                                        padding: "4px",
                                                                        borderRadius: "6px",
                                                                    }}
                                                                    onMouseEnter={(e) => {
                                                                        e.currentTarget.style.backgroundColor = usePillButtonHighLight;
                                                                        e.currentTarget.style.color = "black";
                                                                        e.currentTarget.style.cursor = "pointer";
                                                                    }}
                                                                    onMouseLeave={(e) => {
                                                                        e.currentTarget.style.backgroundColor = usePillButtonBg;
                                                                        e.currentTarget.style.color = useTextColor;
                                                                    }}
                                                                >
                                                                    <Icon
                                                                        bg={usePillButtonBg}
                                                                        ml={2}
                                                                        mt={1}
                                                                        p={2} rounded={'lg'}
                                                                        h={8}
                                                                        _hover={{ cursor: "pointer" }}
                                                                        w={8}
                                                                        as={AiOutlineLink}
                                                                    />
                                                                </a>
                                                            </Box>
                                                    </Tooltip>
                                                </Flex>
                                            </Flex>
                                            <Flex w={"100%"}>
                                                {(item?.trial && (item?.class === 2 || item?.class === 3)) ? (
                                                    item?.calls?.expired ? (
                                                        <Flex
                                                            w={"100%"}
                                                            bg={useBrandBg}
                                                            align={"center"}
                                                            rounded={"md"}
                                                            p={1}
                                                            borderWidth={"0.05px"}
                                                            borderColor={"gray.400"}>
                                                            <Box w={"250px"}>
                                                                <Text color={"red.700"}>Expired Trial Link</Text>
                                                            </Box>
                                                            <Flex w={"100%"} direction={"column"}>
                                                                <Box w={"100%"}>
                                                                    <Icon
                                                                        as={AiOutlineClockCircle}
                                                                        mr={1}
                                                                        mt={1}
                                                                        h={5}
                                                                        w={5}
                                                                    />{" "}
                                                                    <Text mr={1}>Time:</Text>
                                                                    <Text mr={1}>{formatSeconds(item?.calls?.calls[6].toFixed(2))}</Text>
                                                                    <Text mr={1}>| Used:</Text>
                                                                    <Text mr={1}>{formatSeconds(item?.calls?.calls[7].toFixed(2))}</Text>
                                                                    <Text mr={1}>| Remaining:</Text>
                                                                    <Text mr={1}>
                                                                        {item?.calls?.calls[8] > 0
                                                                            ? formatSeconds(
                                                                                item?.calls?.calls[8].toFixed(2)
                                                                            )
                                                                            : "00:00:00"}
                                                                    </Text>
                                                                </Box>
                                                                <Flex w={"100%"}>
                                                                    <Box w={"100%"}>
                                                                        <Icon
                                                                            as={AiTwotoneAudio}
                                                                            mr={1}
                                                                            mt={1}
                                                                            h={5}
                                                                            w={5}
                                                                        />{" "}
                                                                        <Text>
                                                                            Calls:{" "}
                                                                            {item?.calls?.calls[0]} | Made:{" "}
                                                                            {item?.calls?.calls[1]} | Remaining:{" "}
                                                                            {item?.calls?.calls[2]}
                                                                        </Text>
                                                                    </Box>
                                                                    <Box w={"100%"}>
                                                                        <Icon
                                                                            as={AiOutlineVideoCamera}
                                                                            mr={1}
                                                                            mt={1}
                                                                            h={5}
                                                                            w={5}
                                                                        />{" "}
                                                                        <Text>
                                                                            Calls:{" "}
                                                                            {item?.calls?.calls[3]} | Made:{" "}
                                                                            {item?.calls?.calls[4]} | Remaining:{" "}
                                                                            {item?.calls?.calls[5]}
                                                                        </Text>
                                                                    </Box>
                                                                </Flex>
                                                            </Flex>
                                                        </Flex>
                                                    ) : (
                                                        <Flex
                                                            w={"100%"}
                                                            align={"start"}
                                                            rounded={"md"}
                                                            p={1}
                                                        >
                                                            {/* <Box w={"250px"}>
                                                                <Text color={"green.700"} align={'start'}>Active Trial Link</Text>
                                                            </Box>
                                                            <Flex w={"100%"} direction={"column"} alignItems={'center'}>
                                                                <Flex w={"100%"} alignItems={'center'}>
                                                                    <Icon
                                                                        as={AiOutlineClockCircle}
                                                                        mr={1}
                                                                        mt={1}
                                                                        h={5}
                                                                        w={5}
                                                                    />
                                                                    <Text mr={1}>Time:</Text>
                                                                    <Text mr={1}>{formatSeconds(item?.calls?.calls[6].toFixed(2))}</Text>
                                                                    <Text mr={1}>| Used:</Text>
                                                                    <Text mr={1}>{formatSeconds(item?.calls?.calls[7].toFixed(2))}</Text>
                                                                    <Text mr={1}>| Remaining:</Text>
                                                                    <Text mr={1}>
                                                                        {item?.calls?.calls[8] > 0
                                                                            ? formatSeconds(
                                                                                item?.calls?.calls[8].toFixed(2)
                                                                            )
                                                                            : "00:00:00"}
                                                                    </Text>
                                                                </Flex>
                                                                <Flex w={"100%"} alignItems={'center'}>
																<Flex w={"100%"} alignItems={'center'}>
																	<Icon
																		as={AiTwotoneAudio}
																		mr={1}
																		h={5}
																		w={5}
																	/>{" "}
																	<Text mr={1}>Calls:</Text>
																	<Text mr={1}>{item?.calls?.calls[0]} | Made:</Text>
																	<Text mr={1}>{item?.calls?.calls[1]} | Remaining:</Text>
																	<Text mr={1}>{item?.calls?.calls[2]}</Text>
																</Flex>
																<Flex w={"100%"} alignItems={'center'}>
																	<Icon
																		as={AiOutlineVideoCamera}
																		mr={1}
																		h={5}
																		w={5}
																	/>
																	<Text mr={1}>Calls:</Text>
																	<Text mr={1}>{item?.calls?.calls[3]} | Made:</Text>
																	<Text mr={1}>{item?.calls?.calls[4]} | Remaining:</Text>
																	<Text mr={1}>{item?.calls?.calls[5]}</Text>
																</Flex>
															</Flex>
                                                            </Flex> */}
                                                        </Flex>
                                                    )
                                                ) : (
                                                    <Flex
                                                        w={"100%"}
                                                        bg={useBrandBg}
                                                        align={"center"}
                                                        rounded={"md"}
                                                        px={2}
                                                    >
                                                        {/* {item?.multichannel === false
													?<Text fontSize={"md"} color={"green.700"} fontWeight={'500'}>
														Not a trial/demo link.
													</Text>:null} */}
                                                    </Flex>
                                                )}
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                    <Flex w={'100%'} p={0} align={'start'}>
                                        <Flex w={'100%'} direction={'column'} bg={useGrayShadeCardBg} rounded={'lg'} p={1}
                                            borderWidth={'0.5px'} borderColor={useGrayShadeCardBorderBg}>
                                            <Flex align={'start'}>
                                                <LinkTags linkItem={item} accountId={accountId} locationId={locationId} reListLink={handleLink} isEnabled={isEnabled} />
                                            </Flex>
                                            {item?.multichannel
                                                ? <Flex w={'100%'} align={'center'} py={1}>
                                                    <Image h={'40px'} w={'40px'} borderRadius="5px" src={MultichannelBg} />
                                                    <Text ml={1} fontSize={'xl'}>Multichannel Lingolink</Text>
                                                </Flex>
                                                : <LinkApp
                                                    linkItem={item}
                                                    supportedApps={subAppDetails}
                                                    reListLink={handleLink} isEnabled={isEnabled} />}
                                            <LinkPreferences linkItem={item} reListLink={handleLink} availableLangs={availableLangs} langPrefList={langPrefList} isEnabled={isEnabled} />
                                        </Flex>
                                        <Flex minW={'300px'} maxW={'300px'} ml={1}
                                            rounded={'lg'} p={0} bg={useGrayShadeCardBg}
                                            borderWidth={'0.5px'} borderColor={useGrayShadeCardBorderBg}>
                                            <Flex maxW={"150px"}>
                                                <Flex w={"100%"} direction={"column"} p={4} alignItems={'center'} justifyContent={'end'}>
                                                    <Box
                                                        w={"100%"}
                                                        onClick={() => {
                                                            setModalQrCode(item?.url);
                                                            onOpenQr();
                                                        }}
                                                        _hover={{ cursor: "pointer", boxShadow: useBoxHoverHighlight, borderColor: "blue.600", borderWidth: "1px" }}
                                                        p={1}
                                                        borderColor={"gray.400"}
                                                        borderWidth={"1px"}
                                                        rounded={"md"}>
                                                        <QRCode
                                                            size={90}
                                                            style={{ height: "auto", maxWidth: "100", width: "100" }}
                                                            value={item?.url}
                                                            viewBox={`0 0 90 90`}
                                                        />
                                                    </Box>
                                                    <Text>QR(Url)</Text>
                                                </Flex>
                                            </Flex>
                                            <Flex maxW={"150px"}>
                                                <Flex w={"100%"} direction={"column"} p={4} alignItems={'center'} justifyContent={'end'}>
                                                    <Box
                                                        w={"100%"}
                                                        onClick={() => {
                                                            setModalQrCode(item?.fullurl);
                                                            onOpenQr();
                                                        }}
                                                        _hover={{ cursor: "pointer", boxShadow: useBoxHoverHighlight, borderColor: "blue.600", borderWidth: "1px" }}
                                                        p={1}
                                                        borderColor={"gray.400"}
                                                        borderWidth={"1px"}
                                                        rounded={"md"}>
                                                        <QRCode
                                                            size={90}
                                                            style={{ height: "auto", maxWidth: "100", width: "100" }}
                                                            value={item?.fullurl}
                                                            viewBox={`0 0 90 90`}
                                                        />
                                                    </Box>
                                                    <Text>QR(Url+Code)</Text>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Card>
                ))
                : null}
        </>
    )
}

export default RenderDynamicLinks