import React from "react";
import { useState, useEffect } from "react";

// Component Imports
import BoostHILingoSystem from "./components/HumanAIComponent/BoostHILingoSystem";
import LingoletHISystem from "./components/HumanAIComponent/LingoletHISystem";
import LSAHISystem from "./components/HumanAIComponent/LSAHISystem";

function LingoAppHome(props) {
	const [provider, selectedProvider] = useState(props?.providerName);

	useEffect(() => {
		selectedProvider(props?.providerName);
	}, [props?.providerName]);

	useEffect(() => {
		console.log(provider);
	}, [provider]);

	return <>
				{
					provider === "boostlingo" || provider === 100 || provider === '100'
						? <BoostHILingoSystem 
						sessionKey={props?.sessionKey} 
						launchKey={props?.launchKey} 
						selectedOption={props?.selectedOption} 
						verifyAccessCode={props?.verifyAccessCode} /> 

						: provider === "lsa" || provider === 200 || provider === '200'
						? <LSAHISystem 
						sessionKey={props?.sessionKey} 
						launchKey={props?.launchKey} 
						selectedOption={props?.selectedOption} 
						verifyAccessCode={props?.verifyAccessCode} /> 
						
						: provider === "lingolet" || provider === 300 || provider === '300'
						? <LingoletHISystem 
						sessionKey={props?.sessionKey} 
						launchKey={props?.launchKey} /> : null
				}
			</>;
}

export default LingoAppHome;
