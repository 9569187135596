import React, { useState, useEffect, useMemo } from 'react';
import {
    Text,
    Flex,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    IconButton,
    useToast,
    background,
    color,
} from '@chakra-ui/react';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import { BiDotsHorizontal } from "react-icons/bi";
import { dynamicLinkTags } from '../../../../services/businessServices';
import { toastFunctionToaster } from '../../../../utils/toastFunction';
import { useGreenBg, useRedBg, useBlueBg, useDummyBlueButtonTextColor } from '../../../../theme/globalColorTheme';
import { ClassNames } from '@emotion/react';

function LinkTags({ linkItem, accountId, locationId, reListLink, isEnabled }) {
    const toast = useToast()
    const greenBg = useGreenBg();
    const blueBg = useBlueBg();
    const redBg = useRedBg()
    const linkUuid = linkItem?.uuid;
    const [loading, setLoading] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [skillsKeyword, setSkillsKeyword] = useState([]);
    const linkTagsArr = linkItem?.tags ? linkItem.tags.split(',') : [];


    const onTagChange = (tags) => {
        setSkillsKeyword(tags);
    };

    useEffect(() => {
        if (linkTagsArr?.length) {
            setSkillsKeyword(linkTagsArr);
        }
    }, [linkItem]);

    const addTags = () => {
        setLoading(true)
        if (accountId !== null && locationId !== null && linkUuid !== null) {
            const obj = {
                link_uuid: linkUuid,
                keywords_string: skillsKeyword.join(','),
            };
            dynamicLinkTags(obj).then((res) => {
                setLoading(false)
                if (res?.status) {
                    reListLink()
                    toast(toastFunctionToaster(res?.message, "success"));
                    onClose()
                }
                else {
                    toast(toastFunctionToaster(res?.message, "error"));

                }
            }).catch((err) => {
                setLoading(false)

                toast(toastFunctionToaster(err?.message, "error"));

            })
        }
        else {
            setLoading(true)
            toast(toastFunctionToaster("Something went wrong", "error"));
        }

    };

    const onCloseModal = () => {
        onClose();
    };

    const renderTagsData = () => {
        return linkTagsArr?.length ? (
            linkTagsArr?.map((data, index) => (
                <Text px="2" py="1" bg={blueBg} color={useDummyBlueButtonTextColor} borderRadius={'5px'} mr="1" key={index}>{data}</Text>
            ))
        ) : (
            <Text mr="1" p="1" borderRadius={'5px'} borderWidth={'0.5px'} borderColor={'blue.100'}>No Tags Available</Text>
        );
    };

    return (
        <>
            <Flex mt="2">
                <Flex alignItems={'center'}>
                    <Text align={'start'} as="span" mr='2' w={'200px'} fontWeight={'bold'}>
                        Tags:
                    </Text>
                    {renderTagsData()}

                    <IconButton
                        borderRadius={'5px'}
                        size='sm'
                        fontSize={'22px'}
                        _hover={{
                            color: "white",
                            backgroundColor: "blue.300"
                        }}
                        ml='2'
                        bg={'blue.100'}
                        color="black"
                        // colorScheme='blue'
                        icon={<BiDotsHorizontal />}
                        onClick={onOpen}
                        isDisabled={!isEnabled}
                        display={isEnabled ? "inline-flex" : "none"}

                    />
                </Flex>

                <Modal isOpen={isOpen} onClose={onCloseModal}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Add Tag</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <TagsInput
                                style={{ width: '100%' }}
                                value={skillsKeyword}
                                onChange={onTagChange}
                                inputProps={{ placeholder: 'Enter Tab' }}
                                className='tags-input'
                            />
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="blue" borderRadius="5px" size="sm" mr={3} onClick={onCloseModal}>
                                Close
                            </Button>
                            <Button colorScheme="green" borderRadius="5px" size="sm" onClick={addTags} isDisabled={skillsKeyword?.length ? false : true}
                                isLoading={loading ? true : false}
                            >
                                Add Tags
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Flex>
        </>
    );
}

export default LinkTags;
