import { Box, Flex, Spinner, Text } from '@chakra-ui/react'
import React from 'react'
import { forwardRef } from 'react';
import { useEffect } from 'react';
import { AiFillInfoCircle } from 'react-icons/ai'
import "./VideoCallComp.css"

const VideoCallComp = forwardRef(function VideoCallComp(props, ref) {
    const { showVideosLoading, progressText, remoteVideoError, selectedSettings, demoHistoryTag,
        setSelectedValue, setDemoHistoryTag } = props
    const { localVideoRef, remoteVideoRef, streamRef } = ref
    async function getMicAndVideoStream() {
        const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true,
        });
        if (stream) {
            streamRef.current = stream
            const remoteVideoEle = document.getElementById("remoteVideoELE")
            if (localVideoRef.current) {
                localVideoRef.current.srcObject = stream;
                remoteVideoEle.srcObject = stream
            }
        } else {
            console.error("getUserMedia returned a null or undefined stream.");
        }
    };

    useEffect(() => {
        getMicAndVideoStream()
        return () => {

            if (streamRef.current) {
                streamRef.current.getAudioTracks().forEach(function (track) {
                    track.stop();
                });

                streamRef.current.getVideoTracks().forEach(function (track) {
                    track.stop();
                });

                streamRef.current = null;
            }
        }
    }, [])

    useEffect(() => {
        setSelectedValue(selectedSettings);
        if (demoHistoryTag) {
            getMicAndVideoStream();
        }
        if (selectedSettings?.sourceLangCode === 1000 || selectedSettings?.targetLangCode === 1000) {
            setDemoHistoryTag(false)
        }
    }, [selectedSettings]);


    return (
        <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
            borderWidth={"1px"}
            h="100%"
            w="100%"
            flexWrap={"wrap"}>
            <Flex
                position={"relative"}
                w={{
                    base: "100%",
                    md: "100%",
                    xl: "100%",
                    "2xl": "100%",
                }}
                h={{
                    base: "100%",
                    md: "100%",
                    xl: "100%",
                    "2xl": "100%",
                }}
                borderBottom={{
                    base: "1px",
                    md: "0px",
                    xl: "0px",
                    "2xl": "0px",
                }}
                borderColor={"#d7d7d7"}>
                <Box
                    w={{
                        base: "100%",
                        md: "100%",
                        xl: "100%",
                        "2xl": "100%",
                    }}>
                    <div
                        ref={remoteVideoRef}
                        id="remoteVideo"
                    >

                        {showVideosLoading && <Flex h="full" w="full" justifyContent="center" alignItems="center" position="absolute" flexDir="column" gap="2">
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='green.500'
                                size='xl'
                            />
                            Connecting...
                        </Flex>}
                        {
                            remoteVideoError && <Flex h="full" w="full" justifyContent="center" alignItems="center" position="absolute" flexDir="column" gap="2">
                                <Text fontSize="4xl" color="red.300"><AiFillInfoCircle /></Text>
                                <Text color="red.400">{remoteVideoError}</Text>
                            </Flex>
                        }
                    </div>
                </Box>
                <Box
                    id="locaVideoContainer"
                    position={{
                        base: "absolute",
                        md: "absolute",
                        xl: "absolute",
                        "2xl": "absolute",
                    }}
                    right="0"
                    bottom="0"
                    h={{
                        base: "35%",
                        md: "35%",
                        xl: "45%",
                        "2xl": "45%",
                    }}
                    w={{
                        base: "40%",
                        md: "30%",
                        xl: "25%",
                        "2xl": "25%",
                    }}
                >
                    <video
                        ref={localVideoRef}
                        autoPlay
                        muted
                        id="localVideo"
                        style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                        }}></video>
                </Box>
            </Flex>

        </Flex >
    )
})

export default VideoCallComp