import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { getPageConfig } from '../../../services/sharedServices';
import { Flex, Text } from '@chakra-ui/react';
import VerifyControlAccess from './VerifyControlAccess';
import { useSelector } from 'react-redux';

const Documents = () => {
    const [supportEmail, setSupportEmail] = useState(null);
    const space = useSelector((state) => state.SharedSpaceUserData?.space);
    const type = useSelector((state) => state.SharedSpaceUserData?.type);
    const subdomain = window.location.origin.split(".")[0]
        ? window.location.origin.split(".")[0]
        : null;

    useEffect(() => {
        if (subdomain != null) {
            let subdomain_val = subdomain.replace(window.location.protocol + "//", "");
            fetchConfDetails(subdomain_val);
        }
    }, []);

    const fetchConfDetails = (sub_domain) => {
        let paramObj = {
            subdomain: sub_domain,
        };
        getPageConfig(paramObj)
            .then((res) => {
                if (res?.data?.length > 0) {
                    if (res?.data[0]?.info?.support)
                        setSupportEmail(res?.data[0]?.info?.support);
                }
            })
            .catch(() => { });
    };
    if (!type) {
        return <Flex justifyContent="center" gap="1"><Text fontSize="xx-large">Link is not valid</Text> {supportEmail && <Text fontSize="xx-large">please contact {supportEmail}</Text>}</Flex>
    }
    return (
        <>
            {
                type === "listing" ? <VerifyControlAccess space_name={space}/> : null
            }
        </>
    )
}

export default Documents