import { SET_INPUT_VALUE, CLEAR_INPUT } from '../actionTypes';

export const setInputValue = (value) => ({
    type: SET_INPUT_VALUE,
    value: value,
});

export const clearInput = () => ({
    type: CLEAR_INPUT,
});
