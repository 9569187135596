import { SET_INPUT_VALUE, CLEAR_INPUT } from "../actionTypes";


const initialState = {
    inputValue: "",
};

const dialerReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_INPUT_VALUE:
            return { ...state, inputValue: action.value };
        case CLEAR_INPUT:
            return { ...state, inputValue: "" };
        default:
            return state;
    }
};

export default dialerReducer;
