import { Alert, AlertIcon, Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, FormControl, FormLabel, Radio, RadioGroup, Spacer, Spinner, Switch, Text, useDisclosure, useToast, VStack } from '@chakra-ui/react';
import React from 'react'
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import InputField from "../../../components/fields/InputField";
import "flatpickr/dist/themes/material_blue.css";

import Flatpickr from "react-flatpickr";
import { useTextColor } from '../../../theme/globalColorTheme';
import { useRef } from 'react';
import { useEffect } from 'react';
import { createDocAccessControl, createDocumentAccessSpace, getDocControlAccessList, updateControlAccessCode, UpdateDocControlAccessList, updateDocumentAccessSpace, updateDocumentAccessSpaceCode } from '../../../services/accountServices';
import moment from 'moment'
import { toastFunctionToaster } from '../../../utils/toastFunction';
import Card from '../../../components/card/Card';
import ShareAccessControlCard from './ShareAccessControlCard';
import DocAISampleEmail from '../DocAiSampleEmail';
import Pagination from '../../../components/pagination/Pagination';
import { DRAWER_TYPES } from './constants';

const ShareAccessControl = (props) => {
    const location = useLocation()
    const projectData = location?.state?.projectData ? location?.state?.projectData : null;
    const [loading, setLoading] = useState(false)
    const [shareAccessUuid, setShareAccessUuid] = useState(null)
    const [accessControlList, setAccessControlList] = useState([])

    const { isOpen, onOpen, onClose} = useDisclosure();
    const [email, setEmail] = useState(null)
    const [verifyEmail, setVerifyEmail] = useState(null)
    const [accessType, setAccessType] = useState(null)
    const [accessStart, setAccessStart] = useState(null)
    const [accessEnd, setAccessEnd] = useState(null)

    const {
		isOpen: isEmailDrawerOpen,
		onOpen: onEmailDrawerOpen,
		onClose: onEmailDrawerClose,
	} = useDisclosure();

    const [pageId, setPageId] = useState(1)
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [errors, setErrors] = useState({})
    const [btnLoading, setBtnLoading] = useState({})
    const touched = useRef({})
    const [disableAddUpdate, setDisableAddUpdate] = useState(true)
    const [selectedAccessControl, setSelectedAccessControl] = useState(null)
    const [controlDisabled, setControlDisabled] = useState(false)
    const [totalPages, setTotalPages] = useState(0)
    const [drawerType, setDrawerType] = useState(null)
    const [spaceName, setSpaceName] = useState(null)
    const navigate = useNavigate()
    const pathname = window.location.pathname;
	const subroute = pathname.split('/')[1];

    const toast = useToast()
    function closeDrawer() {
        setEmail(null)
        setVerifyEmail(null)
        setAccessType(null)
        setAccessEnd(null)
        setAccessStart(null)
        touched.current = {}
        setErrors({})
        setDisableAddUpdate(true)
        setSelectedAccessControl(null)
        setControlDisabled(false)
        setSpaceName(null)
        setDrawerType(null)
        onClose()
    }
    const DatetimePickerStyles =
    {
        height: "38.5px",
        padding: "0 16px",
        width: "100%",
        border: ".8px solid #cbd5e0",
        borderRadius: "5px",
        background: "transparent",
        color: useTextColor()
    }

    function handleGetAccessList() {
        if (projectData?.project_uuid){
            const paramObj = {
                project_uuid: projectData?.project_uuid,
                page_id: pageId,
                records_per_page: recordsPerPage
            }
            setLoading(true)
            getDocControlAccessList(paramObj).then(resp => {
                if (resp?.data[0]?.status) {
                    let list = resp?.data[0]?.data?.data
                    setTotalPages(resp?.data[0]?.data?.total_pages)
                    setAccessControlList(list)
                } else {
                    setAccessControlList([])
                    toast(toastFunctionToaster(resp?.message, "error"))
                }
            }).catch(e => {
                setAccessControlList([])
            }).finally(() => {
                setLoading(false)
            })
        } else {
            toast(toastFunctionToaster("Unable to get the list of access control due to no project info.", "error"))
        }
    }

    function handleCreateAccess() {
        const paramObj = {
            "project_uuid": projectData?.project_uuid,
            "user_email": email,
            "is_full_access": accessType === "full",
            "access_start": moment(new Date(accessStart)).utc().format('YYYY-MM-DD HH:mm:ss') || new Date(),
            "access_end": moment(new Date(accessEnd)).utc().format('YYYY-MM-DD HH:mm:ss') || new Date()
        }
        setBtnLoading(prevState => ({ ...prevState, create: true }))
        createDocAccessControl(paramObj).then(resp => {
            if (resp?.data[0]?.status) {
                toast(toastFunctionToaster(resp?.message, "success"))
                closeDrawer()
                handleGetAccessList()
            }
            else {
                toast(toastFunctionToaster(resp?.message, "error"))
            }
        }).catch(e => {
            toast(toastFunctionToaster("Failed to create access", "error"))
        }).finally(() => {
            setBtnLoading(prevState => ({ ...prevState, create: false }))
        })
    }

    function updateAccessCode(data) {
        const paramObj = {
            "doc_control_uuid": data?.control_uuid
        }
        setBtnLoading(prevState => ({ ...prevState, accessCodeUpdate: data?.control_uuid }))
        updateControlAccessCode(paramObj).then(resp => {
            if (resp?.data[0]?.status) {
                handleGetAccessList()
                toast(toastFunctionToaster(resp?.message, "success"))
            } else {
                toast(toastFunctionToaster(resp?.message, "error"))
            }
        }).catch(e => {
            toast(toastFunctionToaster("Failed to update access code", "error"))
        }).finally(() => {
            setBtnLoading(prevState => ({ ...prevState, accessCodeUpdate: "" }))
        })
    }

    function validate() {
        const isValid = Object.values(errors).every(value => value === "");
        if (!isValid || !accessType) {
            setDisableAddUpdate(true)
        } else {
            if (accessType === "limited" && (!accessEnd || !accessStart)) {
                setDisableAddUpdate(true)
                return
            } else if (accessEnd && accessStart) {
                if (new Date(accessEnd) < new Date() || new Date(accessStart) < new Date()) {
                    setErrors(errors => ({ ...errors, durationError: "Start and End Date-Time should be greater than current Date-time" }))
                    return false
                }
            }
            setDisableAddUpdate(false)
        }
    }

    function EditAccessDetails(data) {
        debugger;
        onOpen()
        setDrawerType(DRAWER_TYPES.SHARE_CONTROL)
        setSelectedAccessControl(data)
        setEmail(data?.control_user_email)
        setAccessType(data?.control_full_access ? "full" : "limited")
        setAccessEnd(moment(data?.control_access_end).format('YYYY-MM-DD'))
        setAccessStart(moment(data?.control_access_start).format('YYYY-MM-DD'))
        setVerifyEmail(data?.control_user_email)
        setControlDisabled(data?.control_access_disabled)
    }

    function handleUpdateAccessDetails() {
        debugger;
        const paramObj = {
            user_email: email,
            "control_uuid": selectedAccessControl?.control_uuid,
            "is_full_access": accessType === "full",
            "is_disabled": controlDisabled,
            "access_start": moment(new Date(accessStart)).utc().format('YYYY-MM-DD HH:mm:ss') || new Date(),
            "access_end": moment(new Date(accessEnd)).utc().format('YYYY-MM-DD HH:mm:ss') || new Date()
        }
        setBtnLoading(prevState => ({ ...prevState, update: true }))
        UpdateDocControlAccessList(paramObj).then(resp => {
            if (resp?.data[0]?.status) {
                closeDrawer()
                toast(toastFunctionToaster(resp?.message, "success"))
                handleGetAccessList()
            } else {
                toast(toastFunctionToaster(resp?.message, "error"))
            }
        }).catch(e => {
            toast(toastFunctionToaster("Failed to update Access control", "error"))
        }).finally(() => {
            setBtnLoading(prevState => ({ ...prevState, update: false }))
        })
    }

    useEffect(() => {
        validate()
    }, [errors, accessType])

    useEffect(() => {
        handleGetAccessList()
    }, [])
    useEffect(() => {
        setPageId(1)
    }, [recordsPerPage])

    function handleBlur(name) {
        touched.current = { ...touched.current, [name]: true };

        const newErrors = {};

        if (
            (touched.current['link_start_date_time'] && !accessStart) ||
            (touched.current['link_end_date_time'] && !accessEnd)
        ) {
            newErrors.durationError = "Both Fields are required!";
        } else {
            newErrors.durationError = "";
        }

        if (touched.current['email']) {
            newErrors.email = email ? "" : "Email is required";
        }

        if (touched.current['verify_email']) {
            if (!verifyEmail) {
                newErrors.verify_email = "Re-enter email";
            } else if (touched.current['email'] && email !== verifyEmail) {
                newErrors.verify_email = "Both email should match";
            } else {
                newErrors.verify_email = "";
            }
        }

        setErrors(errors => ({ ...errors, ...newErrors }));
    }

    useState(() => {
        if (!accessType) {
            setDisableAddUpdate(true)
        } else {
            setDisableAddUpdate(false)
        }
    }, [accessType])


    function createSpaceAccess(data) {
        setSelectedAccessControl(data)
        setDrawerType(DRAWER_TYPES.ACCESS_SPACE)
        onOpen()
    }

    function editSpaceAccess(data){
        setSelectedAccessControl(data)
        setDrawerType(DRAWER_TYPES.ACCESS_SPACE)
        setSpaceName(data?.document_space_name)
        onOpen()

    }

    function isAccessSpaceNameValid(name) {
        const pattern = /^[a-zA-Z][a-zA-Z0-9_-]{8,18}[a-zA-Z]$/;
        return pattern.test(name);
    }

    function handlecreateAccessSpace() {
        if (!isAccessSpaceNameValid(spaceName)){
            return toast(toastFunctionToaster("Invalid Space name", "error"))
        }

        setBtnLoading(prevState => ({ ...prevState, createAccessSpace: true }))
        const paramObj = {
            document_space_name: spaceName,
            control_uuid: selectedAccessControl?.control_uuid
        }
        createDocumentAccessSpace(paramObj).then(resp=>{
            if(resp?.data[0]?.status){
                toast(toastFunctionToaster(resp?.message, "success"))
                closeDrawer()
                handleGetAccessList()
            }
            else{
                toast(toastFunctionToaster(resp?.message || resp?.error, "error"))
            }
        }).catch(err=>{
            toast("Failed to create document access space", "error")
        }).finally(()=>{
            setBtnLoading(prevState => ({ ...prevState, createAccessSpace: false }))
        })

    }

    function handleUpdateDocumentSpaceAccessCode(data){
        setBtnLoading(prevState => ({ ...prevState, updateDocSpaceAccessCode: true }))
        const paramObj={
            control_uuid:data?.control_uuid
        }
        updateDocumentAccessSpaceCode(paramObj).then(resp=>{
            if(resp?.data[0]?.status){
                toast(toastFunctionToaster(resp?.message, "success"))
                handleGetAccessList()
            }else{
                toast(toastFunctionToaster(resp?.message || resp?.error, "error"))
            }
        }).catch(err=>{
            toast(toastFunctionToaster("Failed to update space access code", "error"))

        }).finally(()=>{
            setBtnLoading(prevState => ({ ...prevState, updateDocSpaceAccessCode: false }))
        })

    }

    function handleUpdateDocSpaceAccessDetails(data){
        if (!isAccessSpaceNameValid(spaceName)){
            return toast(toastFunctionToaster("Invalid Space name", "error"))
        }

        setBtnLoading(prevState => ({ ...prevState, updateAccessSpace: true }))
        const paramObj = {
            document_space_name: spaceName,
            control_uuid: selectedAccessControl?.control_uuid
        }
        updateDocumentAccessSpace(paramObj)
        .then(resp=>{
            if(resp?.data[0]?.status){
                toast(toastFunctionToaster(resp?.message, "success"))
                closeDrawer()
                handleGetAccessList()
            }else{
                toast(toastFunctionToaster(resp?.message || resp?.error, "error"))
            }
        })
        .catch(err=>{
            toast(toastFunctionToaster("Failed to update document space access details"))
        })
        .finally(()=>{
            setBtnLoading(prevState => ({ ...prevState, updateAccessSpace: false }))
        })

    }

    return (
        <>
            <Flex direction="column" pt={{ sm: "125px", lg: "75px" }} minH="93vh">
                <Flex
                    p="3"
                    gap={1}
                    alignItems="center"
                    borderBottomWidth="1px"
                    justifyContent={"space-between"}
                >
                    <Spacer />
                    <Button rounded={'lg'} colorScheme="blue" size="sm" ml="auto" onClick={onEmailDrawerOpen} >
                        Translation Email Help
                    </Button>
                    <Button
                        rounded={'lg'} 
                        colorScheme="blue" size="sm"
                        onClick={() => {
                            navigate(`/${subroute}/projects`)
                        }}>
                        Back to Projects list
                    </Button>
                    <Button
                        rounded={'lg'} 
                        colorScheme="blue" size="sm"
                        onClick={() => {
                            handleGetAccessList();
                        }}>
                        Refresh
                    </Button>
                    <Button
                        rounded={'lg'} 
                        colorScheme="blue" size="sm"
                        onClick={() => {
                            setEmail(null)
                            setVerifyEmail(null)
                            setAccessType(null)
                            setAccessEnd(null)
                            setAccessStart(null)
                            onOpen();
                            setDrawerType(DRAWER_TYPES.SHARE_CONTROL)
                        }}>
                        Create Access
                    </Button>
                </Flex>
                <Card flex="1" p="2">
                    {loading ? (
                        <Flex alignItems="center" justifyContent="center" h="50vh">
                            <Spinner size="xl" />
                        </Flex>
                    ) : accessControlList?.length ? (
                        <Flex direction="column" flex="1">
                            <Flex direction="column" gap="2" flex={1}>{
                                accessControlList.map(item => (
                                    <ShareAccessControlCard
                                        data={item}
                                        createSpaceAccess={createSpaceAccess}
                                        editSpaceAccess={editSpaceAccess}
                                        EditAccessDetails={EditAccessDetails}
                                        key={item?.control_uuid}
                                        updateAccessCode={updateAccessCode}
                                        btnLoading={btnLoading}
                                        handleUpdateDocumentSpaceAccessCode={handleUpdateDocumentSpaceAccessCode}
                                        projectData={projectData}
                                         />
                                ))
                            }</Flex>
                            {accessControlList?.length ? (
                                <Pagination currentPage={pageId} setCurrentPage={setPageId} setRecordsPerPage={setRecordsPerPage} totalPages={totalPages} recordsPerPage={recordsPerPage} />
                            ) : null}
                        </Flex>

                    ) : (
                        <Alert status="warning" mt="4">
                            <AlertIcon />
                            No Share Access Found!!
                        </Alert>
                    )}

                </Card>
            </Flex>
            <Drawer size="md" isOpen={isEmailDrawerOpen} placement="right" onClose={onEmailDrawerClose} >
                <DocAISampleEmail onEmailDrawerClose={onEmailDrawerClose} />
            </Drawer>
            <Drawer
                size="md"
                isOpen={isOpen}
                placement="right"
                onClose={() => {
                    closeDrawer();
                }}
            >
                <DrawerOverlay />
                {drawerType === DRAWER_TYPES.SHARE_CONTROL && <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth={"1px"}>
                        {" "}
                        {shareAccessUuid ? "Update" : "Add New"} Access
                    </DrawerHeader>
                    <DrawerBody overflow="hidden" mt="5">
                        <InputField
                            mb="3"
                            me="30px"
                            id="email"
                            label="Email"
                            value={email}
                            name="email"
                            required="true"
                            borderRadius="5px"
                            onBlur={e =>
                                handleBlur(e.target.name)
                            }
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            placeholder="Enter Email"
                            w="100%"
                            maxlength={100}
                        />
                        {errors?.email ? (
                            <Text fontSize="sm" color={"red"} mb="5" mt="1">
                                Please enter email
                            </Text>
                        ) : null}
                        <InputField
                            mb="0"
                            me="30px"
                            id="verify_email"
                            label="Verify email"
                            value={verifyEmail}
                            name="verify_email"
                            required="true"
                            borderRadius="5px"
                            onBlur={e =>
                                handleBlur(e.target.name)
                            }
                            onChange={(e) => {
                                setVerifyEmail(e.target.value);
                            }}
                            placeholder="Re-Enter Email"
                            w="100%"
                            maxlength={100}
                        />

                        {errors?.verify_email ? (
                            <Text fontSize="sm" color={"red"} mb="5" mt="1">
                                {errors?.verify_email}
                            </Text>
                        ) : null}

                        <Flex direction="column" mt={4}>
                            <Text fontWeight="bold">Access Type:</Text>
                            <RadioGroup onChange={setAccessType} value={accessType}>
                                <Flex direction="column" p={2}>
                                    <Radio value='full'>Full Access</Radio>
                                    <Radio value="limited" mt={2}>Limited Access</Radio>
                                    {accessType === "limited" &&
                                        <Flex mt={2} pl={2} direction="column">
                                            <Flex justifyContent="space-between">
                                                <FormControl w="45%">
                                                    <FormLabel htmlFor="startDate">Access Start Date<Text color="red.400" as="span">*</Text></FormLabel>
                                                    {/* <Input required type="datetime-local" variant="outline" value={linkStartDateTime} onChange={e => setLinkStartDatetime(e.target.value)} /> */}
                                                    <Flatpickr options={{
                                                        enableTime: true,
                                                        dateFormat: "Y-m-d",
                                                        minuteIncrement: 15,
                                                        minDate: 'today',
                                                    }}
                                                        altInputClass="flat-picker"
                                                        value={accessStart}
                                                        name="link_start_date_time"
                                                        onBlur={(e) => {
                                                            handleBlur(e.target.name)
                                                        }}
                                                        onChange={value => setAccessStart(value[0])}
                                                        style={DatetimePickerStyles}
                                                        placeholder="Select access start"
                                                        id="startDate"
                                                    />
                                                </FormControl>
                                                <FormControl w="45%">
                                                    <FormLabel htmlFor="startDate" >Access End Date<Text color="red.400" as="span">*</Text></FormLabel>
                                                    {/* <Input required type="datetime-local" variant="outline" value={linkStartDateTime} onChange={e => setLinkStartDatetime(e.target.value)} /> */}
                                                    <Flatpickr options={{
                                                        enableTime: true,
                                                        dateFormat: "Y-m-d",
                                                        minuteIncrement: 15,
                                                        minDate: accessStart,
                                                    }}
                                                        altInputClass="flat-picker"
                                                        value={accessEnd}
                                                        name="link_end_date_time"
                                                        onBlur={(e) => {
                                                            handleBlur(e.target.name)
                                                        }}
                                                        onChange={value => setAccessEnd(value[0])}
                                                        style={DatetimePickerStyles}
                                                        placeholder="Select access end"
                                                        id="startDate"
                                                    />
                                                </FormControl>
                                            </Flex>
                                            {errors?.durationError ? <Text color="red.600" fontSize="sm" mt={2}>{errors?.durationError}</Text> : null}
                                        </Flex>}
                                    {selectedAccessControl && <Flex alignItems="center" mt={4} gap="2">
                                        <Text>{controlDisabled 
                                            ? 'Currently access is disabled, enable access? ' 
                                            : 'Currently access is enabled, disable access?'}</Text>
                                        <Switch value={controlDisabled} onChange={() => setControlDisabled(controlDisabled => !controlDisabled)}></Switch>
                                    </Flex>}
                                </Flex>
                            </RadioGroup>
                        </Flex>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button variant="outline" mr={3} onClick={closeDrawer}>
                            Cancel
                        </Button>
                        {
                            selectedAccessControl ? <Button onClick={handleUpdateAccessDetails} isLoading={btnLoading?.update} >Update</Button> : <Button isDisabled={disableAddUpdate} onClick={handleCreateAccess} isLoading={btnLoading?.create}>Create Access</Button>
                        }
                    </DrawerFooter>
                </DrawerContent>}

                {drawerType === DRAWER_TYPES.ACCESS_SPACE && <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth={"1px"}>
                        {" "}
                        {shareAccessUuid ? "Update" : "Add New"} Document Space
                    </DrawerHeader>
                    <DrawerBody overflow="hidden" mt="5">
                        <InputField
                            mb="3"
                            me="30px"
                            id="email"
                            label="Document Share Space Name"
                            value={spaceName}
                            name="email"
                            required="true"
                            borderRadius="5px"
                            onChange={(e) => {
                                setSpaceName(e.target.value)
                            }}
                            placeholder="Enter Access Space Name.."
                            w="100%"
                            maxlength={20}
                        />
                        <Text ml="2" fontSize="smaller" color="red.200">Name should be of atleast 11 characters long(max 20 char.), it should start and with letter, can contain numbers, hyphen and underscores in between.</Text>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button variant="outline" mr={3} onClick={closeDrawer}>
                            Cancel
                        </Button>
                        {
                            selectedAccessControl?.document_space_uuid ? <Button onClick={handleUpdateDocSpaceAccessDetails} isLoading={btnLoading?.updateAccessSpace}>Update</Button> : <Button onClick={handlecreateAccessSpace} isLoading={btnLoading?.createAccessSpace}>Create Access Space</Button>
                        }
                    </DrawerFooter>
                </DrawerContent>}
            </Drawer>
        </>
    )
}

export default ShareAccessControl