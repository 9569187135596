import React from "react";
import { Flex } from "@chakra-ui/react";

import LSAsystem from "./components/lsa/LSAsystem";
import useSipClient from "../../../../utils/lingoSipTest/useSipClient";

function LSAHISystem({ sessionKey, launchKey, selectedOption, verifyAccessCode }) {
	const CONFIG = process.env.REACT_APP_LSA_HI_DEFAULT_CONFIG;

    const sipClient = useSipClient(CONFIG);

	return (
		<Flex alignItems={"center"} justifyContent={"center"} w="100%">
			<Flex alignItems={"center"} justifyContent={"center"} p="2" borderWidth={"1px"} borderRadius={"10px"} h="400px" w="400px" bg="#fff" color={"black"}>
				<LSAsystem 
				sessionKey={sessionKey} 
				launchKey={launchKey} 
				selectedOption={selectedOption} 
				verifyAccessCode={verifyAccessCode} 
				sipClient={sipClient} />
			</Flex>
		</Flex>
	);
}

export default LSAHISystem;
