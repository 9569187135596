// Chakra Imports
import { FaPhoneSlash, FaPhone } from "react-icons/fa";
import React, { useState, useRef, useEffect } from "react";
import {
	Box,
	Flex,
	Button,
	Icon,
	Textarea,
	Text,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Image,
	useMediaQuery,
	Spinner,
	useToast,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Spacer,
	Alert,
	AlertIcon,

} from "@chakra-ui/react";

// API
import { callStartedEx, callCompletedEx, addFeedBack } from "../../../../../../services/dynamicPageSvc";
import { AiOutlineAudio, AiOutlineVideoCamera, AiFillClockCircle } from "react-icons/ai";
import FeedbackModal from "../../../BasicAIComponent/FeedbackModal";
import { toastFunctionToaster } from "../../../../../../utils/toastFunction";
import "./LSAConnect.css"
import VideoCallComp from "../VideoCallComp";
import AudioCallComp from "../AudioCallComp";

const LSAConnect = ({ launchKey, sessionKey, callReadyVal, callTrialVal, callDataVal, selectedSettings, sipClient }) => {
	const callProviderCode = 11; 																	// What is this ? Do I need to change it?

	const localVideoRef = useRef(null); // Ref for local video container
	const remoteVideoRef = useRef(null);
	const [callLog, setCalLog] = useState("Start of the log...");
	const [isLandscape] = useMediaQuery("(orientation: landscape)");
	const [callReadiness, setCallReadiness] = useState(callReadyVal ? callReadyVal : false);
	const [callTrial, setCallTrial] = useState(callTrialVal ? callTrialVal : false);
	const [callData, setCallData] = useState(callDataVal ? callDataVal : [0, 0, 0, 0]);
	const [currentCall, setCurrentCall] = useState(null);
	const [agentDetails, setAgentDetails] = useState(null);											
	const [callUuid, setCallUuid] = useState(null);
	const [hangupFlag, setHangupFlag] = useState(false);
	const [callSuccessStatus, setCallSuccessStatus] = useState(false);
	const [muteUnmuteFlag, setMuteUnmuteFlag] = useState(false);
	const [pauseUnpauseFlag, setPauseUnpauseFlag] = useState(false);
	const [videoEnable, setVideoEnable] = useState(true);
	const [micEnable, setMicEnable] = useState(false);
	const [callType, setCallType] = useState("audio");
	const [selectedValue, setSelectedValue] = useState(selectedSettings);
	const localVideoElement = document.getElementById("localVideo");
	const [pauseLayer, setPauseLayer] = useState(false);
	// Get local and remote video elements
	const [callStarted, setCallStarted] = useState(null);
	const [demoHistoryTag, setDemoHistoryTag] = useState(true)
	const [progressText, setProgressText] = useState(null)
	const [showVideosLoading, setShowVideoLoading] = useState(false)
	const [remoteVideoError, setRemoteVideoError] = useState(null)
	const [callCompleted, setCallCompleted] = useState(false)
	const [comment, setComment] = useState("");
	const [rating, setRating] = useState(0);
	const [selectedComment, setSelectedComment] = useState([]);
	const streamRef = useRef(null);
	const { isOpen: isFeedBackOpen, onOpen: onFeedBackOpen, onClose: onFeedBackClose } = useDisclosure()
	const callLogDisclosure = useDisclosure();
	const [isHangupLoading, setIsHangupLoading] = useState(false);

	const toast = useToast();
	const [btnLoading, setBtnLoading] = useState({
		submitFeedback: false
	})

	// Timer UseEffect
	const [time, setTime] = useState(0);
	const [isRunning, setIsRunning] = useState(false);
	const intervalRef = useRef();

	useEffect(() => {
		setSelectedValue(selectedSettings);
		if (demoHistoryTag) {
			// getMicAndVideoStream();
		}
		if (selectedSettings?.sourceLangCode === 1000 || selectedSettings?.targetLangCode === 1000) {
			setDemoHistoryTag(false)
		}
	}, [selectedSettings]);

	useEffect(() => {
		return () => {
			closeVideoAndAudioStream()
		}
	}, [])

	useEffect(() => {
		if (callCompleted) {
			onFeedBackOpen()
		}
	}, [callCompleted])

	useEffect(() => {
		if (sipClient.isCallConnected) {
			setAgentDetails({
				name: sipClient.session.remoteIdentity.uri.normal.user,
				company: "",
				rating: "",
			});
		}
	}, [sipClient.isCallConnected])

	useEffect(() => {
		const remoteContainer = remoteVideoRef.current;

		if (!remoteContainer) {
			console.log("remote container div not found")
			return
		};

		const cleanUpRemoteContainer = () => {
			const existingVideos = remoteContainer.getElementsByTagName("video");
			while (existingVideos.length > 0) {
				existingVideos[0].remove();
			}
		};

		const observer = new MutationObserver(() => {
			const videos = remoteContainer.getElementsByTagName("video");
			if (videos.length > 1) {
				for (let i = 1; i < videos.length; i++) {
					videos[i].remove();
				}
			}
		})
		observer.observe(remoteContainer, { childList: true });
		return () => {
			observer.disconnect();
			cleanUpRemoteContainer();
		};
	}, [currentCall])  // or session from sip

	function handleOnFeedBackClose() {
		onFeedBackClose();
		setRating(0);
		setSelectedComment([]);
		setComment("");
	}

	// Time Start Stop Function
	const startTimer = () => {
		resetTimer();
		if (!isRunning) {
			setIsRunning(true);
			intervalRef.current = setInterval(() => {
				setTime(prevTime => prevTime + 1);
			}, 1000);
		}
	};

	const stopTimer = () => {
		clearInterval(intervalRef.current);
		setIsRunning(false);
	};

	const resetTimer = () => {
		clearInterval(intervalRef.current);
		setIsRunning(false);
		setTime(0);
	};

	const resetData = () => {
		setProgressText(null)
		setShowVideoLoading(false)
		setRemoteVideoError(null)
	}

	const formatTime = timeInSeconds => {
		//console.log(timeInSeconds);
		const minutes = Math.floor(timeInSeconds / 60)
			.toString()
			.padStart(2, "0");
		const seconds = (timeInSeconds % 60).toString().padStart(2, "0");
		//console.log(minutes, seconds);
		return `${minutes}:${seconds}`;
	};

	const callStartedProcess = () => {
		if (launchKey != null && sessionKey != null && callType != null && callReadiness) {
			setShowVideoLoading(true)
			setProgressText("Registering your call...")
			let paramObj = {
				feature: "hi",
				provider: callProviderCode,
				callType: callType,
				launchKey: launchKey,
				launchSession: sessionKey,
				source_language: selectedSettings?.sourceLangName,
				target_language: selectedSettings?.targetLangName,
				agentInfo: agentDetails != null ? JSON.stringify(agentDetails) : !demoHistoryTag ? 'Demo Call' : "{}",
			};
			callStartedEx(paramObj)
				.then(res => {
					if (res?.data[0]?.status) {
						setCallReadiness(res?.data[0]?.ready);
						if (res?.data[0]?.ready == false) {
							updateLogDetails("Call can not be completed as trials period is expired.");
						} else {
							updateLogDetails("Call Started successully with call registration..");
							if (callType === "audio") {
								setCallStarted(true);
							}
							handleCall();
						}
						setCallUuid(res?.data[0]?.uuid);
						setCallTrial(res?.data[0]?.calls?.trial);
						setCallData(res?.data[0]?.calls?.value);
					}
				})
				.catch(err => {
					updateLogDetails("Unable to get call started working...");
				});
		}
		else if (!demoHistoryTag) {
			setCallStarted(true);
			updateLogDetails("Call Started successully with call registration..");
			if (callType === "audio") {
				setCallStarted(true);
			}
		}
	};

	const callCompletedProcess = () => {

		if (launchKey != null && sessionKey != null && callUuid != null) {
			resetData();


			if (currentCall) {																		
				try {

					sipClient.hangUpSipCall();

					setCurrentCall(null);
					updateLogDetails("The " + callType + " call is successfully closed.");
				} catch (error) {
					updateLogDetails("Error hanging up the call: " + error);
				}
			} else {
				updateLogDetails("There is no active " + callType + " call..");
			}

			let paramObj = {
				feature: "hi",
				provider: callProviderCode,
				callUuid: callUuid,
				launchKey: launchKey,
				launchSession: sessionKey,
				callLog: "none",
				callSuccessStatus: callSuccessStatus,
				agentInfo: agentDetails != null ? JSON.stringify(agentDetails) : !demoHistoryTag ? 'Demo Call' : "{}",
			};
			callCompletedEx(paramObj)
				.then(res => {
					if (res?.data[0]?.status) {

						setCallTrial(res?.data[0]?.calls?.trial);
						setCallData(res?.data[0]?.calls?.value);
						setCallReadiness(res?.data[0]?.ready);
						updateLogDetails("Call stopped successfully with call registration updated..");
						updateLogDetails(
							"You have completed " + callType + " call with total call time: " + formatTime(time) + "."
						);

						if (callType === "audio") {
							setCallStarted(false);
						}
						setCallCompleted(true)
					}
				})
				.catch(err => {
					updateLogDetails("Unable to get call stopped correctly...");
				});
		}
		else if (!demoHistoryTag) {
			updateLogDetails("Call stopped successfully with call registration updated..");
			updateLogDetails(
				"You have completed " + callType + " call with total call time: " + formatTime(time) + "."
			);
			if (callType === "audio") {
				setCallStarted(false);
			}
		}
	};

	function closeVideoAndAudioStream() {
		if (streamRef.current) {
			streamRef.current.getTracks().forEach(track => {

				track.stop()
				streamRef.current.removeTrack(track)
			});
		}
	}

	function handleAddFeedBack() {
		if (launchKey != null && sessionKey != null && callUuid != null) {
			let user_comment = comment.length ? [comment] : []
			const paramsObj = {
				call_uuid: callUuid,
				link_key: launchKey,
				link_session: sessionKey,
				rating: rating,
				comments: [...selectedComment, ...user_comment],
			}
			addFeedBack(paramsObj).then(resp => {
				if (resp?.data[0]?.status) {
					toast(toastFunctionToaster(resp?.message, "success"))
					setCallUuid(null)
					handleOnFeedBackClose()
					return
				} else {
					toast(toastFunctionToaster(resp?.message, "error"))
				}
			}).catch(err => {
				toast(toastFunctionToaster(err?.message, "error"))
			}).finally(() => {
				setBtnLoading(state => ({ ...state, submitFeedback: false }))
			})
		}
	}

	const handleCall = async () => {
		if (videoEnable == false && micEnable == false) {
			console.log("Please enable either mic or video to complete the call");
		} else {
			try {
				const callRequest = {																		// Check how to send the header or other info in sip request
					languageFromId: parseInt(selectedValue?.sourceLangCode), // Sample language IDs, replace with actual values
					languageToId: parseInt(selectedValue?.targetLangCode),
					serviceTypeId: parseInt(selectedValue?.serviceTypeCode),
					genderId: parseInt(selectedValue?.genderCode),
				};

				if (callType === "audio") {
    				const DESTINATION = process.env.REACT_APP_LSA_HI_DEFAULT_DESTINATION;					
					await sipClient.makeSipCall(DESTINATION);				
					setCurrentCall(sipClient.session);
					setCallSuccessStatus(true);
				}
				//  else {
				// 	const callProgress = await blObj.makeVideoCall(
				// 		callRequest,
				// 		remoteVideoRef.current,
				// 		localVideoRef.current
				// 	);
				// 	setCallSuccessStatus(true);
				// 	setCurrentCall(callProgress);
				// 	setProgressText("Connecting...")
				// 	updateCallProgress(callProgress);
				// }
				setCallSuccessStatus(true);
				console.log(callType + " call initiated successfully");
				updateLogDetails(callType + " call initiated successfully");
			} catch (error) {
				if (error) {
					setCallSuccessStatus(false);
					updateLogDetails("Error initiating " + callType + " call: " + error);
					console.log("Error initiating " + callType + " call: " + error);
				}
			}
		}
	};

	// const updateCallProgress = currentLocalCall => {
	// 	if (currentLocalCall != null && currentLocalCall != undefined) {
	// 		console.log("call: " + currentLocalCall);
	// 		setCallSuccessStatus(true);
	// 		updateLogDetails("Call is successfully connected...");
	// 		// currentLocalCall = call;
	// 		console.log("currentLocalCall: " + currentLocalCall);
	// 		// event that let's you know when remote participant has joined call
	// 		currentLocalCall.on("callConnected", () => {
	// 			updateLogDetails("Interpreter joined call")
	// 			setProgressText("Interpreter joined call")
	// 		});


	// 		// event fires when call is completed or cancelled
	// 		currentLocalCall.on("callCompleted", cancelled => {
	// 			updateLogDetails("Call completed " + (cancelled ? "before" : "after") + " connect");
	// 			setCallSuccessStatus(true);
	// 			currentLocalCall = null;
	// 			resetCallButtons();
	// 		});

	// 		// event when the remote participant information is available
	// 		// once available, can also be obtained with call.getInterlocutorInfo())
	// 		currentLocalCall.on("interlocutorInfo", info => {
	// 			updateLogDetails("Interpreter info available", info);
	// 			updateLogDetails(
	// 				"Name:" + info.requiredName + " Company:" + info.companyName + " Rating:" + info.rating
	// 			);
	// 			setAgentDetails({
	// 				name: info.requiredName,
	// 				company: info.companyName,
	// 				rating: info.rating,
	// 			});
	// 		});

	// 		// events for when local and remote audio is shared and/or attached to DOM
	// 		currentLocalCall.on("localAudioShared", isEnabled => {
	// 			updateLogDetails("Started sharing local audio", isEnabled);
	// 			// should just be one button, but showing both for testing purposes
	// 			// $("#button-mute").fadeIn();
	// 			// $("#button-unmute").fadeIn();
	// 		});

	// 		currentLocalCall.on("remoteAudioShared", isEnabled =>
	// 			updateLogDetails("Started sharing remote audio", isEnabled)
	// 		);

	// 		// events for when local and remote audio is unshared and/or removed from DOM
	// 		currentLocalCall.on("localAudioUnshared", () => updateLogDetails("Local audio unshared"));
	// 		currentLocalCall.on("remoteAudioUnshared", () => updateLogDetails("Remote audio unshared"));

	// 		// events for when local and remote audio is enabled (unmuted)
	// 		// does not fire initially if shared in isEnabled state
	// 		currentLocalCall.on("localAudioEnabled", () => updateLogDetails("Local audio enabled")); // could toggle mute/unmute
	// 		currentLocalCall.on("remoteAudioEnabled", () => updateLogDetails("Remote audio enabled"));

	// 		// events for when local and remote audio is disabled (muted)
	// 		currentLocalCall.on("localAudioDisabled", () => updateLogDetails("Local audio disabled"));
	// 		currentLocalCall.on("remoteAudioDisabled", () => updateLogDetails("Remote audio disabled"));

	// 		if (currentLocalCall.isVideo()) {
	// 			setShowVideoLoading(true)

	// 			currentLocalCall.on("remoteVideoShared", isEnabled => {
	// 				setRemoteVideoError(null)
	// 				setShowVideoLoading(false)
	// 			});

	// 			currentLocalCall.on("remoteVideoUnshared", () => {
	// 				setRemoteVideoError("Remote video not shared")
	// 				setShowVideoLoading(false)
	// 			});

	// 			currentLocalCall.on("remoteVideoEnabled", () => {
	// 				setRemoteVideoError(null)
	// 				setShowVideoLoading(false)
	// 			})

	// 			currentLocalCall.on("remoteVideoDisabled", () => {
	// 				setRemoteVideoError("Remote video disabled")
	// 				setShowVideoLoading(false)
	// 			});
	// 		}
	// 	}
	// };

	const updateLogDetails = logMessage => {
		let currentLog = callLog;
		logMessage = "> " + logMessage;
		currentLog = logMessage + "\n" + currentLog;
		setCalLog(currentLog);
	};

	// const resetCallButtons = () => { };

	const handleMute = () => {
		if (streamRef.current) {
			streamRef.current.getAudioTracks().forEach(track => {
				track.enabled = false; // Disable the audio track
			});
			console.log("Audio muted.");
			updateLogDetails("Audio muted.");
		}
	};

	const handleUnmute = () => {
		// Unmute the audio
		if (streamRef.current) {
			streamRef.current.getAudioTracks().forEach(track => {
				track.enabled = true; // Enable the audio track
			});
			console.log("Audio unmuted.");
			updateLogDetails("Audio unmuted.");
		}
	};

	const handlePause = () => {
		// Implement pause logic
		setPauseLayer(true);
		updateLogDetails("Pause button clicked");
		if (localVideoRef.current) {
			localVideoRef.current.pause();
			console.log(currentCall);
			if (currentCall) {
				currentCall.pauseVideo();
			}
		}
	};

	const handleUnpause = () => {
		// Implement unpause logic
		setPauseLayer(false);
		updateLogDetails("Unpause button clicked");
		if (localVideoRef.current) {
			localVideoRef.current.play();
		}
	};

	return (
		<Box bg="#fff" h="100%">
			<Box>
				{selectedValue ? (
					<Flex w={"100%"} p={0} m={0}>
						{/* <Text>
              {selectedValue?.sourceLangCode}-{selectedValue?.sourceLangName}/
              {selectedValue?.targetLangCode}-{selectedValue?.targetLangName}/
              {selectedValue?.serviceTypeCode}-{selectedValue?.serviceName}/
              {selectedValue?.genderCode}
            </Text> */}
					</Flex>
				) : null}
			</Box>
			<Tabs variant="soft-rounded" colorScheme="blue" mt="1" p="1px" position="relative">
				<TabList bg="#000" p="2" borderRadius="10px" w="fit-content" mx="auto" >
					<Tab
						onClick={e => {
							setCallType("audio");
							setCallStarted(null);
							callCompletedProcess();
							setHangupFlag(false);
							resetTimer();
						}}
						borderRadius={"10px"}
						color="#fff"
						isDisabled={callTrial && !callReadiness ? true : false}>
						<Icon as={AiOutlineAudio} mr={1} /> Audio{" "}
						{callType === "audio" && time > 0 ? (
							<>
								<Flex
									ml={1}
									py={".5"}
									rounded={"md"}
									minW={"80px"}
									maxW={"80px"}
									bg={"blue.300"}
									align={"center"}>
									<Icon as={AiFillClockCircle} ml={1} mr={1} h={5} w={5} />
									{formatTime(time)}
								</Flex>
							</>
						) : null}
					</Tab>
					<Tab
						onClick={e => {
							setCallType("video");
							setCallStarted(null);
							callCompletedProcess();
							setHangupFlag(false);
							resetTimer();
						}}
						borderRadius={"10px"}
						color="#fff"
						isDisabled={callTrial && !callReadiness ? true : false}>
						<Icon as={AiOutlineVideoCamera} mr={1} />
						Video
						{callType === "video" && time > 0 ? (
							<>
								<Flex
									ml={1}
									py={".5"}
									rounded={"md"}
									minW={"80px"}
									maxW={"80px"}
									bg={"blue.300"}
									align={"center"}>
									<Icon as={AiFillClockCircle} ml={1} mr={1} h={5} w={5} />
									{formatTime(time)}
								</Flex>
							</>
						) : null}
					</Tab>
					<Spacer />
				</TabList>
				<TabPanels>
					<TabPanel
						h={{
							base: isLandscape ? "auto" : "calc(100vh - 260px)", // "60vh",
							md: "calc(100vh - 260px)", // "50vh",
							xl: "calc(100vh - 250px)", // "50vh",
							"2xl": "calc(100vh - 200px)", // "50vh",
						}}
						w="100%">
						{
							hangupFlag && callType === "audio" ?
								<AudioCallComp
									callStarted={callStarted}
									callLog={callLog}
									setSelectedValue={setSelectedValue}
									demoHistoryTag={demoHistoryTag}
									setDemoHistoryTag={setDemoHistoryTag}
									selectedSettings={selectedSettings}
									ref={streamRef} /> : null
						}

					</TabPanel>
					<TabPanel
						h={{
							base: isLandscape ? "auto" : "calc(100vh - 260px)", // "60vh",
							md: "calc(100vh - 260px)", // "50vh",
							xl: "calc(100vh - 250px)", // "50vh",
							"2xl": "calc(100vh - 200px)", // "50vh",
						}}
						w="100%">
						{
							hangupFlag && callType === "video" ?
								<VideoCallComp
									showVideosLoading={showVideosLoading}
									progressText={progressText}
									remoteVideoError={remoteVideoError}
									demoHistoryTag={demoHistoryTag}
									selectedSettings={selectedSettings}
									setDemoHistoryTag={setDemoHistoryTag}
									setSelectedValue={setSelectedValue}
									ref={{ localVideoRef, remoteVideoRef, streamRef }}
								/> : null
						}

					</TabPanel>
				</TabPanels>
			</Tabs>
			{callType === "video" && hangupFlag && (showVideosLoading || remoteVideoError) && <Alert status='info' position={{
				base: "relative",
				md: "relative",
				xl: "absolute",
				"2xl": "absolute",
			}}
				justifyContent="center"
				w={{
					base: "100%",
					md: "100%",
					xl: "fit-content",
					"2xl": "fit-content",
				}}
				top={{
					base: "auto",
					md: "auto",
					xl: "5.5%",
					"2xl": "5.5%",
				}}
				right={{
					base: "auto",
					md: "auto",
					xl: "1%",
					"2xl": "1%",
				}}
				borderRadius={{
					base: "0",
					md: '0',
					xl: "15px",
					"2xl": "15px"
				}}
				p={{
					base: "2",
					md: "2",
					xl: "4",
					"2xl": "4"
				}}>
				<AlertIcon />
				{progressText || remoteVideoError}
			</Alert>}
			<Flex
				direction={"column"}
				rounded={"md"}
				borderColor={"gray.300"}
				borderWidth={"0.5px"}
				w={"100%"}
				h={"40px"}>
				{" "}
				{callReadiness ? (
					<Flex
						flexWrap="wrap"
						id="call-controls"
						mt="1px"
						align={"center"}
						justifyContent={"center"}
						bg="#fff">
						{!hangupFlag ? (
							<Button
								id="button-call"
								onClick={() => {
									callStartedProcess();
									setHangupFlag(true);
									startTimer();
								}}
								mr="2"
								colorScheme="green"
								size="sm"
								rounded="none"
								mt="2px">
								{callType === "audio" ? (
									<Icon as={AiOutlineAudio} mr={1} />
								) : (
									<Icon as={AiOutlineVideoCamera} mr={1} />
								)}{" "}
								Call
							</Button>
						) : null}
						{hangupFlag ? (
							<Button
								id="button-hangup"

								onClick={async () => {
									setIsHangupLoading(true);
									await callCompletedProcess();
									setHangupFlag(false);
									stopTimer();
									setIsHangupLoading(false);
									closeVideoAndAudioStream()
								}}
								mr="2"
								colorScheme="red"
								size="sm"
								rounded="none"
								mt="2px"
								isLoading={isHangupLoading}
							>
								Hangup
							</Button>
						) : null}
						{!muteUnmuteFlag ? (
							<Button
								id="button-mute"
								onClick={() => {
									handleMute();
									setMuteUnmuteFlag(true);
								}}
								mr="2"
								variant="outline"
								colorScheme="red"
								size="sm"
								rounded="none"
								mt="2px">
								Mute
							</Button>
						) : null}
						{muteUnmuteFlag ? (
							<Button
								id="button-unmute"
								onClick={() => {
									handleUnmute();
									setMuteUnmuteFlag(false);
								}}
								mr="2"
								variant="outline"
								colorScheme="green"
								size="sm"
								rounded="none"
								mt="2px">
								Unmute
							</Button>
						) : null}
						{callType === "video" ? (
							<>
								{!pauseUnpauseFlag ? (
									<Button
										id="button-pause"
										onClick={() => {
											handlePause();
											setPauseUnpauseFlag(true);
										}}
										mr="2"
										colorScheme="purple"
										size="sm"
										rounded="none"
										mt="2px">
										Pause
									</Button>
								) : null}
								{pauseUnpauseFlag ? (
									<Button
										id="button-unpause"
										onClick={() => {
											handleUnpause();
											setPauseUnpauseFlag(false);
										}}
										variant="outline"
										colorScheme="purple"
										size="sm"
										rounded="none"
										mt="2px">
										Unpause
									</Button>
								) : null}
								<Button
									onClick={callLogDisclosure.onOpen}
									mr="2"
									colorScheme="blue"
									size="sm"
									rounded="none"
									mt="2px"
								>
									Show Log
								</Button>

							</>
						) : null}
					</Flex>
				) : null}
			</Flex>{" "}
			{/* End of Bottom Control Button and Trial message Rectangle */}
			<FeedbackModal 
				isFeedBackOpen={isFeedBackOpen} 
				onFeedBackClose={handleOnFeedBackClose} 
				rating={rating} 
				setRating={setRating} 
				comment={comment} 
				setComment={setComment} 
				selectedComment={selectedComment} 
				setSelectedComment={setSelectedComment} 
				handleAddFeedBack={handleAddFeedBack} 
				submitLoading={btnLoading?.submitFeedback} 
			/>

			<Modal isOpen={callLogDisclosure.isOpen} onClose={callLogDisclosure.onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Call Log</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Textarea
							value={callLog}
							color="#000"
							rows={10}
							border="0"
							p="1"
							w="100%"
							h="100%"
							borderColor={"#d7d7d7"}
						/>
					</ModalBody>
					<ModalFooter>
						<Button variant="ghost" onClick={callLogDisclosure.onClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Box>
	);
};

export default LSAConnect;
