// Component Imports
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";

// Component Imports
import ProjectListing from "./ProjectListing";
import ExternalStorage from "./ExternalStorage";
import ProofreadingHome from "../accountmanagement/Proofreading/ProofreadingHome";
import { useState } from "react";

function DocumentAiHome() {
const [tabIndex, setTabIndex] = useState(0)
console.log(tabIndex, "in home")
	return (
		<Box direction="column" pt={{ sm: "125px", lg: "80px" }}>
			<Tabs isLazy lazyBehavior="unmount" variant="enclosed" colorScheme="teal" onChange={(index) => setTabIndex(index)}>
				<TabList justifyContent="flex-end">
					<Tab>Project Listing</Tab>
					<Tab>ExternalStorage</Tab>
					<Tab>Proofreading</Tab>
				</TabList>

				<TabPanels>
					<TabPanel>
						<ProjectListing />
					</TabPanel>
					<TabPanel>
						{tabIndex===1 ? <ExternalStorage tab={tabIndex}/> : null}
					</TabPanel>
					<TabPanel>
						<ProofreadingHome />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Box>
	);
}

export default DocumentAiHome;
