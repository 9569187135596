import React from "react";
import { Icon } from "@chakra-ui/react";
import { AiOutlineSetting, AiOutlineDashboard, AiOutlineSelect, AiOutlineLink, AiOutlineUser, AiOutlineGift, AiOutlineBoxPlot, AiOutlineTranslation } from "react-icons/ai";
import { MdOutlineHub, MdDocumentScanner, MdDevices } from "react-icons/md";

// Business Componenr Imports
import SubscriberDashboard from "../komponents/subscriber/dashboard/SubscriberDashboard";
import SubscriberEvents from "../komponents/subscriber/dashboard/SubscriberEvents";
import BusinessProfile from "../komponents/business/profile/BusinessProfile";

// LingoLinks

// Subscriber LingoLinks
import LinkSubscriberManage from "../komponents/subscriber/links/LinkSubscriberManage";
import LinkSubscriberCalls from "../komponents/subscriber/links/LinkSubscriberCalls";
import SubscriberInfo from "../komponents/subscriber/SubscriberInfo";
// import LinkCustomers from "../komponents/business/customers/LinkCustomers";
// import LinkCustomerCalls from "../komponents/business/customers/LinkCustomerCalls";
// import LinkCustomerManage from "../komponents/business/customers/LinkCustomerManage";

// Common
import Helper from "../komponents/common/helper/Helper";
import PlatformTrust from "../komponents/common/helper/PlatformTrust";
import LinkSubscriberTranscription from "../komponents/subscriber/links/LinkSubcriberTranscription";
import CardListing from "../komponents/subscriber/links/CardListing";
import CardUsage from "../komponents/subscriber/links/CardUsage";
import ApplicationSettings from "../komponents/common/ApplicationSettings";
import SubscriberSchedule from "../komponents/subscriber/SubscriberSchedule";

import SubLanguagePreference from "../komponents/subscriber/LanguagePreference";
import Sharing from "../komponents/subscriber/links/sharing/Sharing";
import LinkSubscriberLingoCardCallsHistory from "../komponents/subscriber/links/LinkSubscriberLingoCardCallsHistory";
import { TbFileReport } from "react-icons/tb";
import ProjectListing from "../komponents/documentai/ProjectListing";
import ShareAccessControl from "../komponents/documentai/shareControl/ShareAccessControl";
import ContentList from "../komponents/documentai/shareControl/ContentList";
import ProjectContent from "../komponents/documentai/ProjectContent";
import ContentWorkflowMgmt from "../komponents/documentai/ContentWorkflowMgmt";
import ContentWorkflowResults from "../komponents/documentai/ContentWorkflowResult";
import AccountReporting from "../komponents/subscriber/reporting/AccountReporting";
import AccountFullBilling from "../komponents/subscriber/reporting/accountBilling/AccountFullBilling";

const TrialRoutes = (isLingoCardEnabled = false, locationUuid = null, accountUuid = null, subsAppDetails = "") => {
    const documentAiSupport = subsAppDetails?.split(",")?.indexOf("di") >= 0 ? true : false;
    return [
        {
            name: "Dashboard",
            path: "/subscriber",
            icon: <Icon as={AiOutlineDashboard} width="25px" height="25px" />,
            collapse: true,
            items: [
                {
                    name: "Dashboard",
                    layout: "/trial",
                    path: "/dashboard",
                    component: <></>,
                    secondary: true,
                    showToMenu: true,
                    icon: <Icon as={AiOutlineDashboard} width="20px" height="20px" />,
                },

            ],
        },

        {
            name: "Lingo Hub",
            path: "/subscriber",
            icon: <Icon as={MdOutlineHub} width="25px" height="25px" />,
            collapse: true,
            items: [
                {
                    name: "LingoLink",
                    layout: "/trial",
                    path: "/lingolinklisting",
                    component: <>TRial link listing component</>,
                    secondary: true,
                    showToMenu: true,
                    icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
                },


            ],
        },
        {
            name: "Reporting",
            path: "/trial",
            icon: <Icon as={AiOutlineGift} width="25px" height="25px" />,
            collapse: true,
            items: [
                // {
                // 	name: "Account Report",
                // 	layout: "/subscriber",
                // 	path: "/reporting",
                // 	component: <AccountReporting />,
                // 	secondary: true,
                // 	showToMenu: true,
                // 	icon: <Icon as={TbFileReport} width="20px" height="20px" />,
                // },
                {
                    name: "Account Billing",
                    layout: "/subscriber",
                    path: "/billing",
                    component: <AccountFullBilling />,
                    secondary: true,
                    showToMenu: true,
                    icon: <Icon as={TbFileReport} width="20px" height="20px" />,
                },
            ],
        },

        {
            name: "Settings",
            path: "/subscriber",
            icon: <Icon as={AiOutlineSetting} width="25px" height="25px" />,
            collapse: true,
            items: [
                // {
                //     name: "Team Members",
                //     layout: "/subscriber",
                //     path: "/team",
                //     component: <AccountTeam />,
                //     secondary: true,
                //     showToMenu: true,
                //     icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
                // },

                {
                    name: "Profile",
                    layout: "/trial",
                    path: "/profile",
                    component: <></>,
                    secondary: true,
                    showToMenu: true,
                    icon: <Icon as={AiOutlineUser} width="20px" height="20px" />,
                },
            ],
        },
        {
            name: "Help",
            path: "/trial",
            icon: <Icon as={AiOutlineSetting} width="25px" height="25px" />,
            collapse: true,
            items: [
                {
                    name: "Get Help",
                    layout: "/subscriber",
                    path: "/help",
                    component: <Helper />,
                    secondary: true,
                    showToMenu: true,
                    icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
                },
                // {
                // 	name: "Compliance",
                // 	layout: "/subscriber",
                // 	path: "/trust",
                // 	component: <PlatformTrust />,
                // 	secondary: true,
                // 	showToMenu: true,
                // 	icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
                // },
            ],
        },
    ].filter(item => !item.isHidden);
};

export default TrialRoutes;
