import { UserAgent, Inviter } from 'sip.js';
import { toastFunctionToaster } from '../../toastFunction';

export const acceptCall = async (
    incomingCall, 
    inputDevice, 
    remoteAudio, 
    setIncomingCall, 
    setLogMessages, 
    toast
    ) => {

    if (!incomingCall) {
        console.error('No incoming call to accept');
        toast(
            toastFunctionToaster(
                'No incoming call to accept.',
                'error'
            )
        )
        return;
    }

    const options = {
        sessionDescriptionHandlerOptions: {
            constraints: {
                audio: {
                    deviceId: inputDevice ? { exact: inputDevice } : undefined,
                },
                video: false
            }
        },
        media: {
            remote: {
                audio: remoteAudio.current
            }
        }
    };

    try {
        await incomingCall.session.accept(options); // Accept the call
        setIncomingCall(null); // Close modal
    } catch (error) {
        console.error('Error accepting call:', error);
        setLogMessages((prev) => [...prev, `Error accepting call: ${error.message}`]);
        toast(
            toastFunctionToaster(
                'Error accepting call.',
                'error'
            )
        );
    }
};

export const rejectCall = (
    incomingCall, 
    setIncomingCall, 
    setLogMessages, 
    toast
    ) => {

    try {

        if (incomingCall) {
            incomingCall.session.reject();
            setIncomingCall(null); 
        }

    } catch (error) {
        console.error('Error rejecting call:', error);
        setLogMessages((prev) => [...prev, `Error rejecting call: ${error.message}`]);
        toast(
            toastFunctionToaster(
                'Error rejecting call.',
                'error'
            )
        );
    }
};


// Make outgoing call
export const makeCall =  async(
    userAgent, 
    inputDevice, 
    remoteAudio, 
    destination, 
    toast, 
    setLogMessages, 
    setSession, 
    updateCallProgress,
    setIsCallConnecting, 
    setIsCallConnected
    ) => {

    try {
        if (!userAgent) {
            toast(toastFunctionToaster('Agent not available.','error'));
            return;
        };

        const options = {
            sessionDescriptionHandlerOptions: {
                constraints: {
                    audio: {
                        deviceId: inputDevice ? { exact: inputDevice } : undefined,
                    },
                    video: false 
                }
            },
            media: {
                remote: {
                    audio: remoteAudio.current
                }
            }
        };

        const destinationUri = UserAgent.makeURI(destination);
        if (!destinationUri) {
            console.error('Invalid destination URI', destinationUri);
            toast(
                toastFunctionToaster(
                'Invalid URI.',
                'error'
                )
            );
            return;
        }

        const inviter = new Inviter(userAgent, destinationUri);
        
        setLogMessages((prev) => [...prev, `Dialing ${destination}`]);
        
        updateCallProgress(inviter, setIsCallConnecting, setIsCallConnected, setSession, setLogMessages, remoteAudio, toast);

        await inviter.invite(options);

        setSession(inviter);
    } catch (error) {
        console.error('Error making call:', error);
        setLogMessages((prev) => [...prev, `Error making call: ${error.message}`]);
        toast(
            toastFunctionToaster(
                'Error making call.',
                'error'
            )
        );
    }
};

// Hang up call
export const hangUpCall = (
    session, 
    setSession, 
    setIsCallConnected, 
    setLogMessages, 
    setDtmfHistory,
    toast
    ) => {

    try {

        if (session) {
            session.bye();
            setSession(null);
            setIsCallConnected(false);
            setLogMessages((prev) => [...prev, 'Call Hangup Successful']);
            setDtmfHistory([]);
        }
    } catch (error) {
        console.error('Error hanging up call:', error);
        setLogMessages((prev) => [...prev, `Error hanging up call: ${error.message}`]);
        toast(
            toastFunctionToaster(
                'Error hanging up call.',
                'error'
            )
        );
    }
};
