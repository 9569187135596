import React from "react";
import BoostLingoSystem from "./components/BoostLingoSystem";

function BoostHILingoSystem({ sessionKey, launchKey, selectedOption, verifyAccessCode }) {
	return (
		<>
			<BoostLingoSystem sessionKey={sessionKey} launchKey={launchKey} selectedOption={selectedOption} verifyAccessCode={verifyAccessCode} />
		</>
	);
}

export default BoostHILingoSystem;
