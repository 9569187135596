// Chakra Imports
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
	Box,
	Heading,
	Flex,
	Text,
	Button,
	Spinner,

} from "@chakra-ui/react";

// Api Call Imports
import { accessibilityPageEx } from "../../../../../../services/dynamicPageSvc";


const LingoletSystem = props => {
	const navigate = useNavigate();
	const [loading, isLoading] = useState(false);
	const launchKey = props.launchKey ? props.launchKey : null;
	const sessionKey = props.sessionKey ? props.sessionKey : null;

	const [timeLeft, setTimeLeft] = useState([]);
	const [callReady, setCallReady] = useState(false);
	const [callTrial, setCallTrial] = useState(false);
	const [callData, setCallData] = useState([]);

    const sipClient = props.sipClient;

	const { startSip, userAgent, DialerUI, logMessages} = sipClient;
	

    const startSipClient = () => {
		isLoading(true);
        startSip();
    }

	useEffect(() => {
		startSipClient();
		runPageAccessibility();
	}, []);

    useEffect(() => {
        if (userAgent !== null || userAgent !== undefined) {
            isLoading(false);
        } 
    },[userAgent]);


	const runPageAccessibility = () => {
		if (launchKey != null && sessionKey != null) {
			let paramObj = {
				feature: 'hi',
				launchKey: launchKey,
				launchSession: sessionKey,
			};
			accessibilityPageEx(paramObj)
				.then(res => {
					if (res?.data[0]?.status) {
						// Load the Page only when True
						setCallTrial(res?.data[0]?.calls?.trial);
						setCallData(res?.data[0]?.calls?.value);
						setTimeLeft(res?.data[0]?.timeLeft);
						setCallReady(res?.data[0]?.ready);
					} else {
						console.error("Not Accessible.");
					}
				})
				.catch(err => {
					isLoading(false);
				});
		}
	};



	return (
		<>
			{loading ? (
				<Flex alignItems="center" justifyContent="center" h="70vh" w="100%" color="#fff">
					<Spinner size="xl" bg="#000" />
				</Flex>
			) : (
				<Box w={"100%"} overflow={"auto"}>
					{launchKey != null && sessionKey != null && userAgent ? (
						<>
							<Flex w="100%" h="100%" flexWrap={"wrap"}>
									<Box w="50%" borderLeftWidth={"0px"}>
										<DialerUI />
									</Box>
									<Box w="50%" p={4}  borderLeftWidth="1px" borderColor="gray.300">
										<Text fontSize="xl" fontWeight="bold" mb={2}>Call Logs</Text>
										<Box maxH="300px" overflowY="auto" p={2} borderRadius="md">
											{logMessages.length > 0 ? (
												logMessages.map((log, index) => (
													<Text key={index} fontSize="sm" color="gray.700" mb={1}>
														{log}
													</Text>
												))
											) : (
												<Text fontSize="sm" color="gray.500">No logs available.</Text>
											)}
										</Box>
									</Box>							
							</Flex>
						</>
					) : (
						<Flex p={5} w={"100%"} direction={"column"} align={"center"} bg={"red.100"}>
							<Heading color="#gray.800" fontSize={"26px"} fontWeight={"normal"}>
								Hmm....{" "}
								<Text as="span" fontWeight={"600"} mr="1">
									Something is not right!!
								</Text>
							</Heading>
							<Text mt="3" fontSize={"17px"} color="gray.700">
								Looks like, there are some issues with Human Translation...
							</Text>
						</Flex>
					)}
					<Flex w="100%" justify="center" mt={4} mb={2}>
						<Button
							size={{
								base: "sm",
								md: "sm",
								lg: "sm",
								xl: "sm",
							}}
							colorScheme="red"
							borderRadius={"10px"}
							onClick={() => {
								navigate(-1);
							}}>
							Back
						</Button>
					</Flex>	
				</Box>
			)}
		</>
	);
};

export default LingoletSystem;
