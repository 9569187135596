import React, { useEffect, memo, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Input, Flex, Text, useToast, Fade, Spinner } from '@chakra-ui/react';
import { FaPhoneAlt } from 'react-icons/fa';

import { toastFunctionToaster } from '../../toastFunction';
import { setInputValue, clearInput } from '../../../store/actions/dialerActions';

const Dailer = ({ onDial, hangUpCall, onDTMF, disabled, isCallConnecting, isCallConnected }) => {
    const dispatch = useDispatch();
    const inputValue = useSelector((state) => state.dialerReducer.inputValue);
    const toast = useToast();
    const inputRef = useRef(null);

    useEffect(() => {
        if(inputRef.current) {
            inputRef.current.focus();
            inputRef.current.style.outline = "none"; // Remove default outline
            inputRef.current.style.borderColor = "inherit"; // Keep original border color
            inputRef.current.style.boxShadow = "none"; // Prevent extra focus effects
        }
    });

    useEffect(() => {
        if (isCallConnected) {
            dispatch(clearInput());
        }
    }, [isCallConnected]);

    const handleKeyPress = (key) => {
        if (isCallConnected) {
            onDTMF(key);
        } else {
            dispatch(setInputValue(inputValue + key));
        }
    };

    const handleBackspace = () => {
        if (inputValue.length > 0) {
            dispatch(setInputValue(inputValue.slice(0, -1)));
        }
    };

    const isValidNumber = (number) => {
        const sipUriRegex = /^sip:\d+@(?:\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(:\d+)?|[a-zA-Z0-9.-]+\.[a-z]{2,})$/; 
        return sipUriRegex.test(number); 
    }

  // Function to handle dialing logic
    const handleDial = async () => {
        if (!inputValue) {
            toast(toastFunctionToaster('Please enter a number to dial.', 'warning'));
            return;
        }

        if (!isValidNumber(inputValue)) {
            toast(toastFunctionToaster('Invalid number format.', 'error'));
            return;
        }

        try {
            await onDial(inputValue);
        } catch (error) {
            toast(toastFunctionToaster(`Dialing failed: ${error.message}`, 'error'));
        }
    };

    const handleHangUp = () => {
        try {
            if(isCallConnected) {
                hangUpCall();
            } else {
                toast(toastFunctionToaster('No active call to hang up.', 'warning'));
            }
        } catch (error) {
            console.error('Call hangup failed:', error);
            toast(toastFunctionToaster(`Call hangup failed.`, 'error'));
        }
    };


    // Dial pad layout
    const dialPad = [
        ['1', '2', '3'],
        ['4', '5', '6'],
        ['7', '8', '9'],
        ['*', '0', '#'],
    ];

    return (
        <Box
        maxW="400px"
        mx="auto"
        mt={5}
        p={2}
        bg="white"
        borderRadius="lg"
        boxShadow="lg"
        textAlign="center"
        transition="all 0.3s ease"
        >
            {/* Input Field for the Dialer */}
            {!isCallConnecting && (
                    <Input
                    ref={inputRef}
                    value={inputValue}
                    onChange={(e) => dispatch(setInputValue(e.target.value))}
                    placeholder="sip:example@domain.com"
                    size="lg"
                    mb={4}
                    fontSize="sm"
                    textAlign="left"
                    isDisabled={disabled || isCallConnecting}
                    />
            )}

            {/* Dial Pad */}
            <Box>
                {dialPad.map((row, i) => (
                <Flex key={i} justify="center" mb={2}>
                    {row.map((key) => (
                    <Button
                        key={key}
                        onClick={() => handleKeyPress(key)}
                        size="lg"
                        m={1}
                        w="60px"
                        h="60px"
                        colorScheme="blue"
                        fontSize="2xl"
                        isDisabled={disabled || isCallConnecting}
                        _hover={{ bg: 'blue.600' }}
                        _active={{ bg: 'blue.700' }}
                        transition="all 0.2s ease"
                    >
                        {key}
                    </Button>
                    ))}
                </Flex>
                ))}
            </Box>

            {/* Backspace and Dial buttons */}
            <Flex justify="center" mt={4}>
            {!isCallConnecting && (
                <>
                    <Button
                    onClick={handleBackspace}
                    size="lg"
                    colorScheme="red"
                    m={1}
                    w="60px"
                    h="60px"
                    fontSize="2xl"
                    isDisabled={disabled || isCallConnecting || inputValue.length === 0}
                    _hover={{ bg: 'red.600' }}
                    _active={{ bg: 'red.700' }}
                    transition="all 0.2s ease"
                    >
                    ⌫
                    </Button>

                    <Button
                    onClick={handleDial}
                    colorScheme="green"
                    size="lg"
                    m={1}
                    w="60px"
                    h="60px"
                    fontSize="xl"
                    isDisabled={disabled || !inputValue || isCallConnecting}
                    _hover={{ bg: 'green.600' }}
                    _active={{ bg: 'green.700' }}
                    transition="all 0.2s ease"
                    >
                    {isCallConnecting ? <Spinner size="sm" /> : <FaPhoneAlt />}
                    </Button>
                    </>
                )}
                    <Button
                        onClick={handleHangUp}
                        colorScheme="red"
                        size="lg"
                        m={1}
                        w="60px"
                        h="60px"
                        fontSize="xl"
                        mb={2}
                        _hover={{ bg: 'red.600' }}
                        _active={{ bg: 'red.700' }}
                        transition="all 0.2s ease"
                    >
                        <FaPhoneAlt />
                    </Button>
                </Flex>

            {/* Dialing Indicator */}
            { isCallConnecting ? (
            <Flex direction="column" align="center" justify="center" mt={4}>
                <Spinner size="xl" color="blue.500" thickness="4px" speed="0.7s" />
            </Flex>
            ) : isCallConnected && (
            <Fade in={isCallConnected}>
                <Text mt={4} fontSize="xl" color="green.500">
                Connected
                </Text>
            </Fade>
            )}
        </Box>
    );
};

export default memo(Dailer);
