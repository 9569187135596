import React from "react";
import { Flex } from "@chakra-ui/react";

import useSipClient from "../../../../utils/lingoSipTest/useSipClient";
import LingoletSystem from "./components/lingolet/LingoletSystem";

function LingoletHISystem({sessionKey, launchKey}) {

	const CONFIG = process.env.REACT_APP_LINGOLET_HI_DEFAULT_CONFIG

	const sipClient = useSipClient(CONFIG);

	return (
		<Flex alignItems={"center"} justifyContent={"center"} w="100%">
			<Flex alignItems={"center"} justifyContent={"center"} p="2" borderWidth={"1px"} borderRadius={"10px"} h="85%" w="50%" bg="#fff" color={"black"}>
				<LingoletSystem 
				launchKey={launchKey} 
				sessionKey={sessionKey} 
				sipClient={sipClient} />
			</Flex>
		</Flex>
	);
}

export default LingoletHISystem;
