import { Box, SimpleGrid, Stat, StatLabel, StatNumber, Text, Icon, useColorModeValue, Flex, Spinner,Button } from "@chakra-ui/react";
import Card from "../../../components/card/Card";
import { usePillHoverBgColor, useStatisticsPillBorderColor } from "../../../theme/globalColorTheme";
import IconBox from "../../../components/icons/IconBox";
import { DOC_AI_WORKER_MANAGEMENT } from "../constants";
import { FaSync } from'react-icons/fa';


const MetricsDashboard = ({ metrics, loading ,getDocAiMetrics}) => {
    return (
        <Box w="full">
         <Flex justifyContent="space-between" alignItems="center" mb={8}>
        <Text fontSize="xl" fontWeight="bold">📊 Metrics</Text>
        <Button onClick={getDocAiMetrics}  leftIcon={<FaSync />}>Refresh</Button>
    </Flex>
            <SimpleGrid columns={[1, 1, 3]} spacing={6}>
                {DOC_AI_WORKER_MANAGEMENT.METRICS_CONFIG.map(({ key, label, icon, color }) => (
                    <MetricCard key={key} label={label} value={metrics[key]} icon={icon} color={color} loading={loading}/>
                ))}
            </SimpleGrid>
        </Box>
    );
};

const MetricCard = ({ label, value, icon, color, loading }) => {
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const whitedarkBg = useColorModeValue("white", "#151117");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.400");
    return (

        <Card py="15px"
            borderColor={useStatisticsPillBorderColor} borderWidth={'0.5px'}
            _hover={{ cursor: "pointer", bg: usePillHoverBgColor, 'borderWidth': '0.5px', 'borderColor': 'red.100' }}
            bg={whitedarkBg}>
            <Flex my="auto" h="100%" align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>

                <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={<Icon w="28px" h="28px" as={icon} color={color} />}
                />

                <Stat ml={3}>
                    <StatLabel color={"textColorSecondary"}
                        fontSize={{
                            base: "sm",
                        }}>{label}</StatLabel>
                    {loading ? <Spinner /> : <StatNumber fontSize="2xl" color={textColor}>{value || 0}</StatNumber>}
                </Stat>
            </Flex>
        </Card>
    );
};

export default MetricsDashboard;
