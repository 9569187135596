import {
    SET_SHARED_ACCESS_TOKEN,
    SET_SHARED_ACCESS_USER_EMAIL,
    SET_SHARED_ACCESS_TYPE,
    SET_SHARED_ACCESS_SPACE,
    SET_SHARED_ACCESS_PROJECT_UUID
} from "../actionTypes"

const initialState = {
    token:'',
    userEmail:'',
    type:'',
    space:'',
    projectUuid: ''
}

const SharedSpaceUserData = (state = initialState, action) => {
    switch (action.type) {
        case SET_SHARED_ACCESS_TOKEN: {
            return {
                ...state,
                token: action.value
            }
        }
        case SET_SHARED_ACCESS_USER_EMAIL: {
            return {
                ...state,
                userEmail: action.value
            }
        }
        case SET_SHARED_ACCESS_TYPE: {
            return {
                ...state,
                type: action.value
            }
        }
        case SET_SHARED_ACCESS_SPACE: {
            return {
                ...state,
                space: action.value
            }
        }
        case SET_SHARED_ACCESS_PROJECT_UUID: {
            return {
                ...state,
                projectUuid: action.value
            }
        }
        default:
            return state
    }
}

export default SharedSpaceUserData
