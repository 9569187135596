import { SessionState } from 'sip.js';
import { toastFunctionToaster } from '../../toastFunction';

const setupRemoteMedia = (session, remoteAudio) => {
    try {        
        const remoteStream = new MediaStream();
        session.sessionDescriptionHandler.peerConnection.getReceivers().forEach((receiver) => {
            if (receiver.track) {
            remoteStream.addTrack(receiver.track);
            }
        });
        remoteAudio.current.srcObject = remoteStream;
        remoteAudio.current.play();
    } catch (error) {
        console.error('Error setting up remote media:', error);
        toastFunctionToaster('Error setting up remote media.', 'error');
    }
}

const cleanupMedia = (remoteAudio) => {
    try {
        remoteAudio.current.srcObject = null;
        remoteAudio.current.pause();
    } catch (error) {
        console.error('Error cleaning up media:', error);
        toastFunctionToaster('Error cleaning up media.', 'error');
    }
}

export const updateCallProgress = (
    session, 
    setIsCallConnecting,
    setIsCallConnected,
    setSession,
    setLogMessages,
    remoteAudio,
    toast
    ) => {

    try {

        if (!session) {
            console.error('No active session for call progress.');
            toast(toastFunctionToaster('No active session for call progress.', 'error'));
            return;
        }
    
        session.stateChange.addListener((state) => {
            console.log(`Session state changed to: ${state}`);
    
            if (state === SessionState.Establishing) {
                console.log('Call in progress');
                setIsCallConnecting(true);
                setLogMessages((prev) => [...prev, 'Call in progress']);
    
            } else if (state === SessionState.Established) {
                console.log('Call established');
                setupRemoteMedia(session, remoteAudio);
                setIsCallConnecting(false);
                setIsCallConnected(true);
                setLogMessages((prev) => [...prev, 'Call Connected']);
    
            } else if (state === SessionState.Terminating) {
                console.log('Call terminating');
                setLogMessages((prev) => [...prev, 'Call Terminating']);
    
            } else if (state === SessionState.Terminated) {
                console.log('Call terminated');
                cleanupMedia(remoteAudio);
                setIsCallConnected(false);
                setSession(null);
                setLogMessages((prev) => [...prev, 'Call Terminated']);
            }
        });
    } catch (error) {
        console.error('Error updating call progress:', error);
        toast(toastFunctionToaster('Error updating call progress.', 'error'));
    }
};