import React, { useState, useEffect, useRef } from "react";
import "react-responsive-pagination/themes/classic.css";
import { useNavigate, } from "react-router-dom";
import QRCode from "react-qr-code";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
// Chakra Import
import {
	Box,
	Flex,
	Text,
	Link,
	Button,
	Icon,
	Spacer,
	Alert,
	Drawer,
	Spinner,
	Image,
	Tooltip,
	CheckboxGroup,
	Stack,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Slider,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	SliderMark,
	AlertIcon,
	FormLabel,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	useDisclosure,
	useToast,
	Input,
	Checkbox,
	useColorModeValue,
	SimpleGrid,
	Badge,
	FormControl,
	Select,
	RadioGroup,
	Radio,
	background,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Tabs, TabPanel, TabList, Tab, TabPanels
} from "@chakra-ui/react";
import InputField from "../../../../components/fields/InputField";
import moment from "moment";
import Card from "../../../../components/card/Card";
import {
	AiOutlineCheckCircle,
	AiOutlineCopy,
	AiOutlineLink,
	AiOutlineStop,
	AiTwotoneAudio,
	AiOutlineVideoCamera,
	AiOutlineClockCircle,
	AiOutlinePlus,
	AiFillRightCircle,
	AiFillLeftCircle,
	AiOutlineBulb,
} from "react-icons/ai";
// Api Call Imports
import {
	addNewLink,
	fetchAllAssociatesPaginated,
	getAllLinks,
	getAllTeamLinks,
	shareLinkViaEmail,
	shareLinkViaSms,
} from "../../../../services/businessServices";
import { formatSeconds } from "../../../../utils/TimeFunctions";
// Component Imports
import HumanAIBg from "../../../../assets/communicator/HumanIcon.png";
import BasicAIBg from "../../../../assets/communicator/SecureAI.png";
import DocumentAiLightBg from "../../../../assets/communicator/DocumentAILight.png";
import MultichannelBg from "../../../../assets/communicator/multichannelai.png";
import KioskAiBg from '../../../../assets/communicator/kiosk-ai.png';
import LiveAiBg from '../../../../assets/communicator/live-ai.png';
import PremiumAIBg from "../../../../assets/communicator/Premium_AIIcon.png";
import { useLocation } from 'react-router-dom';

import { toastFunctionToaster } from "../../../../utils/toastFunction";
import {
	useBlackWhiteBg, useBrandBg, useGray500TextColor,
	useGradientColor, useBlueButton, useTextColor, usePillButtonTextColorHover,
	usePillButtonTextColorSelected, usePillButtonBgHover,
	usePillButtonBg, usePillButtonHighLight,
	usePillButtonTextColor, useGrayTextColor,
	useCardHeaderNonActiveBg, useCardHeaderActiveBg, useLinkColor,
	useBoxHoverHighlight,
	useGrayShadeCardBg, useGrayShadeCardBorderBg
} from "../../../../theme/globalColorTheme";
import LinkApp from "./LinkApp";
import Pagination from "../../../../components/pagination/Pagination";
import {
	LINGOLINK_TYPES, MAX_LINK_DURATION_VALUE,
	INIT_CALL_TIME_VALUE, INIT_CURRENT_SELECTION, INIT_DURATION_VALUE, INIT_LINK_CHOICE, INIT_PAGE, INIT_SELECTED_VALUE, INIT_VIDEO_CALL_VALUE, INIT_VOICE_CALL_VALUE, INIT_RECORDS_PER_PAGE
} from "./constants";
import LinkPreferences from "./LinkLanguagePreferences";
import { getAllLocLanguages, fetchLocationInfo, accLangPrefPaginated } from "../../../../services/accountServices";
import LinkTags from "./LinkTags";
import { getAssocTeamMembers } from "../../../../services/businessServices";
import RenderDynamicLinks from "./RenderDynamicLinks";
import { defaultThemeColor } from "../../../../constants";
import TagsInput from "react-tagsinput";
import { getEmailId } from "../../../../utils/localStorageIndex";





function LinkListing(props) {
	const email = getEmailId();
	const location = useLocation()

	const [toSms, setToSms] = useState([]);
	const [sendCode, setSendCode] = React.useState(false);
	const [linkInfo, setLinkInfo] = useState([]);
	const [toEmails, setToEmails] = useState([]);
	const [mainactiveTab, setMainActiveTab] = useState(0);

	const [urlGroup, setUrlGroup] = useState(1);
	const [activeTab, setActiveTab] = useState(0);
	const [isFeatureEnabledTab1, setIsFeatureEnabledTab1] = useState(true);
	const [isFeatureEnabledTab2, setIsFeatureEnabledTab2] = useState(false);
	const { isOpen: onDrawerisOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
	const toast = useToast();
	const navigate = useNavigate();
	const btnRef = React.useRef();
	const [loading, isLoading] = useState(true);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const type = props?.type
	const port_value = window.location.port ? ":" + window.location.port : '';
	const currentHost = window.location.hostname.includes("localhost")
		? "https://lingolet.ai/"
		: window.location.protocol + "//" + window.location.hostname + port_value + "/";

	const currentUseType = window.location.href.includes('/business/') ? "businessadmin" : "accountadmin";

	// const currentHost = window.location.protocol + "//" + window.location.hostname + port_value + "/";

	const { isOpen: isOpenQr, onOpen: onOpenQr, onClose: onCloseQr } = useDisclosure();
	const qrRef = React.useRef();
	const [teamMembers, setTeamMembers] = useState([])
	const [currentSelection, setCurrentSelection] = useState(INIT_CURRENT_SELECTION);
	const [dynamicLinks, setDynamicLinks] = useState([]);
	const [linkChoice, setLinkChoice] = useState(INIT_LINK_CHOICE);
	const [selectedValues, setSelectedValues] = useState(INIT_SELECTED_VALUE);
	const [linkTitle, setLinkTitle] = useState(null);
	const [durationValue, setDurationValue] = useState(INIT_DURATION_VALUE);
	const [showTooltip, setShowTooltip] = useState(false);
	const [linkTitleFlag, setLinkTitleFLag] = useState(false);
	const [saveDetailsLoader, setSaveDetailsLoader] = useState(false);
	const [linkTrial, setLinkTrial] = useState(false);
	const [voiceCallValue, setVoiceCallValue] = useState(INIT_VOICE_CALL_VALUE);
	const [videoCallValue, setVideoCallValue] = useState(INIT_VIDEO_CALL_VALUE);
	const [callTimeValue, setCallTimeValue] = useState(INIT_CALL_TIME_VALUE);
	const [modalQrCode, setModalQrCode] = useState(null);
	const [currentPage, setCurrentPage] = useState(INIT_PAGE);
	const [totalPages, setTotalPages] = useState(0);
	const [recordsPerPage, setRecordsPerPage] = useState(INIT_RECORDS_PER_PAGE)
	const [linkMultichannel, setLinkMultichannel] = useState(false);
	const [isCallDurationUnlimited, setIsCallDurationUnlimited] = useState(false)
	const [linkStartDateTime, setLinkStartDatetime] = useState(null)
	const [linkEndDateTime, setLinkEndDateTime] = useState(null)
	const [newLingoLinkType, setNewLingoLinkType] = useState(LINGOLINK_TYPES.NO_LIMIT)
	const [errors, setErrors] = useState({})
	const [langPrefList, setLangPrefList] = useState([])
	const touched = useRef({})
	const [availableLangs, setAvailableLangs] = useState([])

	const [accountInfo, setAccountInfo] = useState([]);
	const [subAppDetails, setSubAppDetails] = useState([]);
	const [selectedLangPref, setSelectedLangPref] = useState("none")

	const pillButtonTextColorHover = usePillButtonTextColorHover()
	const pillButtonText = useTextColor()
	const brandBg = useBrandBg()
	const gradientColor = useGradientColor()
	const blackWhiteBg = useBlackWhiteBg()
	const blueButtonBg = useBlueButton()

	const pillButtonBgSelected = usePillButtonTextColorSelected()
	const pillButtonBg = usePillButtonBg()
	const pillButtonBgHover = usePillButtonBgHover()
	const pillButtonBgHighlight = usePillButtonHighLight()
	const textColor = usePillButtonTextColor()
	const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(true)
	const [TeamDynamicLinks, setTeamDynamicLinks] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null)

	const linkColor = useLinkColor();

	function onShareDrawerClose() {
		onDrawerClose()
		setLinkInfo(null)
		setToEmails([])
	}

	const labelStyles = {
		mt: "12",
		ml: "-2.5",
		fontSize: "sm",
	};
	const labelStylesVoice = {
		mt: "1",
		ml: "-1",
		mr: "2.0",
		fontSize: "sm",
	};

	const DatetimePickerStyles =
	{
		height: "38.5px",
		padding: "0 16px",
		width: "100%",
		border: ".8px solid #cbd5e0",
		borderRadius: "5px",
		background: "transparent",
		color: pillButtonText
	}

	useEffect(() => { resetProjectDetails(false, false) }, [newLingoLinkType])

	function getLanguagePreferencesList() {
		const paramObj = {
			pageId: 1,
			records_per_page: 100,
			status: 'active'
		}
		accLangPrefPaginated(paramObj).then(resp => {
			if (resp.data[0]?.status) {
				setLangPrefList(resp.data[0]?.status)
			} else {
				setLangPrefList(null)
				// toast(toastFunctionToaster("Failed to fetch Preference list", "error"))
			}
		}).catch(err => {
			setLangPrefList(null)
			// toast(toastFunctionToaster("Failed to fetch Preference list", "error"))
		})
	}

	function calculateMinutes() {
		const differenceInMs = new Date(linkEndDateTime) - new Date(linkStartDateTime);
		const differenceInMinutes = Math.floor(differenceInMs / (1000 * 60));
		console.log(differenceInMinutes)
		return differenceInMinutes;
	}

	//Fetch Parent Account details
	useEffect(() => {
		if (currentUseType === 'accountadmin') {
			collectLocationInfo();
		} else {
			setSubAppDetails(['hi', 'bi', 'mi', 'di', 'li', 'ki']);
		}
		getLanguagePreferencesList()
	}, []);

	const collectLocationInfo = () => {
		isLoading(true);
		let paramObj = {
			key: 'location',
		};
		fetchLocationInfo(paramObj)
			.then(res => {
				isLoading(false);
				if (res?.data[0]?.status) {
					isLoading(false);
					setAccountInfo(res?.data[0]?.info);
					// console.log(res?.data[0]?.info[0]?.apps);
					setSubAppDetails(res?.data[0]?.info[0]?.apps?.length ? res?.data[0]?.info[0]?.apps?.split(',') : []);
					// console.log(subAppDetails);
				} else {
					isLoading(false);
				}
			})
			.catch(err => {
				isLoading(false);
			});
	};

	// Todo:Differentiate based on type
	useEffect(() => {
		const buttonType = props?.button
		if (buttonType?.length) {
			switch (buttonType) {
				case 'active':
				case 'closed':
					getAllDynamicLinks(buttonType);
					break;
				case 'add':
					onOpen();
					break;
				default:
					break;
			}
			props?.resetButton('reset')
		}

	}, [props?.button])
	//   Todo:Fetch all projects
	useEffect(() => {
		getAllDynamicLinks(linkChoice);


	}, [currentPage, recordsPerPage, mainactiveTab]);




	const getAllDynamicLinks = choice => {
		isLoading(true);
		let paramObj = {
			filter_key: "string",
			filter_value: choice,
			records_per_page: recordsPerPage,
			page_id: currentPage,
		};
		setLinkChoice(choice);
		getAllLinks(paramObj)
			.then(res => {
				isLoading(false);
				if (res?.data[0]?.records) {
					setDynamicLinks(res?.data[0]?.records);
					setTotalPages(res?.data[0]?.total_pages);
				} else {
					setDynamicLinks([]);
				}
			})
			.catch(err => {
				isLoading(false);
			});
	};

	const getAllTeamDynamicLinks = (uuid) => {
		isLoading(true);
		let paramObj = {
			filter_key: "string",
			filter_value: "active",
			records_per_page: recordsPerPage,
			page_id: currentPage,
			user_uuid: uuid
		};
		console.log(paramObj)
		// setLinkChoice(choice);
		getAllTeamLinks(paramObj)
			.then(res => {
				isLoading(true);
				if (res?.data[0]?.total_records === 0) {
					//toast(toastFunctionToaster("No Links Found", "error"));
					setTeamDynamicLinks([]);
					isLoading(false);
				}
				else if (res?.data[0]?.records) {
					//toast(toastFunctionToaster(res.message, "success"));
					setTeamDynamicLinks(res?.data[0]?.records);
					setTotalPages(res?.data[0]?.total_pages);
				}
			})
			.catch(err => {
				isLoading(false);
			});
	};




	function validate() {
		if (linkTitle?.length && selectedValues.length > 0) {
			setLinkTitleFLag(false)
			if (newLingoLinkType === LINGOLINK_TYPES.FIXED_DURATION) {
				if (!linkStartDateTime || !linkEndDateTime) {
					setErrors(errors => ({ ...errors, durationError: "Both Fields are required!" }))
					return false
				}
				if (new Date(linkEndDateTime) < new Date() || new Date(linkStartDateTime) < new Date()) {
					setErrors(errors => ({ ...errors, durationError: "Start and End Date-Time should be greater than current Date-time" }))
					return false
				}
				let durationInMinutes = calculateMinutes()
				if (durationInMinutes < 60 || durationInMinutes > MAX_LINK_DURATION_VALUE) {
					setErrors(errors => ({ ...errors, durationError: "Time Gap between Start and End Date-time should be greater than 1 hour and less than 120 days" }))
					return false
				}
			}
			return true
		}
		else {
			setLinkTitleFLag(true);
			return false
		}
	}
	function getAvailableLangs() {
		const paramObj = {
			type: "translation",
			org: "aws"
		}
		getAllLocLanguages(paramObj).then(resp => {
			if (resp?.result) {
				setAvailableLangs(resp?.data)
			} else {
				setAvailableLangs([])
			}
		}).catch(err => {
			setAvailableLangs([])
		})
	}

	useEffect(() => {
		getAvailableLangs()
	}, [])

	useEffect(() => {
		touched.current['link_title'] && !linkTitle?.length ? setLinkTitleFLag(true) : setLinkTitleFLag(false)
	}, [linkTitle])

	useEffect(() => {
		if (newLingoLinkType === LINGOLINK_TYPES.FIXED_DURATION) {
			if (touched.current['link_start_date_time'] && touched.current['link_end_date_time']) {
				if (new Date(linkEndDateTime) < new Date() || new Date(linkStartDateTime) < new Date()) {
					setErrors(errors => ({ ...errors, durationError: "Start and End Date-Time should be greater than current Date-time" }))
					return
				}
				let durationInMinutes = calculateMinutes()
				if (durationInMinutes < 60 || durationInMinutes > MAX_LINK_DURATION_VALUE) {
					setErrors(errors => ({ ...errors, durationError: "Time Gap between Start and End Date-time should be greater than 1 hour and less than 120 days" }))
					return
				}
			}
			setErrors(errors => ({ ...errors, durationError: "" }))
		}
	}, [linkStartDateTime, linkEndDateTime, newLingoLinkType])

	useEffect(() => {
		handleTeamMembers()
		console.log(teamMembers)
	}, [])



	function handleBlur(name) {
		touched.current = { ...touched.current, [name]: true }
		if ((touched.current['link_start_date_time'] && !linkStartDateTime) || (touched.current['link_end_date_time'] && !linkEndDateTime)) {
			setErrors(errors => ({ ...errors, durationError: "Both Fields are required!" }))
		}
		else {
			setErrors(errors => ({ ...errors, durationError: "" }))
		}
		touched.current['link_title'] && !linkTitle?.length ? setLinkTitleFLag(true) : setLinkTitleFLag(false)
	}

	//   Todo:API to create new project
	const createDynaLink = () => {
		if (validate()) {
			let locDurationValue = durationValue;
			let locVideoCallValue = videoCallValue
			let locVoiceCallValue = voiceCallValue
			let locCallTimeValue = callTimeValue;
			let linkStartTime = ''
			let isLinkTrial = newLingoLinkType === LINGOLINK_TYPES.SEVEN_DAYS_TRIAL || newLingoLinkType === LINGOLINK_TYPES.FIXED_DURATION
			if (newLingoLinkType === LINGOLINK_TYPES.NO_LIMIT) {
				locDurationValue = 8760;
				locVideoCallValue = 1000;
				locVoiceCallValue = 10000;
				locCallTimeValue = 2920;
			}
			if (newLingoLinkType === LINGOLINK_TYPES.FIXED_DURATION) {
				let durationInMinutes = calculateMinutes()
				locDurationValue = durationInMinutes / 60
				locVideoCallValue = 9999;
				locVoiceCallValue = 9999;
				locCallTimeValue = isCallDurationUnlimited ? 9999 : callTimeValue
				linkStartTime = moment(new Date(linkStartDateTime)).utc().format('YYYY-MM-DD HH:mm:ss')
			}
			setLinkTitleFLag(false);
			let objBody = {
				link_title: linkTitle,
				is_trail: isLinkTrial,
				active_hours: locDurationValue,
				videoCount: locVideoCallValue,
				voiceCount: locVoiceCallValue,
				callTime: locCallTimeValue,
				features: selectedValues.join(","),
				multichannel: linkMultichannel,
				start_time: linkStartTime,
				lang_pref: selectedLangPref
			};
			setSaveDetailsLoader(true);
			addNewLink(objBody)
				.then(res => {
					setSaveDetailsLoader(false);
					if (res?.result) {
						closeDrawer()
						getAllDynamicLinks("active");
						toast(toastFunctionToaster(res.message, "success"));
					} else {
						toast(toastFunctionToaster(res.message, "error"));
					}
				})
				.catch(err => {
					setSaveDetailsLoader(false);
					toast(toastFunctionToaster(err.message, "error"));
				});
		}
	};

	const launchManageLink = () => {
		if (window.location.pathname.includes('/account')) {
			return '/account/linkmanager';
		} else if (window.location.pathname.includes('/business')) {
			return '/business/linkmanager';
		}
		return null;
	}

	// Todo:Re-render list after updating the features
	const handleLink = (data) => {
		getAllDynamicLinks("active")
	}


	// Todo:Reset Project Details
	const resetProjectDetails = (resetLingoLinkType = true, resetLingoLinkTitle = true) => {
		resetLingoLinkTitle && setLinkTitle("");
		setDurationValue(48);
		setVoiceCallValue(5);
		setVoiceCallValue(5);
		setLinkTitleFLag(false);
		resetLingoLinkType && setNewLingoLinkType(LINGOLINK_TYPES.NO_LIMIT)
		setIsCallDurationUnlimited(false)
		setErrors({})
		setLinkStartDatetime(null)
		setLinkEndDateTime(null)
		setSelectedValues(INIT_SELECTED_VALUE)
	};

	// Todo:Reset details on drawer closed
	const closeDrawer = () => {
		onClose();
		resetProjectDetails();
	};

	// Function to handle changes in the CheckboxGroup
	const handleChange = (values) => {
		setSelectedValues(values);
	};

	const renderFeatureName = (name) => {
		if (name === 'hi') {
			return 'Human Interpretation';
		} else if (name === 'bi') {
			return 'Secure AI';
			// } else if (name === 'pi') {
			//     return 'Premium AI';
		} else if (name === 'di') {
			return 'Document AI';
		} else if (name === 'mi') {
			return 'Multichannel AI';
		} else if (name === 'li') {
			return 'Live AI';
		} else if (name === 'ki') {
			return 'Kiosk AI';
		}
	}

	// Todo:Render Feature Image By Code
	const renderFeatureImage = name => {
		if (name === "hi") {
			return HumanAIBg;
		} else if (name === "bi") {
			return BasicAIBg;
		} else if (name === "pi") {
			return PremiumAIBg;
		} else if (name === "di") {
			return DocumentAiLightBg;
		} else if (name === "li") {
			return LiveAiBg;
		} else if (name === "ki") {
			return KioskAiBg;
		} else return;
	};

	const handleTeamMembers = () => {
		const isAccountPage = location.pathname.includes("account");

		if (isAccountPage) {
			handleGetAccountTeamMembers();
		} else {
			handleGetBusinessTeamMembers();
		}
	};


	const handleGetAccountTeamMembers = () => {
		const paramObj = {
			key: 'string'
		}
		getAssocTeamMembers(paramObj).then(resp => {
			if (resp?.data[0]) {
				if (resp?.data[0]?.team_list?.length > 0) {
					setTeamMembers(resp?.data[0].team_list);
				}
				console.log(JSON.stringify(resp?.data[0]?.team_apps));
			} else {
				toast(toastFunctionToaster(resp?.message, "error"))
				setTeamMembers([])
			}
		}).catch(err => {
			toast(toastFunctionToaster("Failed to get Team Menbers", "error"))
			setTeamMembers([])

		})
	}

	const handleGetBusinessTeamMembers = () => {
		isLoading(true);
		let objBody = {
			email: email,
			team_only: true,
			records_per_page: 0,
			page_id: 0,
			records_length: 0,
		};
		fetchAllAssociatesPaginated(objBody)
			.then((res) => {
				isLoading(false);
				if (res.data.length > 0 && res.result === true) {
					console.log(res.data[0])
					setTeamMembers(res.data[0].records);
					//toast(toastFunctionToaster(res.message, "success"));
				} else if (res.result === false) {
					//toast(toastFunctionToaster(res.message, "error"));
				} else {
					//toast(toastFunctionToaster(res.message, "error"));
				}
			})
			.catch((err) => {
				isLoading(false);
				toast(toastFunctionToaster(err.message, "error"));
			});
	};




	const handleTabChange = index => {
		setActiveTab(index);
	};

	const shareDynaLink = () => {
		console.log(toEmails, linkInfo?.uuid)
		if (toEmails?.length > 0 && linkInfo?.uuid != null && (urlGroup == '11' || urlGroup == '10')) {
			let objBody = {
				target_emails: toEmails?.join(","),
				link_uuid: linkInfo?.uuid,
				host_name: currentHost,
				url_group: urlGroup,
				is_code: sendCode,
			};

			shareLinkViaEmail(objBody)
				.then((res) => {
					if (res?.result) {
						toast(toastFunctionToaster(res.message, "success"));
					} else {
						toast(toastFunctionToaster(res.message, "error"));
					}
				})
				.catch((err) => {
					toast(toastFunctionToaster(err.message, "error"));
				});
		} else {
			toast(
				toastFunctionToaster(
					"You must provide email addresse and select which url to share..",
					"error"
				)
			);
		}
	};




	const smsDynaLink = () => {
		if (toSms?.length > 0 && linkInfo?.uuid != null && (urlGroup == '11' || urlGroup == '10')) {
			let objBody = {
				target_phone: toSms[0],
				link_uuid: linkInfo?.uuid,
				host_name: currentHost,
				url_group: urlGroup,
				is_code: sendCode,
			};
			shareLinkViaSms(objBody)
				.then((res) => {
					if (res?.result) {
						toast(toastFunctionToaster(res.message, "success"));
					} else {
						toast(toastFunctionToaster(res.message, "error"));
					}
				})
				.catch((err) => {
					toast(toastFunctionToaster(err.message, "error"));
				});
		} else {
			toast(
				toastFunctionToaster(
					"You must provide Phone number and select which url to send in SMS",
					"error"
				)
			);
		}
	};






	return (
		<>
			<Tabs variant='enclosed-colored' align='end' colorScheme='blue' h="full" display="flex" flexDirection="column" onChange={(index) => setMainActiveTab(index)}
				pt={
					type === 'lingoCustomer' ? { sm: "0px", lg: "0px" } : { sm: '125px', lg: '75px' }}
				mt={type === 'lingoCustomer' ? '0' : 4}
			>
				<TabList h="40px">
					<Tab>My Links</Tab>
					<Tab>Team Links</Tab>
				</TabList>
				<TabPanels h="100%" display="flex" w="full">
					<TabPanel h="100%" flex="1" display="flex" p="0" flexDirection="column" overflow="hidden" >
						<Flex direction="column" h="100%" overflow="hidden">
							{type === 'lingoCustomer' ? 'null' : (
								<Card p="1" px={3} alignItems="center" mt={1} 
									bg={useCardHeaderActiveBg}
									 rounded={'md'} h="60px">
									<Flex w={'100%'} direction={'row'} align={'center'}>
										<Text fontWeight={"100"} fontSize={"18px"}>
											LingoLink {dynamicLinks?.length > 0 ? dynamicLinks?.length : 0} ({linkChoice})
										</Text>
										<Spacer />
										<Flex minW={'440px'} maxW={'440px'} bg={pillButtonBg} rounded={'md'} p={1} pl={2}>
											<Text
												minW={'70px'} maxW={'70px'}
												_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
												onClick={() => {
													setCurrentSelection('Active');
													getAllDynamicLinks("active")
												}}
												p={2} roundedLeft={'md'}
												color={currentSelection === 'Active' ? 'white' : textColor}
												bg={currentSelection === 'Active' ? pillButtonBgSelected : pillButtonBg}>
												Active
											</Text>
											<Text
												minW={'80px'} maxW={'80px'}
												_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
												onClick={() => {
													setCurrentSelection('closed');
													getAllDynamicLinks("closed")
												}}
												color={currentSelection === 'closed' ? 'white' : textColor}
												p={2}
												bg={currentSelection === 'closed' ? pillButtonBgSelected : pillButtonBg}>
												Closed
											</Text>
											<Text
												_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
												onClick={() => {
													getAllDynamicLinks("active");
													setCurrentSelection('refresh');
												}}
												color={currentSelection === 'refresh' ? 'white' : textColor}
												p={2} bg={currentSelection === 'refresh' ? pillButtonBgSelected : pillButtonBg} pl={5}
												pr={5}>Refresh
											</Text>
											<Text
												_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
												onClick={() => {
													onOpen();
													// setCurrentSelection('new');
												}}
												roundedRight={'md'}
												color={currentSelection === 'new' ? 'white' : textColor}
												p={2} bg={currentSelection === 'new' ? pillButtonBgSelected : pillButtonBg} pl={5}
												pr={5}>
												<Icon pt={1} as={AiOutlinePlus} mr={1} />
												Create New Link
											</Text>
										</Flex>
									</Flex>
								</Card>)}
							{loading ? (
								<Flex alignItems="center" justifyContent="center" h={type === 'lingoCustomer' ? '10vh' : '50vh'}>
									<Spinner size="xl" />
								</Flex>
							) : dynamicLinks?.length ? (
								<Card w={"100%"} borderRadius='10px' mt={1} p="0" overFlow="hidden" h="calc(100% - 90px)">
									<Flex flexDirection="column" h="100%" overflow="hidden">
										<Flex w={"100%"} rounded={"md"} direction={"column"} p={1}
											overflow="auto" className="custom-scrollbar" h="90%">
											<RenderDynamicLinks dynamicLinks={dynamicLinks} setModalQrCode={setModalQrCode} onOpenQr={onOpenQr} setShowTooltip={setShowTooltip} availableLangs={availableLangs} subAppDetails={subAppDetails} langPrefList={langPrefList} handleLink={handleLink} type={type} accountId={props?.accountId} locationId={props?.locationId} isEnabled={isFeatureEnabledTab1} />
										</Flex>
										<Flex w="full">
											{dynamicLinks?.length ? (
												<Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} setRecordsPerPage={setRecordsPerPage} totalPages={totalPages} recordsPerPage={recordsPerPage} />
											) : null}
										</Flex>
									</Flex>
								</Card>
							) : (
								<Alert status="warning" mt="4">
									<AlertIcon />
									<Text>You dont have any LingoLink yet... </Text>
									<Text ml={5}>
										<b>CREATE NEW LINGOLINK!!</b>
									</Text>
								</Alert>
							)}
						</Flex>

						{/* // Add Project Drawer */}
						<Drawer
							size="lg"
							isOpen={isOpen}
							placement="right"
							onClose={() => {
								closeDrawer();
							}}
							finalFocusRef={btnRef}
						>
							<DrawerOverlay />
							<DrawerContent>
								<DrawerCloseButton />
								<DrawerHeader borderBottomWidth={"1px"}>{"Create New LingoLink"}</DrawerHeader>
								<DrawerBody overflow="hidden" overflowY="scroll" mt="5">
									<Flex w={"100%"} direction={"column"}>
										<Flex w={'100%'} rounded={'md'}
											mt={2} direction={'column'} p={2} borderWidth={'0.5px'} borderColor={'gray.200'}>
											<InputField
												mb="0"
												me="30px"
												id="link_title"
												label="LingoLink Name"
												value={linkTitle}
												name="link_title"
												required="true"
												borderRadius="5px"
												bg="inherit"
												onChange={e => {
													setLinkTitle(e.target.value);
												}}
												onBlur={e => handleBlur(e.target.name)}
												placeholder="Enter Link Name"
												w="100%"
												maxlength={100}
											/>
											<Flex alignItems={"center"} justifyContent={"space-between"}>
												{linkTitleFlag ? (
													<Text fontSize="sm" color={"red"} mt="1">
														LingoLink Name is Required
													</Text>
												) : null}
												<Spacer />
												<Text textAlign={"right"} fontWeight={"100"} fontSize={"sm"} mt="2">
													{linkTitle?.length}/100
												</Text>
											</Flex>
										</Flex>
										<Flex w={'100%'} direction={'column'}>
											<Flex w={'100%'} bg={pillButtonBg} rounded={'md'} p={1} pl={2}>
												<Text
													minW={'50%'} maxW={'50%'}
													_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
													onClick={() => {
														setLinkMultichannel(false);
														setSelectedValues(INIT_SELECTED_VALUE);
													}}
													p={2} roundedLeft={'md'}
													color={linkMultichannel === false ? 'white' : textColor}
													bg={linkMultichannel === false ? pillButtonBgSelected : pillButtonBg}>
													Multipurpose LingoLink
												</Text>
												{subAppDetails?.length && subAppDetails?.includes('mi') ? (
													<Text
														minW={'50%'} maxW={'50%'}
														_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
														onClick={() => {
															setLinkMultichannel(true);
															setSelectedValues(['mi']);
														}}
														roundedRight={'md'}
														color={linkMultichannel === true ? 'white' : textColor}
														p={2}
														bg={linkMultichannel === true ? pillButtonBgSelected : pillButtonBg}>
														Multichannel LingoLink
														{/* {JSON.stringify(subAppDetails)} */}
													</Text>
												) : null}
											</Flex>
										</Flex>
										{linkMultichannel === true
											? <Flex w={'100%'} bg={'red.50'} rounded={'lg'} mt={1} direction={'column'} p={2}>
												<Flex alignItem='center'>
													<Image h="100px" w="100px" mx="1" borderRadius="50px" src={MultichannelBg} />
													<Flex w={'100%'} direction={'column'} color="black">
														<Text as="span">
															MultiChannel AI
														</Text>
														<Text as="span">
															Main Language:
														</Text>
														<Text as="span">
															<b>English</b>
														</Text>
														<Text as="span">
															Other Language:
														</Text>
														<Text as="span">
															<b>Selected at Connecting Time</b>
														</Text>
													</Flex>
												</Flex>
											</Flex>
											: <Flex w={'100%'} rounded={'lg'} mt={1} direction={'column'}>
												<Flex
													w={"100%"}
													direction={"column"}
													p={1}
													rounded={"md"}
													borderWidth={"0.5px"}
													borderColor={"gray.200"} mt="3">
													<Flex w={"100%"} align={"center"} p={2} rounded={'md'} color="black">
														<RadioGroup defaultValue={newLingoLinkType} onChange={value => setNewLingoLinkType(value)}>
															<Stack spacing={4} direction='row' color={pillButtonText}>
																<Radio variant="contained" colorScheme="gray" value={LINGOLINK_TYPES.NO_LIMIT}>Unlimited LingoLink</Radio>
																{/* <Radio value={LINGOLINK_TYPES.SEVEN_DAYS_TRIAL}>7 Days Trial LingoLink</Radio> */}
																<Radio variant="contained" value={LINGOLINK_TYPES.FIXED_DURATION}>Fixed Day or Duration LingoLink</Radio>
															</Stack>
														</RadioGroup>
														<Spacer />
													</Flex>
													{newLingoLinkType === LINGOLINK_TYPES.SEVEN_DAYS_TRIAL && (
														<Flex w={"100%"} direction={"column"}>
															<FormLabel my="3" ml="2" required>
																<Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
																	Link Activity Duration (Hours)
																</Text>
																<Flex
																	mt={5}
																	h={"100px"}
																	w={"100%"}
																	rounded={"md"}
																	borderWidth={"0.5px"}
																	borderColor={"gray.200"}>
																	<Slider
																		aria-label="slider-ex-1"
																		colorScheme="teal"
																		min={2}
																		max={168}
																		step={1}
																		defaultValue={48}
																		onChange={v => setDurationValue(v)}
																		onMouseEnter={() => setShowTooltip(true)}
																		onMouseLeave={() => setShowTooltip(false)}>
																		<SliderMark value={24} {...labelStyles}>
																			24
																		</SliderMark>
																		<SliderMark value={48} {...labelStyles}>
																			48
																		</SliderMark>
																		<SliderMark value={72} {...labelStyles}>
																			72
																		</SliderMark>
																		<SliderMark value={96} {...labelStyles}>
																			96
																		</SliderMark>
																		<SliderMark value={120} {...labelStyles}>
																			120
																		</SliderMark>
																		<SliderMark value={144} {...labelStyles}>
																			144
																		</SliderMark>
																		<SliderMark value={168} {...labelStyles}>
																			168
																		</SliderMark>
																		<SliderTrack>
																			<SliderFilledTrack />
																		</SliderTrack>
																		<Tooltip
																			hasArrow
																			bg="teal.500"
																			color="white"
																			placement="top"
																			isOpen={showTooltip}
																			label={`${durationValue} Hours`}>
																			<SliderThumb />
																		</Tooltip>
																	</Slider>
																</Flex>
															</FormLabel>
															<Flex w={"100%"} align={"center"}>
																<Flex
																	w={"50%"}
																	h={"100px"}
																	direction={"column"}
																	py={1}
																	px={5}
																	rounded={"md"}
																	borderWidth={"0.5px"}
																	borderColor={"gray.200"}>
																	<Text>
																		Voice Call Count: <b>{voiceCallValue}</b>
																	</Text>
																	<Slider
																		mt={8}
																		aria-label="slider-ex-1"
																		colorScheme="teal"
																		min={1}
																		max={50}
																		step={1}
																		defaultValue={5}
																		onChange={v => setVoiceCallValue(v)}
																		onMouseEnter={() => setShowTooltip(true)}
																		onMouseLeave={() => setShowTooltip(false)}>
																		<SliderMark value={1} {...labelStylesVoice}>
																			1
																		</SliderMark>
																		<SliderMark value={25} {...labelStylesVoice}>
																			25
																		</SliderMark>
																		<SliderMark value={50} {...labelStylesVoice}>
																			50
																		</SliderMark>
																		<SliderTrack>
																			<SliderFilledTrack />
																		</SliderTrack>
																		<Tooltip
																			hasArrow
																			bg="teal.500"
																			color="white"
																			placement="top"
																			isOpen={showTooltip}
																			label={`${voiceCallValue}`}>
																			<SliderThumb />
																		</Tooltip>
																	</Slider>
																</Flex>
																<Flex
																	ml={1}
																	w={"50%"}
																	h={"100px"}
																	direction={"column"}
																	py={1}
																	px={5}
																	rounded={"md"}
																	borderWidth={"0.5px"}
																	borderColor={"gray.200"}>
																	<Text>
																		Video Call Count: <b>{videoCallValue}</b>
																	</Text>
																	<Slider
																		mt={8}
																		aria-label="slider-ex-1"
																		colorScheme="teal"
																		min={1}
																		max={50}
																		step={1}
																		defaultValue={5}
																		onChange={v => setVideoCallValue(v)}
																		onMouseEnter={() => setShowTooltip(true)}
																		onMouseLeave={() => setShowTooltip(false)}>
																		<SliderMark value={1} {...labelStylesVoice}>
																			1
																		</SliderMark>
																		<SliderMark value={25} {...labelStylesVoice}>
																			25
																		</SliderMark>
																		<SliderMark value={50} {...labelStylesVoice}>
																			50
																		</SliderMark>
																		<SliderTrack>
																			<SliderFilledTrack />
																		</SliderTrack>
																		<Tooltip
																			hasArrow
																			bg="teal.500"
																			color="white"
																			placement="top"
																			isOpen={showTooltip}
																			label={`${videoCallValue}`}>
																			<SliderThumb />
																		</Tooltip>
																	</Slider>
																</Flex>
															</Flex>
															<Flex
																mt="3"
																w={"100%"}
																h={"100px"}
																direction={"column"}
																py={1}
																px={5}
																rounded={"md"}
																borderWidth={"0.5px"}
																borderColor={"gray.200"}>
																<Text>
																	Maximum minutes available in this Trial call: <b>{callTimeValue}</b>{" "}
																	minutes.
																</Text>
																<Slider
																	mt={10}
																	aria-label="slider-ex-1"
																	colorScheme="teal"
																	min={5}
																	max={600}
																	step={5}
																	defaultValue={5}
																	onChange={v => setCallTimeValue(v)}
																	onMouseEnter={() => setShowTooltip(true)}
																	onMouseLeave={() => setShowTooltip(false)}>
																	<SliderMark value={5} {...labelStylesVoice}>
																		5
																	</SliderMark>
																	<SliderMark value={100} {...labelStylesVoice}>
																		100
																	</SliderMark>
																	<SliderMark value={200} {...labelStylesVoice}>
																		200
																	</SliderMark>
																	<SliderMark value={300} {...labelStylesVoice}>
																		300
																	</SliderMark>
																	<SliderMark value={400} {...labelStylesVoice}>
																		400
																	</SliderMark>
																	<SliderMark value={500} {...labelStylesVoice}>
																		500
																	</SliderMark>
																	<SliderMark value={600} {...labelStylesVoice}>
																		600
																	</SliderMark>
																	<SliderTrack>
																		<SliderFilledTrack />
																	</SliderTrack>
																	<Tooltip
																		hasArrow
																		bg="teal.500"
																		color="white"
																		placement="top"
																		isOpen={showTooltip}
																		label={`${callTimeValue}`}>
																		<SliderThumb />
																	</Tooltip>
																</Slider>
															</Flex>
														</Flex>
													)}
													{
														newLingoLinkType === LINGOLINK_TYPES.NO_LIMIT && (
															<Box w="full" p={2} border="1px" borderColor="green.200" bg="green.100" borderRadius="lg" mt="4">
																<Text color="black">This LingoLink Type has no restrictions. It will be active as long as you do not disable it.</Text>
															</Box>
														)
													}
													{
														newLingoLinkType === LINGOLINK_TYPES.FIXED_DURATION && (
															<Flex w="full" p={3} mt={3} direction="column">
																<Flex w="full" direction="column">
																	<Flex w="full" justifyContent="space-between">
																		<FormControl w="45%">
																			<FormLabel htmlFor="startDate" >Start Date-Time<Text color="red.400" as="span">*</Text></FormLabel>
																			{/* <Input required type="datetime-local" variant="outline" value={linkStartDateTime} onChange={e => setLinkStartDatetime(e.target.value)} /> */}
																			<Flatpickr options={{
																				enableTime: true,
																				dateFormat: "Y-m-d H:i",
																				minuteIncrement: 15,
																				minDate: 'today',
																			}}
																				altInputClass="flat-picker"
																				value={linkStartDateTime}
																				name="link_start_date_time"
																				onBlur={(e) => {
																					handleBlur(e.target.name)
																				}}
																				onChange={value => setLinkStartDatetime(value[0])}
																				style={DatetimePickerStyles}
																				placeholder="Select Date Range"
																				id="startDate"
																			/>
																		</FormControl>
																		<FormControl w="45%">
																			<FormLabel htmlFor="endDate" >End Date-Time<Text color="red.400" as="span">*</Text></FormLabel>
																			{/* <Input required type="datetime-local" variant="outline" value={linkStartDateTime} onChange={e => setLinkStartDatetime(e.target.value)} /> */}
																			<Flatpickr options={{
																				enableTime: true,
																				dateFormat: "Y-m-d H:i",
																				minuteIncrement: 15,
																				minDate: 'today',
																			}}
																				altInputClass="flat-picker"
																				onBlur={e => handleBlur(e.target.name)}
																				name="link_end_date_time"
																				value={linkEndDateTime}
																				onChange={value => setLinkEndDateTime(value[0])}
																				style={DatetimePickerStyles}
																				placeholder="Select Date Range"
																				id="endDate"
																			/>
																		</FormControl>
																	</Flex>


																	{errors?.durationError ? <Text color="red.600" fontSize="sm" mt={2}>{errors?.durationError}</Text> : null}
																</Flex>
																<Flex
																	mt="3"
																	w={"100%"}
																	h={"140px"}
																	direction={"column"}
																	py={1}
																	px={5}
																	rounded={"md"}
																	borderWidth={"0.5px"}
																	borderColor={"gray.200"}>
																	<Text>
																		Maximum minutes available in this call: <b>{isCallDurationUnlimited ? 'unlimited' : callTimeValue}</b>{" "}
																		minutes.
																	</Text>
																	<Checkbox mt='2' isChecked={isCallDurationUnlimited} onChange={e => setIsCallDurationUnlimited(!isCallDurationUnlimited)}>Make it unlimited</Checkbox>
																	<Slider
																		isDisabled={isCallDurationUnlimited}
																		mt={5}
																		aria-label="slider-ex-1"
																		colorScheme="teal"
																		min={5}
																		max={600}
																		step={5}
																		defaultValue={5}
																		onChange={v => setCallTimeValue(v)}
																		onMouseEnter={() => setShowTooltip(true)}
																		onMouseLeave={() => setShowTooltip(false)}>
																		<SliderMark value={5} {...labelStylesVoice}>
																			5
																		</SliderMark>
																		<SliderMark value={100} {...labelStylesVoice}>
																			100
																		</SliderMark>
																		<SliderMark value={200} {...labelStylesVoice}>
																			200
																		</SliderMark>
																		<SliderMark value={300} {...labelStylesVoice}>
																			300
																		</SliderMark>
																		<SliderMark value={400} {...labelStylesVoice}>
																			400
																		</SliderMark>
																		<SliderMark value={500} {...labelStylesVoice}>
																			500
																		</SliderMark>
																		<SliderMark value={600} {...labelStylesVoice}>
																			600
																		</SliderMark>
																		<SliderTrack>
																			<SliderFilledTrack />
																		</SliderTrack>
																		<Tooltip
																			hasArrow
																			bg="teal.500"
																			color="white"
																			placement="top"
																			isOpen={showTooltip}
																			label={`${callTimeValue}`}>
																			<SliderThumb />
																		</Tooltip>
																	</Slider>
																</Flex>
															</Flex>
														)
													}
												</Flex>
												<Flex w={'100%'} rounded={'md'}
													mt={5} direction={'column'} p={2} borderWidth={'0.5px'} borderColor={'gray.200'}>
													<Text fontSize={'17px'} my={2} fontWeight={'bold'}>Select LingoLink Applications</Text>
													{
														subAppDetails.length > 0 ? <CheckboxGroup colorScheme='blue' value={selectedValues} onChange={handleChange}>
															<SimpleGrid columns={2} gap="2px" mt="2" >
																{
																	subAppDetails?.map(app => (
																		<Checkbox value={app} isChecked={selectedValues.indexOf(app) > -1} key={app}>
																			<Flex alignItem='center'>
																				<Image h="40px" w="40px" mx="1	" borderRadius="50px" src={renderFeatureImage(app)} />
																				<Text as="span" >
																					{renderFeatureName(app)}
																				</Text>
																			</Flex>
																		</Checkbox>
																	))
																}
															</SimpleGrid>
														</CheckboxGroup> :
															<Alert status='info'>
																<AlertIcon />
																No Apps Found!!
															</Alert>
													}

												</Flex>
												{langPrefList?.length ? <Flex w={'100%'} rounded={'md'}
													mt={5} direction={'column'} p={2} borderWidth={'0.5px'} borderColor={'gray.200'}>
													<Text fontSize={'17px'} my={2} fontWeight={'bold'}>Select Language Preference</Text>
													<Select defaultValue={"none"} value={selectedLangPref} onChange={e => setSelectedLangPref(e.target.value)}>
														<option style={{
															color: pillButtonText
														}} value={"none"} >Select language preference</option>
														{
															langPrefList.map(pref => (
																<option style={{
																	color: pillButtonText
																}} value={pref.language_preference_uuid} key={pref?.language_preference_uuid}>{pref?.language_preference_name}</option>
															))
														}
													</Select>

												</Flex> : null}
											</Flex>}
									</Flex>
								</DrawerBody>
								<DrawerFooter>
									<Flex w={"100%"} direction={"row"}>
										<Button
											variant="outline"
											mr={3}
											onClick={() => {
												onClose();
												resetProjectDetails();
											}}>
											Cancel
										</Button>
										<Button
											colorScheme="blue"
											onClick={() => {
												// resetProjectDetails();
												createDynaLink();
											}}
											isDisabled={
												!linkTitle || !selectedValues.length || saveDetailsLoader
											}
											isLoading={saveDetailsLoader ? true : false}>
											{"Create New Link"}
										</Button>
									</Flex>
								</DrawerFooter>
							</DrawerContent>
						</Drawer>


					</TabPanel>
					<TabPanel h="100%" flex="1" display="flex" p="0" flexDirection="column" overflow="hidden">
						<Flex h="full" overflow="hidden" w="full">
							<Card p="1" h="full" overflow="auto">
								<Flex h="full">
									<Box
										w={!configurationCollapseFlag ? "95%" : "78%"}
										bg={brandBg}
										p="2"
										borderRadius={"10px"}
										h="full"
										className="custom-scrollbar"
										overflow="hidden"
									>
										<Flex w={"100%"} rounded={"md"} align={'start'} direction={"column"}
											p={0}
											overflowY="auto" className="custom-scrollbar" h="full">
												{TeamDynamicLinks?.length > 0
												?<RenderDynamicLinks 
													dynamicLinks={TeamDynamicLinks} 
													setModalQrCode={setModalQrCode} 
													onOpenQr={onOpenQr} 
													setShowTooltip={setShowTooltip} 
													availableLangs={availableLangs} 
													subAppDetails={subAppDetails} 
													langPrefList={langPrefList} 
													handleLink={handleLink} 
													type={type} 
													accountId={props?.accountId} 
													locationId={props?.locationId} 
													isEnabled={isFeatureEnabledTab2} 
													onDrawerOpen={onDrawerOpen} 
													setLinkInfo={setLinkInfo} />
												:<Text align={'start'} w={'100%'} rounded={'lg'} py={3} px={2} bg={'red.100'}>
													No links created by selected team member
												</Text>}
										</Flex>
									</Box>
									{configurationCollapseFlag ? (
										<Box
											w="22%"
											position="relative"
											p="2"
											h="full"
											borderRadius="15px"
											bg={brandBg}
											ml="2"
											overflow="hidden"
										>
											<Box
												position="absolute"
												top="0"
												right="0"
												mr="2"
												mt="3"
												zIndex="999"
											>
												<AiFillRightCircle
													cursor="pointer"
													size="20px"
													onClick={() => {
														setConfiguationCollapseFlag(!configurationCollapseFlag);
													}}
												/>
											</Box>
											<Box w="100%" h="full" overflow="hidden">
												<Text fontSize="lg" mr={10} fontWeight="bold" mb="4">Team Members</Text>
												<Card h="94%" p="0" overflow="auto" className="custom-scrollbar">
													<Flex direction="column" 
														alignItems={'start'}
														pt="5" gap="2" h="full" pb={2} flexDir="Column" overflow="auto" className="custom-scrollbar" p="2">
														{teamMembers.map((member, index) => (
															<Text 
																alignSelf={'start'}
																w={'100%'} p={1} rounded={'lg'}
																bg={usePillButtonBg}
																_hover={{ cursor: "pointer", color: "blue.500" }} 
																key={index} mb="2" 
																onClick={() => { member.user_uuid ? getAllTeamDynamicLinks(member.user_uuid) : getAllTeamDynamicLinks(member.uuid) }}>
																	{member.email}
															</Text>
														))}
													</Flex>
												</Card>
											</Box>
										</Box>

									) : (
										<Box
											w="5%"
											p="3"
											borderRadius="10px"
											bg={brandBg}
											minH="100%"
											ml="2"
											pl="5"
										>
											<Box w={"100%"} h="100%">
												<AiFillLeftCircle
													cursor="pointer"
													onClick={() => {
														setConfiguationCollapseFlag(
															!configurationCollapseFlag
														);
													}}
													size="20px"
												/>
												<Text
													fontSize="16px"
													mt="2"
													css={{
														writingMode: "vertical-lr",
														textOrientation: "mixed",
													}}
												>
													Expand Management Settings
												</Text>
											</Box>
										</Box>
									)}
								</Flex>
							</Card>
						</Flex>


						<Drawer
							isOpen={onDrawerisOpen}
							placement='right'
							onClose={onShareDrawerClose}
							size="lg"

						>
							<DrawerOverlay />
							<DrawerContent>
								<DrawerCloseButton />


								<DrawerBody>
									<Tabs
										w="100%"
										borderBottom={"0px"}
										mx="auto"
										onChange={handleTabChange}
										index={activeTab}
									>
										<TabList border="0px" w="100%" h="5%">
											<Flex h={'30px'}>
												<Tab
													_selected={{
														color: defaultThemeColor,
														fontWeight: 600,
														borderBottomColor: defaultThemeColor,
													}}
													fontSize="16px"
													px="6"
													py="2"
												>
													<Icon as={AiOutlineBulb} mr={1} />
													Email LingoLink
												</Tab>
												<Tab
													_selected={{
														color: defaultThemeColor,
														fontWeight: 600,
														borderBottomColor: defaultThemeColor,
													}}
													fontSize="16px"
													px="6"
													py="2"
												>
													<Icon as={AiOutlineBulb} mr={1} />
													SMS LingoLink
												</Tab>
											</Flex>
										</TabList>
										<TabPanels mt="1" h="100%">
											<TabPanel h="100%" p={2}>
												<Flex w={'100%'} size={'xl'} direction={'column'}>
													<Text>{"Email LingoLink to Subscriber(s)"}</Text>
												</Flex>
												<Text mt={5} mb="2" fontSize={"sm"}>
													Please select which URL you want to share in  this email:
												</Text>
												<RadioGroup onChange={setUrlGroup} value={urlGroup}>
													<Stack direction='column'>
														<Flex w={'100%'} direction={'column'} rounded={'md'} p={2} borderWidth={'0.5px'} borderColor={'green.500'}>
															<Radio value={'10'}>{currentHost + linkInfo?.url}</Radio>
															<Text mt={1} fontSize={'sm'}>Note: Sharing this link URL will require you to share Link Access Code seperately to target user. Without code LingoLink is not accessible.</Text>
														</Flex>
														<Flex w={'100%'} direction={'column'} rounded={'md'} p={2} borderWidth={'0.5px'} borderColor={'green.500'}>
															<Radio value={'11'}>{currentHost + linkInfo?.fullurl}</Radio>
															<Text mt={1} fontSize={'sm'}>Note: If you share this LingoLink, target user can access it without the code, because the code is embedded into the LingoLink.</Text>
														</Flex>
													</Stack>
												</RadioGroup>
												<Text mb="2" fontSize={"md"} color={"blue.500"}>

												</Text>
												<FormLabel mt="3" ml="2" required>
													<Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
														To Email Address(es) (Maxium 5 Emails):
													</Text>
													<TagsInput
														style={{ width: '100%' }}
														maxTags={5}
														value={toEmails}
														onChange={tags => {
															setToEmails(tags)

														}

														}
														inputProps={{ placeholder: 'Enter Email' }}
													/>

													<Flex w={'100%'}>
														<Text
															textAlign={"right"}
															fontWeight={"500"}
															fontSize={"sm"}
															mt="2"
														>
															Please click ENTER at the end of email to add.
														</Text>
														<Spacer />
														<Text
															textAlign={"right"}
															fontWeight={"500"}
															fontSize={"sm"}
															mt="2"
														>
															{toEmails?.length}/5
														</Text>
													</Flex>
													<Text mt={1}>
														Note: An email will be sent to all above emails address with the
														link
													</Text>
												</FormLabel>
												<FormLabel mt="3" ml="2" required>
													<Flex w={"100%"}>
														<Checkbox
															onChange={() => setSendCode(!sendCode)}
															checked={sendCode}
														></Checkbox>
														<Text ml={1}>
															Do you want to add Link Access Code with Email too?
														</Text>
													</Flex>
												</FormLabel>
												<Flex w={'100%'} mt={5}>
													<Button
														colorScheme="blue"
														onClick={() => {
															shareDynaLink();
														}}
														isDisabled={
															toEmails === null || toEmails?.length === 0 ? true : false
														}
														isLoading={saveDetailsLoader ? true : false}
													>
														Send Email
													</Button>
												</Flex>
											</TabPanel>
											<TabPanel p={2} h="100%">
												<Flex w={'100%'} size={'xl'} direction={'column'}>
													<Text>{"SMS LingoLink to Subscriber(s)"}</Text>
												</Flex>
												<Text mt={5} mb="2" fontSize={"sm"}>
													Please select which URL you want to share in  this SMS:
												</Text>
												<RadioGroup onChange={setUrlGroup} value={urlGroup}>
													<Stack direction='column'>
														<Flex w={'100%'} direction={'column'} rounded={'md'} p={2} borderWidth={'0.5px'} borderColor={'green.500'}>
															<Radio value={'10'}>{currentHost + linkInfo?.url}</Radio>
															<Text mt={1} fontSize={'sm'}>Note: Sharing this link URL will require you to share Link Access Code seperately to target user. Without code LingoLink is not accessible.</Text>
														</Flex>
														<Flex w={'100%'} direction={'column'} rounded={'md'} p={2} borderWidth={'0.5px'} borderColor={'green.500'}>
															<Radio value={'11'}>{currentHost + linkInfo?.fullurl}</Radio>
															<Text mt={1} fontSize={'sm'}>Note: If you share this LingoLink, target user can access it without the code, because the code is embedded into the LingoLink.</Text>
														</Flex>
													</Stack>
												</RadioGroup>
												<FormLabel mt="3" ml="2" required>
													<Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
														Phone Number (+1):
													</Text>
													<TagsInput
														style={{ width: '100%' }}
														maxTags={1}
														value={toSms}
														onChange={tags => setToSms(tags)}
														inputProps={{ placeholder: 'Enter Phone Number' }}
													/>
													<Flex w={'100%'}>
														<Text
															textAlign={"right"}
															fontWeight={"500"}
															fontSize={"sm"}
															mt="2"
														>
															Please click ENTER at the end of Phone number.
														</Text>
														<Spacer />
														<Text
															textAlign={"right"}
															fontWeight={"500"}
															fontSize={"sm"}
															mt="2"
														>
															{JSON.stringify(toSms)?.length}/20
														</Text>
													</Flex>
													<Text mt={1}>
														Note: A SMS will be sent to above phone number with selected link.
													</Text>
												</FormLabel>
												<Flex w={'100%'} mt={20}>
													<Button
														colorScheme="blue"
														onClick={() => {
															smsDynaLink();
														}}
														isDisabled={
															toSms === null || toSms?.length === 0 ? true : false
														}
														isLoading={saveDetailsLoader ? true : false}
													>
														Send SMS
													</Button>
												</Flex>
											</TabPanel>
										</TabPanels>
									</Tabs>
								</DrawerBody>

								<DrawerFooter>
									<Button variant='outline' mr={3} onClick={onShareDrawerClose}>
										Cancel
									</Button>

								</DrawerFooter>
							</DrawerContent>
						</Drawer>




					</TabPanel>

				</TabPanels>

			</Tabs>

			<Modal finalFocusRef={qrRef} isOpen={isOpenQr} onClose={onCloseQr}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>QR Code for the LingoLink</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{modalQrCode != null ? (
							<Flex w={"100%"} direction={"column"}>
								<QRCode
									title={currentHost + modalQrCode}
									size={90}
									style={{ height: "auto", maxWidth: "100%", width: "100%" }}
									value={currentHost + modalQrCode}
									viewBox={`0 0 90 90`}
								/>
								<Text p={1} bg={"blue.100"}>
									{currentHost + modalQrCode}
								</Text>
							</Flex>
						) : (
							<Text color={"red.400"}>There is some issue with selected URL, please try again..</Text>
						)}
					</ModalBody>
					<ModalFooter>
						<Button
							size={"sm"}
							colorScheme="blue"
							onClick={() => {
								setModalQrCode(null);
								onCloseQr();
							}}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>


	);
}

export default LinkListing;