import React from 'react'
import { useState } from 'react';
import {
    Alert, AlertDescription, AlertIcon,
    AlertTitle, Badge, Box,
    Button, Card, Flex, Icon, IconButton,
    Input, InputGroup, InputLeftElement,
    Spinner, Spacer,
    Table, Tbody, Td, Th, Thead, Tr, useToast, Text, Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure
} from '@chakra-ui/react';



import { FaFilePdf } from 'react-icons/fa6';
import { LuDownload } from "react-icons/lu";
import { useEffect } from 'react';
import Pagination from '../../../components/pagination/Pagination';
import { HiOutlineSearch } from 'react-icons/hi';
import { FaFileAlt } from 'react-icons/fa';
import { getContentForView } from '../../../services/moduleServices';
import { toastFunctionToaster } from '../../../utils/toastFunction';
import TranslatedDocumentView from './TranslatedDocumentView';
import DocAISampleEmail from '../DocAiSampleEmail';
import ContentWorkflow from './ContentWorkflow';
import { useTextColor } from '../../../theme/globalColorTheme';

const ControlSpaceAccessContents = ({ handleGetContentList, data, pageId, setRecordsPerPage, setPageId, recordsPerPage, totalPages, loading, handleModalOpen, projectUuid, jwtToken }) => {
    const [filterData, setFilterData] = useState([])
    const [searchValue, setSearchValue] = useState(null)
    const [btnLoading, SetBtnLoading] = useState({ download: false })
    const {
        isOpen: isEmailDrawerOpen,
        onOpen: onEmailDrawerOpen,
        onClose: onEmailDrawerClose,
    } = useDisclosure();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [uri, setUri] = useState(null);
    const [contentType, setContentType] = useState(null);
    const toast = useToast()
    const { isOpen: isViewOpen, onOpen: onViewOpen, onClose: onViewClose } = useDisclosure();
    const [panelType, setPanelType] = useState(null);
    const [displayContent, setDisplayContent] = useState(null)

    function handleShowTranslatedDoc(url, type) {
        setUri(url)
        setContentType(type)
        onOpen()
    }

    const closePanelFunction = () => {
        onViewClose();
        // reload data here
    };

    function handleFilterData(searchValue) {
        if (!searchValue) {
            setFilterData(data)
            return
        }
        const newFilteredData = data?.filter(item => item?.content_title?.indexOf(searchValue) > -1)
        setFilterData(newFilteredData)
    }

    function handleShowFile(data) {
        handleModalOpen(data)
    }

    function handleDownloadFile(item) {
        SetBtnLoading(btnLoading => ({ ...btnLoading, download: item?.uuid }))
        const paramObj = {
            project_uuid: projectUuid,
            content_uuid: item.uuid
        }
        getContentForView(paramObj)
            .then(resp => {
                if (resp?.status === "success") {
                    const fileUrl = resp?.data?.[0]?.url;
                    const fileName = resp?.data?.[0]?.title || "downloaded_file";

                    if (fileUrl) {
                        const link = document.createElement("a");
                        link.href = fileUrl;
                        link.download = fileName;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    } else {
                        alert("No file URL available for download.");
                    }
                } else {
                    toast(toastFunctionToaster("Failed to download File", "error"))
                }
            })
            .catch(() => {
                toast(toastFunctionToaster("Failed to download File", "error"))
            })
            .finally(() => {
                SetBtnLoading(btnLoading => ({ ...btnLoading, download: null }))
            })

    }

    const handleDownloadFileUsingS3 = (url, fileName) => {
        try {
            const link = document.createElement("a");
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        catch (e) {
            toast(toastFunctionToaster("Failed to download File", "error"))
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            handleFilterData(searchValue)
        }, 200)
        return () => {
            clearTimeout(timer)
        }
    }, [searchValue, data])

    useEffect(() => {
        if (data) {
            setFilterData(data)
        }
    }, [data])
    return (
        <>
            <Card direction="column" p="0" mt="5" h="full" w="full">
                <Box w={"100%"} h="full">
                    <Card px="0" h="full">

                        <Flex alignItems="center" p="2">
                            {data && data?.length > 0 && <InputGroup w="30%">
                                <InputLeftElement pointerEvents='none'>
                                    <Icon as={HiOutlineSearch} color="gray.400" />
                                </InputLeftElement>
                                <Input type='search' placeholder='Search..' borderRadius="lg" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                            </InputGroup>}
                            <Spacer />
                            <Button rounded={'lg'} bg={'blue.100'} size="sm" ml="auto" onClick={onEmailDrawerOpen} >
                                Translation Email Help
                            </Button>
                            <Button size={'sm'} ml={1} rounded={'lg'} bg={'blue.100'} onClick={handleGetContentList}>
                                Refresh
                            </Button>
                        </Flex>

                        {loading ? (
                            <Flex alignItems="center" justifyContent="center" mt="10" h="50vh">
                                <Spinner size="xl" />
                            </Flex>
                        ) : filterData && filterData.length > 0 ? (
                            <Box overflow="hidden" p={4} position="relative" h="full">
                                <Box
                                    overflowY="auto"
                                    maxH="calc(100% - 50px)"
                                    className="custom-scrollbar"
                                >
                                    <Table variant="simple">
                                        <Thead position="sticky" top="0" bg="white" zIndex="1">
                                            <Tr borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                <Th borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                    TITLE
                                                </Th>
                                                <Th borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                    OVERWRITTEN
                                                </Th>
                                                <Th borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                    STORED
                                                </Th>
                                                <Th borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                    ACTION
                                                </Th>
                                                <Th borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                    TRANSLATED CONTENT
                                                </Th>
                                                <Th borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                    Action
                                                </Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {filterData.map((item) => (
                                                <Tr
                                                    key={item?.uuid}
                                                    borderBottomWidth="1px"
                                                    borderBottomColor="#d7d7d7"
                                                >
                                                    <Td display="flex" alignItems="center">
                                                        <Icon
                                                            as={
                                                                item.type === "application/pdf"
                                                                    ? FaFilePdf
                                                                    : FaFileAlt
                                                            }
                                                            color={
                                                                item.type === "application/pdf"
                                                                    ? "blue.500"
                                                                    : "green.500"
                                                            }
                                                            mr={2}
                                                        />
                                                        {item?.title}
                                                    </Td>
                                                    <Td borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                        <Badge
                                                            colorScheme={!item.overwritten ? "red" : "green"}
                                                        >
                                                            {item?.overwritten ? "YES" : "NO"}
                                                        </Badge>
                                                    </Td>
                                                    <Td borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                        <Badge colorScheme={!item.stored ? "red" : "green"}>
                                                            {item?.stored ? "YES" : "NO"}
                                                        </Badge>
                                                    </Td>
                                                    <Td borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                        <Flex gap="1" alignItems="center">
                                                            <Button size="sm" rounded={'lg'} bg={'green.100'} onClick={() => handleShowFile(item)}>
                                                                View Original
                                                            </Button>
                                                            <IconButton
                                                                isLoading={btnLoading.download === item.uuid}
                                                                borderRadius="lg"
                                                                size="sm"
                                                                bg='yellow.300'
                                                                icon={<LuDownload />}
                                                                onClick={() => handleDownloadFile(item)}
                                                            />
                                                        </Flex>
                                                    </Td>
                                                    <Td borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                        <Flex>
                                                            {item.results ?
                                                                <Flex flexDirection="column" maxH="200px" minH="fit-content" overflow="auto" className='custom-scrollbar' gap="1">
                                                                    {item.results.map((result, index) => (
                                                                        <Flex gap="1" key={result?.url} justifyContent="center" alignItems="center" w="full">
                                                                            <Text fontWeight="bold" mr="1" w="60px">{result[0]?.language}</Text>
                                                                            <Button size="sm" colorScheme="green" onClick={() => handleShowTranslatedDoc(result[0]?.url, result[0]?.type)}>View</Button>

                                                                            <IconButton
                                                                                isLoading={btnLoading.download === item.uuid}
                                                                                borderRadius="xl"
                                                                                size="sm"
                                                                                colorScheme='yellow'
                                                                                icon={<LuDownload />}
                                                                                onClick={() => handleDownloadFileUsingS3(result[0]?.url, item?.title)}
                                                                            />
                                                                        </Flex>
                                                                    ))}

                                                                </Flex> : <Text>N/A</Text>}
                                                        </Flex>
                                                    </Td>
                                                    <Td borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                        <Button
                                                            onClick={() => {
                                                                onViewOpen();
                                                                setPanelType("Translation");
                                                                setDisplayContent(item);
                                                            }}
                                                            size={"xs"}
                                                            bg={"green.300"}
                                                            color={"gray.700"}
                                                            _hover={{
                                                                color: useTextColor
                                                            }}
                                                        >
                                                            Translation (Batch)
                                                        </Button>
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </Box>
                                <Flex position="absolute" bottom="0" right="0" w="full">
                                    <Pagination
                                        currentPage={pageId}
                                        setCurrentPage={setPageId}
                                        setRecordsPerPage={setRecordsPerPage}
                                        totalPages={totalPages}
                                        recordsPerPage={recordsPerPage}
                                    />
                                </Flex>

                            </Box>
                        ) : (
                            <Box w={"100%"} p={5}>
                                <Alert borderRadius="8px" status="error" variant="subtle">
                                    <Flex>
                                        <AlertIcon />
                                        <Flex direction="column">
                                            <AlertTitle mr="12px">Empty Resources List</AlertTitle>
                                            <AlertDescription>
                                                No Data Found!!
                                            </AlertDescription>
                                        </Flex>
                                    </Flex>
                                </Alert>
                            </Box>
                        )}
                    </Card>
                </Box>
            </Card>
            <Drawer size="md" isOpen={isEmailDrawerOpen} placement="right" onClose={onEmailDrawerClose} >
                <DocAISampleEmail />
            </Drawer>
            <Drawer isOpen={isViewOpen} placement="right" onClose={onViewClose} size="lg">
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader>{panelType === "view" ? "View Content" : "Content Workflow Setup"}</DrawerHeader>
                    <DrawerCloseButton color={"red.100"} />
                    <DrawerBody overflow={"hidden"}>
                        {panelType === "Translation" || panelType === "Transcription" ? (
                            <ContentWorkflow
                                workflowType={panelType}
                                projectUuid={projectUuid}
                                pageContent={displayContent}
                                closePanelHandler={closePanelFunction}
                                token={jwtToken}
                            />
                        ) : null}
                    </DrawerBody>

                    <DrawerFooter>
                        <Button variant="outline" mr={3} onClick={onViewClose}>
                            Cancel
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
            <TranslatedDocumentView isOpen={isOpen} onOpen={onOpen} onClose={onClose} uri={uri} setUri={setUri} contentType={contentType} />
        </>

    )
}

export default ControlSpaceAccessContents