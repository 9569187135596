import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CgAsterisk } from "react-icons/cg";
import {
	Box,
	Button,
	Flex,
	Icon,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Stack,
	Tooltip,
	Badge,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	Switch,
	useToast,
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	useDisclosure,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	useColorModeValue,
	Textarea,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverArrow,
	PopoverCloseButton,
	PopoverBody,
	Checkbox,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Spacer,
} from "@chakra-ui/react";
import {
	BsFiletypePdf,
	BsFiletypeAi,
	BsFiletypeMp3,
	BsFiletypeDocx,
	BsFiletypeTxt,
	BsFiletypeMp4,
} from "react-icons/bs";
import { BiCommentEdit, BiBookReader } from "react-icons/bi";
import React, { useMemo, useState } from "react";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import InputField from "../../../components/fields/InputField.js";
import { SearchBar } from "../../../components/navbar/searchBar/SearchBar.js";
import { addUpdateProjectContent, getContentDetails } from "../../../services/moduleServices.js";
import { useCustomColorModeValues } from "../../../utils/ColorMode.js";

import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import moment from "moment";
import { toastFunctionToaster } from "../../../utils/toastFunction.js";
import { FaAngleLeft, FaAngleRight, FaGalacticSenate } from "react-icons/fa";

//  Components
import DisplayContent from "./DisplayContent.js";
import ContentWorkflow from "./ContentWorkflow.js";
import { useGrayTextColor, useGreenButton, useRedButton, useTextColor } from "../../../theme/globalColorTheme.js";
import { useCallback } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { PROJECTS_CONTENT } from "../constants.js";
import { PAGINATION_OPTIONS } from "../../../components/pagination/constants.js";
import { FaRegFolderClosed } from "react-icons/fa6";
import { TbCaretDown, TbCaretUp, TbFilter, TbFilterFilled } from 'react-icons/tb';

function EditableContentList(props) {
	const toast = useToast();
	const navigate = useNavigate();
	const pathName = window.location.pathname.split("/")[1]
	const btnRef = React.useRef();
	const btnViewRef = React.useRef();
	const [filters, setFilters] = useState({});


	const { columnsData, tableData,setSelectedStorage, selectedStorage, externalStorageExists, pageId, setRecordsPerPage, recordsPerPage, totalPages, setPageId,setFoldersBreadCrumbs} = props;
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isViewOpen, onOpen: onViewOpen, onClose: onViewClose } = useDisclosure();

	const projectUuid = props?.projectUuid ? props?.projectUuid : null;

	const columns = useMemo(() => columnsData, [columnsData]);

	const [businessContentId, setBusinessContentId] = useState(null);
	const [panelType, setPanelType] = useState(null);

	const [spinner, setSpinner] = useState(false);
	const [filterData, setFilterData] = useState(tableData);
	const [businessResourceTitle, setBusinessResourceTitle] = useState(null);
	const [businessResourceContent, setBusinessResourceContent] = useState(null);
	const [resourceLoader, setResourceLoader] = useState(false);
	const [resourceTitleFlag, setResourceTitleFlag] = useState(false);
	const [resourceTextFlag, setResourceTextFlag] = useState(false);

	const [displayContent, setDisplayContent] = useState(null);

	const filteredData = useMemo(() => {
		return filterData.filter((row) => {
			return Object.keys(filters).every((colId) => {
				if (!filters[colId] || filters[colId].length === 0) return true;
				return filters[colId].includes(row[colId]);
			});
		});
	}, [filterData, filters]);

	const data = useMemo(() => filteredData, [filteredData]);
	const redBg = useRedButton()
	const greenBg = useGreenButton()
	const grayColor = useGrayTextColor()
	const textColor = useTextColor()

	const tableInstance = useTable(
		{
			columns,
			data,
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		setGlobalFilter,
	} = tableInstance;

	// const createPages = count => {
	// 	let arrPageCount = [];

	// 	for (let i = 1; i <= count; i++) {
	// 		arrPageCount.push(i);
	// 	}

	// 	return arrPageCount;
	// };


	// Todo:Import custom color
	const { borderColor, brandColor, badgeBgRedColor, badgeBgBlueColor } = useCustomColorModeValues();

	useEffect(() => {
		if (businessContentId?.length) {
			fetchResouceContent();
		}
	}, [businessContentId]);

	const updateDetails = data => {
		setBusinessResourceTitle(data?.title);
		setBusinessContentId(data?.uuid);
	};

	const fetchResouceContent = () => {
		setResourceLoader(true);
		let objData = {
			project_uuid: projectUuid,
			content_uuid: businessContentId,
		};
		getContentDetails(objData)
			.then(res => {
				if (res?.result) {
					setResourceLoader(false);
					setBusinessResourceContent(res?.data[0]?.content);
				}
			})
			.catch(err => {
				setResourceLoader(false);
			});
	};

	const updateResourceDetails = () => {
		let valid = true;
		if (businessResourceTitle.trim() === "") {
			setResourceTitleFlag(true);
			valid = false;
		} else {
			setResourceTitleFlag(false);
		}
		if (businessResourceContent.trim() === "") {
			setResourceTextFlag(true);
			valid = false;
		} else {
			setResourceTextFlag(false);
		}
		if (valid) {
			let objBody = {
				project_uuid: projectUuid,
				text_title: businessResourceTitle,
				text_content: businessResourceContent,
				content_uuid: businessContentId,
			};
			setSpinner(true);
			setResourceTitleFlag(false);
			addUpdateProjectContent(objBody)
				.then(res => {
					setSpinner(false);
					if (res.result) {
						props?.reloadData();
						onClose();
						toast(toastFunctionToaster(res.message, "success"));
						setBusinessResourceTitle("");
						setBusinessResourceContent("");
						setSpinner(false);
					} else {
						props?.reloadData();
						onClose();
						toast(toastFunctionToaster(res.message, "error"));
						setBusinessResourceTitle("");
						setBusinessResourceContent("");
						setSpinner(FaGalacticSenate);
					}
				})
				.catch(err => {
					onClose();
					props?.reloadData();
					setSpinner(false);
					toast(toastFunctionToaster(err.message, "error"));
				});
		}
	};

	const getContentType = contentType => {
		console.log(contentType)
		if (contentType != null) {
			if (contentType.includes("pdf")) {
				return BsFiletypePdf;
			} else if (contentType.includes("doc")) {
				return BsFiletypeDocx;
			} else if (contentType.includes("txt") || contentType.includes("text")) {
				return BsFiletypeTxt;
			} else if (contentType.includes("mp3") || contentType.includes("audio")) {
				return BsFiletypeMp3;
			} else if (contentType.includes("mp4")) {
				return BsFiletypeMp4;
			}else if(contentType.includes("folder")){
				return FaRegFolderClosed
			}
		} else {
			return BsFiletypeAi;
		}
	};

	const getWorkflowType = contentType => {
		if (contentType != null) {
			if (contentType.includes("text")) {
				return "txl";
			} else if (contentType.includes("pdf") || contentType.includes("doc") || contentType.includes("txt")) {
				return "tsl";
			} else if (contentType.includes("mp3") || contentType.includes("audio") || contentType.includes("mp4")) {
				return "tcp";
			}
		} else {
			return null;
		}
	};

	const closePanelFunction = () => {
		onViewClose();
		props?.reloadData();
	};

	function handleClearAllFilters(colId) {
		setFilters(filters => ({ ...filters, [colId]: [] }))
	}
	const handleFilterChange = useCallback((colId, value) => {
		setFilters((prevFilters) => {
			const newFilters = { ...prevFilters };
			if (!newFilters[colId]) {
				newFilters[colId] = [];
			}
			if (newFilters[colId].includes(value)) {
				newFilters[colId] = newFilters[colId].filter((val) => val !== value);
			} else {
				newFilters[colId].push(value);
			}
			return newFilters;
		});
	}, []);

	const isColumnFiltered = (colId, columnValues) => {
		const selectedValues = filters[colId] || [];
		return selectedValues.length !== 0 && selectedValues.length !== columnValues.length;
	};
	const handleUpdateParentFolder = (cell) =>{
		const folderData = {
			name:cell?.row?.original?.title,
			id:cell?.row?.original?.uuid
		}
		setFoldersBreadCrumbs(prevData=>([...prevData, folderData]))
	}

	console.log(displayContent)
	
	return (
		<>
			<Flex
				direction="column"
				w="100%"
				overflowX={{ sm: "scroll", lg: "hidden" }}
				h={tableData?.length > 4 ? "600px" : "auto"}>
				<Flex
					align={{ sm: "flex-start", lg: "flex-start" }}
					justify={{ sm: "flex-start", lg: "flex-start" }}
					w="100%"
					gap="2"
					px="22px"
					mb="36px">
					<SearchBar
						onChange={e => setGlobalFilter(e.target.value)}
						h="44px"
						w={{ lg: "390px" }}
						borderRadius="16px"
					/>
					{
						externalStorageExists && <Menu placement="bottom">
							<MenuButton as={Button} rightIcon={<ChevronDownIcon />} colorScheme="telegram">
								{selectedStorage?.name || "Select Storage"}
							</MenuButton>
							<MenuList zIndex={1000}>
								{PROJECTS_CONTENT.CONTENT_SOURCE.map(source => (
									<MenuItem onClick={() => setSelectedStorage(source)}>{source.name}</MenuItem>
								))}
							</MenuList>
						</Menu>
					}
					<Spacer />
					<Flex>
						<Flex mr={3}>
							<Menu>
								<MenuButton as={Button} rightIcon={<ChevronDownIcon />} px={2} py={0} colorScheme='blue' h="30px" pl={3}>
									{recordsPerPage}
								</MenuButton>
								<MenuList zIndex={1000}>
									{PAGINATION_OPTIONS.map(item => (<MenuItem value={String(item)} color={useTextColor} onClick={()=>setRecordsPerPage(item)}>{item}</MenuItem>))}
								</MenuList>
							</Menu>
						</Flex>
						<Flex alignItems="center" gap="2">
							<Icon as={FaAngleLeft} cursor={pageId == 1 ?  "not-allowed" :"pointer" } onClick={() => pageId != 1 &&  setPageId(pageId =>pageId - 1)} />
							{pageId}
							<Icon as={FaAngleRight} cursor={pageId < totalPages ? "pointer" : "not-allowed"} onClick={() => pageId < totalPages && setPageId(pageId => pageId+1)} />
						</Flex>
					</Flex>
				</Flex>
				<Flex direction="column" overflowY="hidden" overflowX="auto" w="full" className="custom-scrollbar">
					<Table {...getTableProps()} variant="simple" color="gray.500" mb="24px" style={{ tableLayout: "auto" }}>
						<Thead position="sticky" top="0" zIndex={1} bg="gray.200">
							{headerGroups.map((headerGroup, index) => (
								<Tr {...headerGroup.getHeaderGroupProps()} key={index}>
									{headerGroup.headers.map((column, idx) => {
										const columnValues = [...new Set(data.map((row) => {
											return row[column.id]
										}))];
										return (
											<Th
												{...column.getHeaderProps()}
												pe="10px"
												key={idx}
												borderColor={borderColor}
											>
												<Flex gap={2} alignItems="center" justifyContent="space-between">
													<Box {...column.getSortByToggleProps()}  cursor="pointer" h="full">{column.render('Header')}</Box>
													   <Box>
															{column.isSorted ? (
																column.isSortedDesc ? (
																<Icon as={TbCaretDown} boxSize={5} color="black"  />
																) : (
																<Icon as={TbCaretUp} boxSize={5} color="black" />
																)
															) : (
																''
															)}
															</Box>
													{column.Header === "ADDED BY" && <Flex alignItems="center">
														<Popover>
															<PopoverTrigger>
																<Button
																	size="xs"
																	variant="ghost"
																	p={0}
																	_hover={{ bg: 'transparent' }}
																	_focus={{ boxShadow: 'none' }}
																>
																	{
																		isColumnFiltered(column.id, columnValues) ?
																			<Icon
																				as={TbFilterFilled}
																				boxSize={4}
																				color={'gray.400'}
																			/> :
																			<Icon
																				as={TbFilter}
																				boxSize={4}
																				color={'gray.400'}
																			/>
																	}
																</Button>
															</PopoverTrigger>
															<PopoverContent overflow="hidden">
																<PopoverArrow />
																<PopoverCloseButton />
																<Box p="2">
																	<Button w="40%" onClick={() => handleClearAllFilters(column.id)}>Clear All</Button>
																</Box>
																<PopoverBody height="200px" overflow="auto" className='custom-scrollbar'>
																	<Flex direction="column" gap="2">
																		{columnValues.every(value => value == null) ? (
																			<Text>No filters available</Text>
																		) : (
																			columnValues.map((value, valueIdx) => (
																				value && <Checkbox
																					key={valueIdx}
																					isChecked={filters[column.id]?.includes(value)}
																					onChange={() => handleFilterChange(column.id, value)}
																					textTransform="none"
																				>
																					{value}
																				</Checkbox>
																			))
																		)}
																	</Flex>
																</PopoverBody>
															</PopoverContent>
														</Popover>
													</Flex>}
												</Flex>
											</Th>
										);
									})}
								</Tr>
							))}
						</Thead>
						<Tbody {...getTableBodyProps()}>
							{page.map((row, index) => {
								prepareRow(row);
								return (
									<Tr {...row.getRowProps()} key={index}>
										{row.cells.map((cell, subIndex) => {
											let data = "";
											if (cell.column.Header === "FILE") {
												const content_type = row?.original?.type
												const isFolder = content_type?.includes("folder")
												data = (
													<Flex align="center">
														{tableData[index]?.text ? (
															<Icon
																color={"blue.500"}
																as={getContentType("text")}
																h={6}
																w={6}
																mr={2}
															/>
														) : (
															<Icon
																color={"blue.500"}
																as={getContentType(tableData[index]?.type)}
																h={6}
																w={6}
																mr={2}
															/>
														)}
														<Text color={textColor} fontSize="md" fontWeight="500" _hover={isFolder ? {textDecoration:"underline"} : {textDecoration:"none"}} cursor={isFolder ? "pointer" : "auto"} onClick={()=>handleUpdateParentFolder(cell)}>
															{cell.value}
														</Text>
													</Flex>
												);
											} else if (cell.column.Header === "TYPE") {
												data = (
													<Text color={textColor} fontSize="md" fontWeight="500">
														{cell.value}
														{/* <br />
								{tableData[index]?.dummy ? " (Not Logged yet)" : null} */}
													</Text>
												);
											} else if (cell.column.Header === "URL") {
												data = (
													<Text color={textColor} fontSize="md" fontWeight="500">
														{cell.value}
														{/* <br />
								{tableData[index]?.dummy ? " (Not Logged yet)" : null} */}
													</Text>
												);
											} else if (cell.column.Header === "TEXT") {
												data = (
													<Text color={textColor} fontSize="md" fontWeight="500">
														{cell.value ? (
															<Badge bg={greenBg}>Yes</Badge>
														) : (
															<Badge bg={redBg}>NOoooo</Badge>
														)}
													</Text>
												);
											} else if (cell.column.Header === "VECTORIZE") {
												data = (
													<Text color={textColor} fontSize="md" fontWeight="500">
														{cell.value ? (
															<Badge bg={greenBg}>Yes</Badge>
														) : (
															<Badge bg={redBg}>NO</Badge>
														)}
													</Text>
												);
											} else if (cell.column.Header === "Date") {
												data = (
													<Text color={textColor} fontSize="md" fontWeight="500">
														{cell.value ? (
															<Text>{moment.utc(cell?.value).local().format("MMM DD,YYYY,HH:mm a")}</Text>
														) : (
															<Text>X</Text>
														)}
													</Text>
												);

											}
											else if (cell.column.Header === "EVENT") {
												const content_type = row?.original?.type
												data = content_type?.includes("folder") ? <></> :
												(
													<Text color={textColor} fontSize="md" fontWeight="500">
														{cell.value}
													</Text>
												);
											} else if (cell.column.Header === "OVERWRITTEN") {
												const content_type = row?.original?.type
												data = content_type?.includes("folder") ? <></> :
												(
													<Text color={textColor} fontSize="md" fontWeight="500">
														{cell.value ? (
															<Badge bg={greenBg}>Yes</Badge>
														) : (
															<Badge bg={redBg}>NO</Badge>
														)}
													</Text>
												);
											} else if (cell.column.Header === "STORED") {
												const content_type = row?.original?.type
												data = content_type?.includes("folder") ? <></> : (
													<Text color={textColor} fontSize="md" fontWeight="500">
														{cell.value ? (
															<Badge bg={greenBg}>Yes</Badge>
														) : (
															<Badge bg={redBg}>NO</Badge>
														)}
													</Text>
												);
											} else if (cell.column.Header === "DISABLED STATUS") {
												const content_type = row?.original?.type
												data = content_type?.includes("folder") ? <></> : (
													<Text color={textColor} fontSize="md" fontWeight="500">
														<Switch
															//   onChange={() => {
															//     changeCurrentUserStatus(
															//       tableData[index]?.user_uuid,
															//       cell.value
															//     );
															//   }}
															isChecked={cell.value ? true : false}
															colorScheme="green"
														/>
														{/* {cell.value ? (
									<Badge p={1} color={'white'} bg={'red.500'}>
									{' '}
									YES
									</Badge>
								) : (
									<Badge p={1} color={'white'} bg={'green.500'}>
									{' '}
									NO
									</Badge>
								)} */}
													</Text>
												);
											} else if (cell.column.Header === "ACTION") {
												const content_type = row?.original?.type
												data = content_type?.includes("folder") ? <></> : (
													<Flex w={"100%"}>
														<Tooltip label="View Content" aria-label="tooltip">
															<Button
																mr={1}
																size={"xs"}
																bg={grayColor}
																ref={btnViewRef}
																onClick={() => {
																	onViewOpen();
																	setPanelType("view");
																	setDisplayContent(tableData[index]);
																}}>
																<Icon
																	as={BiBookReader}
																	color={"gray.100"}
																	h={5}
																	w={5}
																	p={0.5}
																/>
															</Button>
														</Tooltip>
														{/* <Button size={"xs"} bg={"blue.100"}>
								{" "}
								Vectorize Data
							</Button> */}
														{/* <Button size={"xs"} bg={"blue.100"} mt={1}>
								{" "}
								Collect Text
							</Button> */}
														{/* {tableData[index]?.text === true
							?<Button 
								onClick={ () => {
								onViewOpen();
								setPanelType('Translation');
								setDisplayContent(tableData[index]);
								}}
								size={'xs'} bg={'green.300'} color={'gray.100'}>Translation</Button>:null} */}
														{getWorkflowType(tableData[index]?.type) === "txl" ? (
															<Button
																onClick={() => {
																	onViewOpen();
																	setPanelType("Translation");
																	setDisplayContent(tableData[index]);
																}}
																size={"xs"}
																bg={"orange.300"}
																color={"gray.700"}
																_hover={{
																	color: textColor
																}}
															>
																Translation (Realtime)
															</Button>
														) : null}
														{getWorkflowType(tableData[index]?.type) === "tsl" ? (
															<Button
																onClick={() => {
																	onViewOpen();
																	setPanelType("Translation");
																	setDisplayContent(tableData[index]);
																}}
																size={"xs"}
																bg={"green.300"}
																color={"gray.700"}
																_hover={{
																	color: textColor
																}}
															>
																Translation (Batch)
															</Button>
														) : null}
														{getWorkflowType(tableData[index]?.type) === "tcp" ? (
															<Button
																onClick={() => {
																	onViewOpen();
																	setPanelType("Transcription");
																	setDisplayContent(row?.original);
																}}
																size={"xs"}
																bg={"blue.300"}
																color={"gray.700"}
																_hover={{
																	color: textColor
																}}
															>
																Transcription (Batch)
															</Button>
														) : null}

														{tableData[index]?.text === true ? (
															<Tooltip label="Edit Text Content" aria-label="tooltip">
																<Button
																	ml={1}
																	size={"xs"}
																	bg={"blue.300"}
																	color={"gray.700"}
																	_hover={{
																		color: textColor
																	}}
																	ref={btnRef}
																	onClick={() => {
																		updateDetails(tableData[index]);
																		onOpen();
																	}}>
																	<Icon as={BiCommentEdit} h={5} w={5} p={0.5} />
																</Button>
															</Tooltip>
														) : null}
													</Flex>
												);
											} else if (cell.column.Header === "WORKFLOW") {
												const content_type = row?.original?.type
												data = content_type?.includes("folder") ? <></> : (
													<Flex w={"100%"}>
														{cell.value > 0 ? (
															<Button
																onClick={() => {
																	navigate(`/${pathName}/project/content/workflow`, {
																		state: {
																			projectUuid: projectUuid,
																			contentUuid: row?.original?.uuid,
																			contentTitle: row?.original?.title,
																		},
																	});
																}}
																size={"xs"}
																color={textColor}
																bg={"blue.300"}
																fontSize="sm">
																Workflows ({cell.value})
															</Button>
														) : (
															<Text color={"red.200"} fontSize="md" fontWeight="500">
																{"No Workflow"}
															</Text>
														)}
													</Flex>
												);
											} else if (cell.column.Header === "JOIN DATE") {
												const content_type = row?.original?.type
												data = content_type?.includes("folder") ? <></> : (
													<Text color={textColor} fontSize="md" fontWeight="500">
														{moment(cell.value).format("MMM DD,YYYY,HH:mm a")}
													</Text>
												);
											} else if (cell.column.Header === "USER TYPE") {
												const content_type = row?.original?.type
												data = content_type?.includes("folder") ? <></> : (
													<Text color={textColor} fontSize="md" fontWeight="500">
														{cell.value === "Admin" ? (
															<Badge
																p={2}
																rounded={"xs"}
																color={textColor}
																bg={badgeBgRedColor}>
																{" "}
																{cell.value}
															</Badge>
														) : (
															<Badge
																p={1}
																rounded={"xs"}
																color={textColor}
																bg={badgeBgBlueColor}>
																{" "}
																{cell.value}
															</Badge>
														)}
													</Text>
												);
											} else if (cell.column.Header === "ACTIONS") {
												const content_type = row?.original?.type
												data = content_type?.includes("folder") ? <></> : (
													<Text
														cursor="pointer"
														color={brandColor}
														textDecoration="underline"
														fontSize="md"
														fontWeight="500"
														id={cell.value}>
														Edit user
													</Text>
												);
											} else if (cell.column.Header === "ADDED BY") {
												data = (
													<Text color={textColor} fontSize="md" fontWeight="500">
														{cell.value || "N/A"}
													</Text>
												)
											}
											return (
												<Td
													{...cell.getCellProps()}
													key={index}
													fontSize={{ sm: "14px" }}
													minW={{ sm: "150px", md: "200px", lg: "auto" }}
													borderColor={borderColor}>
													{data}
												</Td>
											);
										})}
									</Tr>
								);
							})}
						</Tbody>
					</Table>
				</Flex>
				{/* <Flex
					direction={{ sm: "column", md: "row" }}
					justify="space-between"
					align="center"
					w="100%"
					px={{ md: "22px" }}>
					<Text fontSize="sm" color="gray.500" fontWeight="normal" mb={{ sm: "24px", md: "0px" }}>
						Showing {pageSize * pageIndex + 1} to{" "}
						{pageSize * (pageIndex + 1) <= tableData.length ? pageSize * (pageIndex + 1) : tableData.length}{" "}
						of {tableData.length} entries
					</Text>
					<Stack direction="row" alignSelf="flex-end" spacing="4px" ms="auto">
						<Button
							variant="no-effects"
							onClick={() => previousPage()}
							transition="all .5s ease"
							w="40px"
							h="40px"
							borderRadius="50%"
							bg="transparent"
							border="1px solid"
							borderColor={useColorModeValue("gray.200", "white")}
							display={pageSize === 5 ? "none" : canPreviousPage ? "flex" : "none"}
							_hover={{
								bg: "whiteAlpha.100",
								opacity: "0.7",
							}}>
							<Icon as={MdChevronLeft} w="16px" h="16px" color={textColor} />
						</Button>
						{pageSize === 5 ? (
							<NumberInput
								max={pageCount - 1}
								min={1}
								w="75px"
								mx="6px"
								defaultValue="1"
								onChange={e => gotoPage(e)}>
								<NumberInputField />
								<NumberInputStepper>
									<NumberIncrementStepper onClick={() => nextPage()} />
									<NumberDecrementStepper onClick={() => previousPage()} />
								</NumberInputStepper>
							</NumberInput>
						) : (
							createPages(pageCount).map((pageNumber, index) => {
								return (
									<Button
										variant="no-effects"
										transition="all .5s ease"
										onClick={() => gotoPage(pageNumber - 1)}
										w="40px"
										h="40px"
										borderRadius="50%"
										bg={pageNumber === pageIndex + 1 ? brandColor : "transparent"}
										border={pageNumber === pageIndex + 1 ? "none" : "1px solid lightgray"}
										_hover={
											pageNumber === pageIndex + 1
												? {
													opacity: "0.7",
												}
												: {
													bg: "whiteAlpha.100",
												}
										}
										key={index}>
										<Text fontSize="sm" color={pageNumber === pageIndex + 1 ? "#fff" : textColor}>
											{pageNumber}
										</Text>
									</Button>
								);
							})
						)}
						<Button
							variant="no-effects"
							onClick={() => nextPage()}
							transition="all .5s ease"
							w="40px"
							h="40px"
							borderRadius="50%"
							bg="transparent"
							border="1px solid"
							borderColor={useColorModeValue("gray.200", "white")}
							display={pageSize === 5 ? "none" : canNextPage ? "flex" : "none"}
							_hover={{
								bg: "whiteAlpha.100",
								opacity: "0.7",
							}}>
							<Icon as={MdChevronRight} w="16px" h="16px" color={textColor} />
						</Button>
					</Stack>
				</Flex> */}
			</Flex>
			{/* Drawer To Edit Resource */}
			<Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
				<DrawerOverlay />
				<DrawerContent>
					<DrawerHeader>Edit Content Details</DrawerHeader>
					<DrawerCloseButton color={textColor} />
					<DrawerBody overflow={"hidden"}>
						<Box>
							<InputField
								mb="0"
								me="30px"
								id="resource_title"
								label="Enter Content Title"
								value={businessResourceTitle}
								name="resource_title"
								onChange={e => {
									setBusinessResourceTitle(e.target.value);
								}}
								placeholder="Enter Content"
								w="100%"
								borderRadius="5px"
								required="true"
								maxLength={100}
							/>
							<Text textAlign={"right"} fontWeight={"500"} fontSize={"sm"} mt="2">
								{businessResourceTitle?.length}/100
							</Text>
							{resourceTitleFlag ? (
								<Text color="red" fontWeight={"500"} mt="2">
									Content Title is required{" "}
								</Text>
							) : null}
							<Box my="5">
								<Flex mb="2">
									<Text fontSize={"14px"} fontWeight={"bold"}>
										Enter Content
									</Text>
									<CgAsterisk color={"#f1766b"} fontSize={"12px"} />
								</Flex>
								<Textarea
									rows="15"
									placeholder="Enter Content"
									value={businessResourceContent}
									onChange={e => {
										setBusinessResourceContent(e.target.value);
									}}
									isDisable={resourceLoader}
									maxLength={5000}></Textarea>
								<Text textAlign={"right"} fontWeight={"500"} fontSize={"sm"} mt="2">
									{businessResourceContent?.length}/5000
								</Text>
								{resourceTextFlag ? (
									<Text color="red" fontWeight={"500"} mt="2">
										Content is required{" "}
									</Text>
								) : null}
							</Box>
						</Box>
					</DrawerBody>

					<DrawerFooter>
						<Button variant="outline" mr={3} onClick={onClose}>
							Cancel
						</Button>
						<Button
							colorScheme="blue"
							onClick={() => {
								updateResourceDetails();
							}}
							isDisabled={spinner}
							isLoading={spinner}>
							Update Content Details
						</Button>
					</DrawerFooter>
				</DrawerContent>
			</Drawer>
			<Drawer isOpen={isViewOpen} placement="right" onClose={onViewClose} size="lg">
				<DrawerOverlay />
				<DrawerContent>
					<DrawerHeader>{panelType === "view" ? "View Content" : "Content Workflow Setup"}</DrawerHeader>
					<DrawerCloseButton color={"red.100"} />
					<DrawerBody overflow={"hidden"}>
						{panelType === "view" ? (
							<DisplayContent projectUuid={projectUuid} pageContent={displayContent} />
						) : null}
						{panelType === "Translation" || panelType === "Transcription" ? (
							<ContentWorkflow
								workflowType={panelType}
								projectUuid={projectUuid}
								pageContent={displayContent}
								closePanelHandler={closePanelFunction}
							/>
						) : null}
					</DrawerBody>

					<DrawerFooter>
						<Button variant="outline" mr={3} onClick={onViewClose}>
							Cancel
						</Button>
					</DrawerFooter>
				</DrawerContent>
			</Drawer>
		</>
	);
}

export default EditableContentList;
