import React from "react";
import { TbFileReport } from "react-icons/tb";
import { Icon } from "@chakra-ui/react";
import { CiBookmark } from "react-icons/ci";
import { FaBusinessTime, FaWpforms } from "react-icons/fa";
import { MdDashboard, MdOutlineSettings, MdOutlineBusiness } from "react-icons/md";
import { AiOutlineSetting, AiOutlineDashboard, AiOutlineMobile, AiOutlineSelect, AiOutlineLink, AiOutlineGift, AiOutlineSecurityScan, AiOutlineUserAdd, AiOutlineOrderedList, AiOutlineAccountBook, AiOutlineUser, AiOutlineTranslation, AiOutlineSchedule } from "react-icons/ai";
import { MdOutlineHub, MdDocumentScanner, MdDevices, MdArticle, MdOutlineContentPasteSearch } from "react-icons/md";

// Business Component Imports
import AccountDashboard from "../komponents/accountmanagement/dashboard/AccountDashboard";
import AccountTeam from "../komponents/accountmanagement/management/AccountTeam";
import BusinessProfile from "../komponents/business/profile/BusinessProfile";
import LocationsListing from "../komponents/accountmanagement/locations/LocationsListing";
import LocationsSubscribers from "../komponents/accountmanagement/locations/LocationSubscribers";

// LingoLinks
import LinkListing from "../komponents/business/workspace/Links/LinkListing";
import LinkManager from "../komponents/business/workspace/Links/LinkManager";
import LinkSession from "../komponents/business/workspace/Links/LinkSession";
import LinkCalls from "../komponents/business/workspace/Links/LinkCalls";
import LinkConfiguration from "../komponents/business/workspace/Links/LinkConfiguration";

// Language Associate
import LanguageAssociateList from "../komponents/business/LangAssociates/LanguageAssociateList";

// Common
import Helper from "../komponents/common/helper/Helper";
import PlatformTrust from "../komponents/common/helper/PlatformTrust";
import ApplicationSettings from "../komponents/common/ApplicationSettings";
import AccountDesign from "../komponents/accountmanagement/management/AccountDesign";
import SubDomainSetup from "../komponents/accountmanagement/management/SubDomainSetup";
import CardListing from "../komponents/accountmanagement/LingoCard/CardListing";
import AdminSchedule from "../komponents/business/workspace/schedule/AdminSchedule";
import LanguagePrefrence from "../komponents/accountmanagement/management/LanguagePreference";
import { ACCOUNT_ACCESS_CLASS } from "../komponents/business/accounts/accountmanagement/constants";

import DeviceSupport from "../komponents/accountmanagement/DeviceSupport/DeviceSupport";
import DevicesConnectLogs from "../komponents/accountmanagement/DeviceSupport/DeviceConnectLog";

import TranslationSupport from "../komponents/accountmanagement/translationSupport/TranslationSupport";
import ProjectListing from "../komponents/documentai/ProjectListing";
import ProjectContent from "../komponents/documentai/ProjectContent";
import ContentWorkflowMgmt from "../komponents/documentai/ContentWorkflowMgmt";
import ContentWorkflowResults from "../komponents/documentai/ContentWorkflowResult";

// Scheduler
import JobHome from "../komponents/accountmanagement/scheduler/jobs/JobHome";
import Customer from "../komponents/accountmanagement/scheduler/identity/Customer";
import ReportHome from "../komponents/accountmanagement/scheduler/report/ReportHome";
import BookingHome from "../komponents/accountmanagement/scheduler/bookings/BookingHome";
import BusinessHome from "../komponents/accountmanagement/scheduler/business/BusinessHome";
import SettingsHome from "../komponents/accountmanagement/scheduler/settings/SettingsHome";
import DashboardHome from "../komponents/accountmanagement/scheduler/dashboard/DashboardHome";
import IdentitiesHome from "../komponents/accountmanagement/scheduler/identities/IdentitiesHome";

// Accounts - Reporting Components
//import { RiCalendarScheduleLine } from "react-icons/ri";
import { RiCalendarScheduleLine, RiUserLine } from "@remixicon/react";
import AccountReporting from "../komponents/accountmanagement/reporting/AccountReporting";
import LocationReporting from "../komponents/accountmanagement/reporting/LocationReporting";
import AccountFullBilling from "../komponents/accountmanagement/reporting/accountBilling/AccountFullBilling";
import ShareAccessControl from "../komponents/documentai/shareControl/ShareAccessControl";
import ContentList from "../komponents/documentai/shareControl/ContentList";
import { SiManageiq } from "react-icons/si";
import Management from "../komponents/documentai/Management";
import ExternalStorage from "../komponents/documentai/ExternalStorage";
import { TiCloudStorageOutline } from "react-icons/ti";
import DynamicFormBuilder from "../komponents/business/dynamicforms/DynamicFormBuilder";
import LaunchDynamicForm from "../komponents/business/dynamicforms/LaunchDynamicForm";
import DynamicFormContentList from "../komponents/business/dynamicforms/DynamicFormContentList";
import ProofreadingHome from "../komponents/accountmanagement/Proofreading/ProofreadingHome";
import DocumentAiHome from "../komponents/documentai/DocumentAiHome";

const accountRoutes = (isLspAccountType = false, accountType = ACCOUNT_ACCESS_CLASS.BASIC, translationsupport = false, devicesupport = false, schedulerSupport = false, accountUuid, isLingoCardEnabled, isLingoFormEnabled) => {
	return [
		{
			name: "Dashboard",
			path: "/account",
			icon: <Icon as={AiOutlineDashboard} width="25px" height="25px" />,
			collapse: true,
			items: [
				{
					name: "Dashboard",
					layout: "/account",
					path: "/dashboard",
					component: <AccountDashboard accountType={accountType} />,
					secondary: true,
					showToMenu: true,
					icon: <Icon as={AiOutlineDashboard} width="20px" height="20px" />,
				},
			],
		},
		{
			name: "Location+Subscriber",
			path: "/account",
			icon: <Icon as={AiOutlineAccountBook} width="25px" height="25px" />,
			collapse: true,
			isHidden: accountType === ACCOUNT_ACCESS_CLASS.BASIC,
			items: [
				{
					name: "Manage",
					layout: "/account",
					path: "/locations",
					component: <LocationsListing />,
					secondary: true,
					showToMenu: true,
					icon: <Icon as={AiOutlineUserAdd} width="20px" height="20px" />,
				},
				{
					name: "Manage",
					layout: "/account",
					path: "/locations/subscribers",
					component: <LocationsSubscribers />,
					secondary: true,
					showToMenu: false,
					icon: <Icon as={AiOutlineUserAdd} width="20px" height="20px" />,
				},
			],
		},
		{
			name: "Lingo Hub",
			path: "/account",
			icon: <Icon as={MdOutlineHub} width="25px" height="25px" />,
			collapse: true,
			items: [
				{
					name: "LingoLink",
					layout: "/account",
					path: "/linklisting",
					component: <LinkListing />,
					secondary: true,
					showToMenu: true,
					icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
				},
				{
					name: "LingoLink",
					layout: "/account",
					path: "/linkmanager",
					component: <LinkManager />,
					secondary: true,
					showToMenu: false,
					icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
				},
				{
					name: "Link Configuration",
					layout: "/account",
					path: "/linkmananger/configuration",
					component: <LinkConfiguration />,
					secondary: true,
					showToMenu: false,
					icon: <Icon as={AiOutlineLink} width="20px" height="20px" />,
				},
				{
					name: "LingoLinks",
					layout: "/account",
					path: "/linkmananger/sessions",
					component: <LinkSession />,
					secondary: true,
					showToMenu: false,
					icon: <Icon as={AiOutlineLink} width="20px" height="20px" />,
				},
				{
					name: "LingoLinks",
					layout: "/account",
					path: "/linkmananger/calls",
					component: <LinkCalls />,
					secondary: true,
					showToMenu: false,
					icon: <Icon as={AiOutlineSecurityScan} width="20px" height="20px" />,
				},
				{
					name: "LingoCard",
					layout: "/account",
					path: "/cardlisting",
					component: <CardListing accountUuid={accountUuid} isLingoCardEnabled={isLingoCardEnabled} />,
					secondary: true,
					showToMenu: isLingoCardEnabled,
					icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
				},
				{
					name: "Language Preference",
					layout: "/account",
					path: "/languagepreference",
					component: <LanguagePrefrence />,
					secondary: true,
					showToMenu: true,
					icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
				},
				{
					name: "LingoForms",
					layout: "/account",
					path: "/lingoforms",
					component: <DynamicFormBuilder />,
					secondary: true,
					showToMenu: isLingoFormEnabled,
					icon: <Icon as={FaWpforms} width="20px" height="20px" />,
				},
				{
					name: "LingoForm Launch",
					layout: "/account",
					path: "/lingoforms/launch",
					component: <LaunchDynamicForm />,
					secondary: true,
					showToMenu: false,
					icon: <Icon as={FaWpforms} width="20px" height="20px" />,
				},
				{
					name: "LingoForm Contents",
					layout: "/account",
					path: "/lingoforms/contentList",
					component: <DynamicFormContentList />,
					secondary: true,
					showToMenu: false,
					icon: <Icon as={FaWpforms} width="20px" height="20px" />,
				},
			],
		},
		{
			name: "Language Associates",
			path: "/account",
			icon: <Icon as={AiOutlineUserAdd} width="25px" height="25px" />,
			collapse: true,
			isHidden: !isLspAccountType,
			items: [
				{
					name: "Associate Listing",
					layout: "/account",
					path: "/associateListing",
					component: <LanguageAssociateList />,
					secondary: true,
					showToMenu: true,
					icon: <Icon as={AiOutlineOrderedList} width="20px" height="20px" />,
				},
			],
		},
		{
			name: "Scheduler",
			path: "/account",
			icon: <Icon as={AiOutlineSchedule} width="25px" height="25px" />,
			collapse: true,
			isHidden: !schedulerSupport,
			items: [
				{
					name: "Dashboard",
					layout: "/account",
					path: "/scheduler/dashboard",
					component: <DashboardHome />,
					secondary: true,
					showToMenu: true,
					icon: <Icon as={MdDashboard} width="20px" height="20px" />,
				},
				{
					name: "Jobs",
					layout: "/account",
					path: "/scheduler/jobs",
					component: <JobHome />,
					secondary: true,
					showToMenu: true,
					icon: <Icon as={RiUserLine} width="20px" height="20px" />,
				},
				{
					name: "Bookings",
					layout: "/account",
					path: "/scheduler/bookings",
					component: <BookingHome />,
					secondary: true,
					showToMenu: true,
					icon: <Icon as={CiBookmark} width="20px" height="20px" />,
				},
				{
					name: "Customers",
					layout: "/account",
					path: "/scheduler/customers",
					component: <Customer />,
					secondary: true,
					showToMenu: true,
					icon: <Icon as={RiUserLine} width="20px" height="20px" />,
				},
				{
					name: "Identities",
					layout: "/account",
					path: "/scheduler/identities",
					component: <IdentitiesHome />,
					secondary: true,
					showToMenu: true,
					icon: <Icon as={MdOutlineBusiness} width="20px" height="20px" />,
				},
				{
					name: "Business",
					layout: "/account",
					path: "/scheduler/business",
					component: <BusinessHome />,
					secondary: true,
					showToMenu: true,
					icon: <Icon as={FaBusinessTime} width="20px" height="20px" />,
				},
				{
					name: "Reports",
					layout: "/account",
					path: "/scheduler/reports",
					component: <ReportHome />,
					secondary: true,
					showToMenu: true,
					icon: <Icon as={TbFileReport} width="20px" height="20px" />,
				},
				{
					name: "Settings",
					layout: "/account",
					path: "/scheduler/settings",
					component: <SettingsHome />,
					secondary: true,
					showToMenu: true,
					icon: <Icon as={MdOutlineSettings} width="20px" height="20px" />,
				},
				{
					name: "Schedule",
					layout: "/account",
					path: "/scheduler/schedule",
					component: <AdminSchedule />,
					secondary: true,
					showToMenu: true,
					icon: <Icon as={RiCalendarScheduleLine} width="20px" height="20px" />,
				},
			],
		},

		{
			name: "Device Manager",
			path: "/account",
			icon: <Icon as={MdDevices} width="25px" height="25px" />,
			collapse: true,
			isHidden: !devicesupport,
			items: [
				{
					name: "Assigned Device",
					layout: "/account",
					path: "/assigned_devices",
					component: <DeviceSupport />,
					secondary: true,
					showToMenu: true,
					icon: <Icon as={AiOutlineMobile} width="20px" height="20px" />,
				},
				{
					name: "Device Logs",
					layout: "/account",
					path: "/assigned_device/logs",
					component: <DevicesConnectLogs />,
					secondary: true,
					showToMenu: false,
					icon: <Icon as={AiOutlineMobile} width="20px" height="20px" />,
				},
			],
		},
		{
			name: "Document AI",
			path: "/account",
			icon: <Icon as={MdDocumentScanner} width="25px" height="25px" />,
			collapse: true,
			isHidden: !translationsupport,
			items: [
				{
					name: "Translations",
					layout: "/account",
					path: "/projects",
					component: <DocumentAiHome />,
					secondary: true,
					showToMenu: true,
					icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
				},
				// {
				// 	name: "External Storage",
				// 	layout: "/account",
				// 	path: "/documentai/storage",
				// 	component: <ExternalStorage />,
				// 	secondary: true,
				// 	showToMenu: true,
				// 	icon: <Icon as={TiCloudStorageOutline} width="20px" height="20px" />,
				// },
				{
					name: "Management",
					layout: "/account",
					path: "/documentai/management",
					component: <Management />,
					secondary: true,
					showToMenu: true,
					icon: <Icon as={SiManageiq} width="20px" height="20px" />,
				},
				{
					name: "Share Control",
					layout: "/account",
					path: "/project/share-control",
					component: <ShareAccessControl />,
					secondary: true,
					showToMenu: false,
					icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
				},
				{
					name: "Contents",
					layout: "/account",
					path: "/project/content/list",
					component: <ContentList />,
					secondary: true,
					showToMenu: false,
					icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
				},
				{
					name: "Content",
					layout: "/account",
					path: "/project/content",
					component: <ProjectContent />,
					secondary: true,
					showToMenu: false,
					icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
				},
				{
					name: "Workflow",
					layout: "/account",
					path: "/project/content/workflow",
					component: <ContentWorkflowMgmt />,
					secondary: true,
					showToMenu: false,
					icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
				},
				// {
				// 	name: "Proofreading",
				// 	layout: "/account",
				// 	path: "	/proofreading",
				// 	component: <ProofreadingHome />,
				// 	secondary: true,
				// 	showToMenu: true,
				// 	icon: <Icon as={MdOutlineContentPasteSearch} width="20px" height="20px" />,
				// },
				{
					name: "Workflow",
					layout: "/account",
					path: "/project/content/workflow_result",
					component: <ContentWorkflowResults />,
					secondary: true,
					showToMenu: false,
					icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
				},
			],
		},
		{
			name: "Reporting",
			path: "/account",
			icon: <Icon as={MdArticle} width="25px" height="25px" />,
			collapse: true,
			items: [
				{
					name: "Account Report",
					layout: "/account",
					path: "/reporting",
					component: <AccountReporting />,
					secondary: true,
					showToMenu: true,
					icon: <Icon as={TbFileReport} width="20px" height="20px" />,
				},
				...(accountType === ACCOUNT_ACCESS_CLASS.ADVANCE
					? [
							{
								name: "Location Report",
								layout: "/account",
								path: "/location-reporting",
								component: <LocationReporting />,
								secondary: true,
								showToMenu: true,
								icon: <Icon as={TbFileReport} width="20px" height="20px" />,
							},
					  ]
					: []),
				{
					name: "Account Billing",
					layout: "/account",
					path: "/billing",
					component: <AccountFullBilling />,
					secondary: true,
					showToMenu: true,
					icon: <Icon as={TbFileReport} width="20px" height="20px" />,
				},
			],
		},
		{
			name: "Settings",
			path: "/account",
			icon: <Icon as={AiOutlineSetting} width="25px" height="25px" />,
			collapse: true,
			items: [
				{
					name: "Team Members",
					layout: "/account",
					path: "/team",
					component: <AccountTeam />,
					secondary: true,
					showToMenu: true,
					icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
				},
				{
					name: "Profile",
					layout: "/account",
					path: "/profile",
					component: <BusinessProfile />,
					secondary: true,
					showToMenu: true,
					icon: <Icon as={AiOutlineUser} width="20px" height="20px" />,
				},
				{
					name: "Application settings",
					layout: "/account",
					path: "/applicationsettings",
					component: <ApplicationSettings />,
					secondary: true,
					showToMenu: true,
					icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
				},
				{
					name: "Account Design",
					layout: "/account",
					path: "/applicationdesign",
					component: <AccountDesign />,
					secondary: true,
					showToMenu: true,
					icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
				},
				{
					name: "Domain setup",
					layout: "/account",
					path: "/domainsetup",
					component: <SubDomainSetup />,
					secondary: true,
					showToMenu: true,
					icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
				},
			],
		},
		{
			name: "Help",
			path: "/account",
			icon: <Icon as={AiOutlineGift} width="25px" height="25px" />,
			collapse: true,
			items: [
				{
					name: "Get Help",
					layout: "/account",
					path: "/help",
					component: <Helper />,
					secondary: true,
					showToMenu: true,
					icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
				},
				{
					name: "Compliance",
					layout: "/account",
					path: "/trust",
					component: <PlatformTrust />,
					secondary: true,
					showToMenu: true,
					icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
				},
			],
		},
	].filter(item => !item.isHidden);
};

export default accountRoutes;
