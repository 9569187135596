import { Box, Flex, Icon, Image, Textarea } from '@chakra-ui/react'
import React from 'react'
import { useEffect } from 'react'
import { forwardRef } from 'react'
import { FaPhone, FaPhoneSlash } from 'react-icons/fa'
import callingGif from "../../../../../assets/communicator/callingGif.gif";


const AudioCallComp = forwardRef(function AudioCallComp(props, ref) {
  const { callStarted, callLog, setSelectedValue, demoHistoryTag, setDemoHistoryTag, selectedSettings } = props
  const streamRef = ref

  async function getMicAndVideoStream() {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: false,
      audio: true,
    });
    if (stream) {
      streamRef.current = stream
    } else {
      console.error("getUserMedia returned a null or undefined stream.");
    }
  };

  useEffect(() => {
    getMicAndVideoStream()
    return () => {

      if (streamRef.current) {
        console.log("removing ......")
        streamRef.current.getAudioTracks().forEach(function (track) {
          track.stop();
        });

        streamRef.current.getVideoTracks().forEach(function (track) {
          track.stop();
        });

        streamRef.current = null;
      }
    }
  }, [])

  useEffect(() => {
    setSelectedValue(selectedSettings);
    if (demoHistoryTag) {
      getMicAndVideoStream();
    }
    if (selectedSettings?.sourceLangCode === 1000 || selectedSettings?.targetLangCode === 1000) {
      setDemoHistoryTag(false)
    }
  }, [selectedSettings]);

  return (
    <Flex
      alignItems={"center"}
      justifyContent={"space-between"}
      borderWidth={"1px"}
      h="100%"
      w="100%"
      flexWrap={"wrap"}>
      <Flex
        alignItems={"center"}
        justifyContent={"center"}
        w={{
          base: "100%",
          md: "50%",
          xl: "50%",
          "2xl": "50%",
        }}
        h={{
          base: "50%",
          md: "100%",
          xl: "100%",
          "2xl": "100%",
        }}
        borderBottomWidth={{
          base: "1px",
          md: "0px",
          xl: "0px",
          "2xl": "0px",
        }}
        borderRightWidth={{
          base: "0px",
          md: "1px",
          xl: "1px",
          "2xl": "1px",
        }}>
        <Box>
          {callStarted === null ? (
            <Icon
              as={FaPhone}
              fontSize="10rem"
              borderWidth={"1px"}
              borderRadius={"50%"}
              p="2rem"
              color={"gray"}
            />
          ) : callStarted ? (
            // <Icon
            //   as={FaPhone}
            //   fontSize="10rem"
            //   borderWidth={"1px"}
            //   borderRadius={"50%"}
            //   borderColor={"green.500"}
            //   p="2rem"
            //   bg={"green.500"}
            // />
            <Image src={callingGif} w="50%" mx="auto" />
          ) : (
            <Icon
              as={FaPhoneSlash}
              fontSize="10rem"
              borderWidth={"1px"}
              borderRadius={"50%"}
              borderColor={"red.500"}
              bg={"red.500"}
              p="2rem"
            />
          )}
        </Box>
      </Flex>
      <Textarea
        value={callLog}
        color="#000"
        rows={10}
        border="0"
        p="1"
        w={{
          base: "100%",
          md: "50%",
          xl: "50%",
          "2xl": "50%",
        }}
        h={{
          base: "50%",
          md: "100%",
          xl: "100%",
          "2xl": "100%",
        }}
        borderBottom={{
          base: "1px",
          md: "0px",
          xl: "0px",
          "2xl": "0px",
        }}
        borderColor={"#d7d7d7"}
      />
    </Flex>
  )
})

export default AudioCallComp