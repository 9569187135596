import React from 'react'
import { Alert, AlertIcon, Box, Button, Flex, Icon, Spacer, Spinner, Text, Tooltip, useToast } from '@chakra-ui/react';
import { AiFillLeftCircle, AiFillRightCircle } from 'react-icons/ai';
import { useBrandBg, useTextColor } from '../../theme/globalColorTheme';
import { useState } from 'react';
import Card from '../../components/card/Card';
import { DocAiEmailActivity, DocAiEmailActivityList, DocAiEmailSyncList, DocAiMetrics, DocAiRework, DocAiWorkerHeartbeat } from '../../services/accountServices';
import { toastFunctionToaster } from '../../utils/toastFunction';
import { useEffect } from 'react';
import { DOC_AI_WORKER_MANAGEMENT, EXTERNAL_STORAGE } from './constants';
import MetricsDashboard from './components/MetricsDashboard';
import Pagination from '../../components/pagination/Pagination';
import { PiCheckCircleFill } from 'react-icons/pi';
import { HiExclamationCircle } from 'react-icons/hi';
import { FaSync } from 'react-icons/fa';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import moment from 'moment'
import { getProjectsWithSharedAccessList } from '../../services/businessServices';
import { getGroupId } from '../../utils/localStorageIndex';



const Management = () => {
    const brandBg = useBrandBg();
    const [configurationCollapseFlag, setConfiguationCollapseFlag] =
        useState(true);
    const [workerStatus, setWorkerStatus] = useState(null)
    const [workerStatusReqSent, setWorkerStatusReqSent] = useState(false)
    const toast = useToast()
    const [emailSyncLIst, setEmailSyncList] = useState([])
    const [metrics, setMetrics] = useState([])
    const [loading, setLoading] = useState(false)
    const [btnLoading, SetBtnLoading] = useState(false)

    const groupId = getGroupId();
    const [pageId, setPageId] = useState(1)
    const [recordsPerPage, setRecordsPerPage] = useState(5)
    const [totalPages, setTotalPages] = useState(0)
    const [activityList, setActivityList] = useState({})
    const [projectsList, setProjectsList] = useState([])
    const [selectedEmail, setSelectedEmail] = useState(null)
    const [showMetricesTab,setShowMetricesTab] = useState(null)


    useEffect(() => {
        setShowMetricesTab(groupId === '200');  //Show Matrix Tab To Business User Only 
    }, [groupId]);
    


    function checkHeartBeat() {
        setWorkerStatusReqSent(true)
        SetBtnLoading(btnLoading => ({ ...btnLoading, check_worker: true }))
        DocAiWorkerHeartbeat().then(resp => {
            if (resp.data[0]?.status) {
                toast(toastFunctionToaster("Worker online", "success"))
                return setWorkerStatus(true)
            }
            setWorkerStatus(false)
            toast(toastFunctionToaster("Worker offline", "error"))
        }).catch(() => {
            toast(toastFunctionToaster("Worker offline", "error"))
            setWorkerStatus(false)
        }).finally(() => {
            SetBtnLoading(btnLoading => ({ ...btnLoading, check_worker: false }))
        })
    }

    function getDocAiMetrics() {

        setLoading(true)
        DocAiMetrics().then(resp => {
            if (resp?.data[0]?.status) {
                setMetrics(resp?.data[0]?.metrics)
            } else {
                toast(toastFunctionToaster(resp?.message, "error"))
            }
        }).catch(err => {
            toast(toastFunctionToaster("Failed to fetch metrics data", "error"))
        }).finally(() => {
            setLoading(false)
        })
    }

    function getDocAiEmailSyncList() {
        setLoading(true)
        const paramObj = {
            "page_id": 1,
            "records_per_page": 10,
            "filter_key": setSelectedEmail ? "target_email_address" : "",
            "filter_value": selectedEmail ? selectedEmail : "",
        }
        DocAiEmailSyncList(paramObj).then(resp => {
            if (resp?.data[0]?.status) {
                setEmailSyncList(resp?.data[0]?.data[0]?.records)
                setTotalPages(resp?.data[0]?.data[0]?.total_pages)
            } else {
                toast(toastFunctionToaster(resp?.message, "error"))
            }
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    function getActivity(msg_uuid) {
        const paramObj = {
            "inbox_msg_uuid": msg_uuid
        }
        SetBtnLoading(btnLoading => ({ ...btnLoading, activity: msg_uuid }))
        DocAiEmailActivity(paramObj).then(resp => {
            if (resp.data[0]?.status) {
                console.log(resp.data[0])
            }
        }).catch(err => { }).finally(() => {
            SetBtnLoading(btnLoading => ({ ...btnLoading, activity: null }))
        })
    }

    function getActivityList(msg_uuid) {
        const paramObj = {
            "page_id": 1,
            "records_per_page": 10,
            "filter_key": "",
            "filter_value": "",
            "email_sync_inbox_message_uuid": msg_uuid
        }
        SetBtnLoading(btnLoading => ({ ...btnLoading, activityList: msg_uuid }))

        DocAiEmailActivityList(paramObj).then(resp => {

            if (resp.data[0]?.status) {
                setActivityList(activityList => ({ ...activityList, [msg_uuid]: { data: resp?.data[0]?.data[0]?.records, showData: true } }))
            } else {
                setActivityList(activityList => ({ ...activityList, [msg_uuid]: { data: null, showData: false } }))
            }
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            SetBtnLoading(btnLoading => ({ ...btnLoading, activityList: null }))
        })

    }
    function rework(msg_uuid) {
        const paramObj = {
            "inbox_msg_uuid": msg_uuid
        }
        SetBtnLoading(btnLoading => ({ ...btnLoading, rework: msg_uuid }))
        DocAiRework(paramObj).then(resp => {
            if (resp.data[0]?.status) {
                toast(toastFunctionToaster("Rerun request sent", "success"))
            } else {
                toast(toastFunctionToaster("Failed to add Rerun request 1", "error"))
            }
        }).catch(err => {
            toast(toastFunctionToaster("Failed to add Rerun request 2", "error"))
        }).finally(() => {
            SetBtnLoading(btnLoading => ({ ...btnLoading, rework: null }))
        })
    }

    function getProjectLIstWithAccessControl() {
        SetBtnLoading(btnLoading => ({ ...btnLoading, projectsList: true }))
        getProjectsWithSharedAccessList().then(resp => {
            if (resp.data[0]?.status) {
                setProjectsList(resp.data[0]?.records)
            } else {
                toast(toastFunctionToaster("Failed to get Projects list", "error"))
            }
        }).catch(err => { toast(toastFunctionToaster("Failed to get Projects list", "error")) }).finally(() => {
            SetBtnLoading(btnLoading => ({ ...btnLoading, projectsList: false }))
        })
    }

    const handleRefreshList = () => {
        if (selectedEmail) {
            setSelectedEmail(null)
        } else {
            getDocAiEmailSyncList()
        }
    }

    useEffect(() => {
        getProjectLIstWithAccessControl()
        getDocAiMetrics()
        checkHeartBeat()
    }, [])

    useEffect(() => {
        getDocAiEmailSyncList()
    }, [pageId, recordsPerPage, selectedEmail])

    useState(() => {
        setPageId(1)
    }, [recordsPerPage])


    return (
        <Flex direction="column" pt={{ sm: "130px", lg: "80px" }} h="full">

            <Flex gap="2" alignItems="center" justifyContent="space-between" maxH="60px">
                <Box w="full" mt={2} minH="5px" bg={workerStatus ? "green.300" : "red.300"} mb="2"></Box>
                <Button size={'xs'} rounded={'lg'} onClick={checkHeartBeat} isLoading={btnLoading?.check_worker} leftIcon={<FaSync />} colorScheme='blue'>Refresh</Button>
            </Flex>

            <Tabs align='start' variant='enclosed-colored' marginTop={2} h="calc(100% - 60px)" colorScheme='blue'>
                <TabList h="50px">
                    <Tab>Translation Email Events - {groupId}</Tab>
                    { showMetricesTab ? (
                        <Tab>Translation Metrics</Tab>
                    ) : null }
                </TabList>
                <TabPanels h="94%" p="0" overflow="hidden">
                    <TabPanel h="full" p="0.5">
                        <Card p="1" h="full">
                            <Flex h="full" overflow="hidden">
                                <Box
                                    w={!configurationCollapseFlag ? "95%" : "80%"}
                                    bg={brandBg}
                                    p="2"
                                    borderRadius={"10px"}
                                    h="full"
                                >
                                    <Card w={'100%'} rounded={'lg'} mb={1}>
                                        <Text>Selected Sender Email:
                                            <Text as={"span"} ml={1} color={'blue.400'} fontWeight={400}>{selectedEmail} </Text>
                                        </Text>
                                    </Card>

                                    {<Flex h="full" overflow="hidden">
                                        {
                                            loading ? <Spinner /> :
                                                <>
                                                    {emailSyncLIst.length > 0 ?
                                                        <Flex h="full" direction="column" gap={2} w="full" overflow="hidden">
                                                            <Flex overflowY="auto" h="90%" className='custom-scrollbar' direction="column" gap="2">
                                                                {
                                                                    emailSyncLIst?.map((item, index) =>
                                                                        <Card key={index} p="2" borderRadius="5px" w="full" borderColor={item?.action_success ? "green.200" : 'red.300'} borderWidth="1px" h="fit-content" borderLeftWidth="6px">

                                                                            <Flex gap={1} alignItems={"center"} justifyContent={"space-between"} w="full">
                                                                                <Flex gap="1" color={useTextColor} w="full">
                                                                                    <Text fontWeight="bold" w="105px">Sent on</Text>: <Text ml="1">
                                                                                        {/* {moment(item?.inbox_msg_created_time).format("MMM DD,YYYY, HH:mm a")} */}
                                                                                        {moment(item?.inbox_msg_created_time).format("llll")}
                                                                                    </Text>
                                                                                </Flex>
                                                                                <Flex gap="1">
                                                                                    {/* <Button size="sm" variant="outline" colorScheme='green' onClick={() => getActivity(item?.inbox_msg_uuid)} isLoading={btnLoading?.activity === item?.inbox_msg_uuid}>Activity</Button> */}
                                                                                    {activityList[item?.inbox_msg_uuid]?.showData ? <>
                                                                                        <Button
                                                                                            size="sm"
                                                                                            variant="outline"
                                                                                            colorScheme='blue'
                                                                                            onClick={() => {

                                                                                                getActivityList(item?.inbox_msg_uuid)
                                                                                            }} isLoading={btnLoading?.activityList === item?.inbox_msg_uuid}
                                                                                        >
                                                                                            Refresh
                                                                                        </Button> <Button variant="outline" colorScheme='green' size="sm" onClick={() => setActivityList(activityList => ({ ...activityList, [item?.inbox_msg_uuid]: { ...activityList[item?.inbox_msg_uuid], showData: false } }))}>Hide Activity List</Button> </> :
                                                                                        <Button size="sm" variant="outline" colorScheme='green' onClick={() => getActivityList(item?.inbox_msg_uuid)} isLoading={btnLoading?.activityList === item?.inbox_msg_uuid}>Show Activity List</Button>}
                                                                                    {!item?.action_success && <Button size="sm"
                                                                                        variant="outline"
                                                                                        colorScheme={item?.acted ? 'green' : 'red'}
                                                                                        onClick={() => rework(item?.inbox_msg_uuid)}
                                                                                        isLoading={btnLoading?.rework === item?.inbox_msg_uuid}>Retry Translation
                                                                                    </Button>}
                                                                                </Flex>
                                                                            </Flex>
                                                                            <Flex gap={1} color={useTextColor} w="full"><Text fontWeight="bold" w="105px">Sender Email</Text>: <Text ml="1">{item?.target_email}</Text></Flex>
                                                                            <Flex gap={1} color={useTextColor} w="full"><Text fontWeight="bold" w="105px">Subject</Text>: <Text ml="1">{item?.subject}</Text></Flex>
                                                                            <Flex gap={1} color={useTextColor} w="full"><Text fontWeight="bold" w="105px">Body</Text>: <Text ml="1">{item?.body}</Text></Flex>
                                                                            <Flex gap={1} color={useTextColor} w="full"><Text fontWeight="bold" w="105px">Attachments</Text>: <Text ml="1">{item?.attachments}</Text></Flex>
                                                                            {item?.action_result_jsonstring && <Flex gap={1} color={useTextColor} w="full"><Text fontWeight="bold" w="105px">Action result</Text>: <Text ml="1" color={item?.action_success ? "green.200" : "red.300"}>{item?.action_result_jsonstring}</Text></Flex>}
                                                                            {activityList[item?.inbox_msg_uuid]?.showData &&
                                                                                <Flex overflow="hidden" maxH="280px" direction="column" gap="2" p="2" borderRadius="5px" borderColor={item?.action_success ? "green.200" : 'red.300'} borderWidth="1px" mt="2">
                                                                                    <Flex h="30px">
                                                                                        <Text fontWeight="bold"> Activity List:</Text>
                                                                                        <Spacer />
                                                                                    </Flex>
                                                                                    <Flex h="250px" className='custom-scrollbar' overflow="auto" bg={brandBg} p="2" direction="column" gap="2">
                                                                                        {
                                                                                            activityList[item?.inbox_msg_uuid]?.data.length > 0 && <>
                                                                                                {
                                                                                                    activityList[item?.inbox_msg_uuid]?.data?.map(activity => (
                                                                                                        <Card p="1.5" borderRadius="5px"  >
                                                                                                            <Flex flexDir="column" >
                                                                                                                <Text fontWeight="bold" fontSize="md">Activity</Text>
                                                                                                                <Flex gap="4" pl="3">
                                                                                                                    <Flex gap="1" justifyContent="center" alignItems="center"><Text fontWeight="bold">Acted:</Text>{activity?.acted ? <Icon as={PiCheckCircleFill} color="green.300" boxSize="5" /> : <Icon as={HiExclamationCircle} color="red.300" boxSize="5" />}</Flex>
                                                                                                                    <Flex gap="1" justifyContent="center" alignItems="center"><Text fontWeight="bold">Success:</Text>{activity?.action_success ? <Icon as={PiCheckCircleFill} color="green.300" boxSize="5" /> : <Icon as={HiExclamationCircle} color="red.300" boxSize="5" />}</Flex>
                                                                                                                </Flex>
                                                                                                                <Flex pl="3" gap="2" flexDir="column" mt="2" overflowY="auto">
                                                                                                                    {
                                                                                                                        activity?.log_json?.map((log, index) => (
                                                                                                                            <Flex flexDir="row" key={index} gap="2" >
                                                                                                                                <Flex gap="1" justifyContent="center" alignItems="center"><Text fontWeight="bold"></Text>{log?.status ? <Icon as={PiCheckCircleFill} color="green.300" boxSize="5" /> : <Icon as={HiExclamationCircle} color="red.300" boxSize="5" />}</Flex>
                                                                                                                                <Text whiteSpace="nowrap" fontSize="md" fontWeight="bold">{moment(log?.timestamp).format("MMM DD, YYYY hh:mm A")}</Text>
                                                                                                                                <Text whiteSpace="nowrap" fontSize="md">{log.log}</Text>
                                                                                                                            </Flex>))
                                                                                                                    }
                                                                                                                </Flex>
                                                                                                            </Flex>
                                                                                                        </Card>
                                                                                                    ))
                                                                                                }
                                                                                            </>
                                                                                        }
                                                                                    </Flex>
                                                                                </Flex>
                                                                            }
                                                                        </Card>
                                                                    )
                                                                }
                                                            </Flex>
                                                            {emailSyncLIst.length > 0 && <Pagination currentPage={pageId} recordsPerPage={recordsPerPage} setCurrentPage={setPageId} setRecordsPerPage={setRecordsPerPage} totalPages={totalPages} />}
                                                        </Flex> : <Alert status='info' h="fit-content">
                                                            <AlertIcon />
                                                            Email sync list is empty
                                                        </Alert>
                                                    }
                                                </>
                                        }


                                    </Flex>}

                                </Box>
                                {configurationCollapseFlag ? (
                                    <Box
                                        w="20%"
                                        position="relative"
                                        p="2"
                                        borderRadius="15px"
                                        bg={brandBg}
                                        ml="2"
                                    >
                                        <Text fontWeight="bold" pl="2" pb="2">Projects with shared access</Text>
                                        <Box
                                            position="absolute"
                                            top="0"
                                            right="0"
                                            mr="2"
                                            mt="3"
                                            zIndex="999"
                                        >
                                            <AiFillRightCircle
                                                cursor="pointer"
                                                size="20px"
                                                onClick={() => {
                                                    setConfiguationCollapseFlag(
                                                        !configurationCollapseFlag
                                                    );
                                                }}
                                            />
                                        </Box>
                                        <Box w="100%" h="full" bg={brandBg}>
                                            <Card h="95%" w="full" p="0" rounded={'lg'} overflow="hidden">
                                                <Flex p="2" justifyContent="center">
                                                    <Button 
                                                        rounded={'lg'}
                                                        colorScheme='blue' 
                                                        onClick={handleRefreshList}>Email Translation Requests</Button>
                                                </Flex>
                                                <Flex direction="column" pt="3" gap="2" w="full" overflowY="auto" pr="1" pl="1" className="custom-scrollbar" pb="3">
                                                    {
                                                        btnLoading?.projectsList ? <Spinner /> :
                                                            <>
                                                                <Flex direction="column" gap="2" w="full">
                                                                    {
                                                                        projectsList.length > 0 && projectsList.map((project, index) => (
                                                                            <Card key={index} p="2" borderRadius="5px" w="full" borderColor="green.200" borderWidth="1px">
                                                                                <Flex gap={1} color={useTextColor} w="full">
                                                                                    <Tooltip label={project.project_title} hasArrow>
                                                                                        <Text fontWeight="bold" isTruncated maxW="full">
                                                                                            Project: {project.project_title}
                                                                                        </Text>
                                                                                    </Tooltip>
                                                                                </Flex>
                                                                                {
                                                                                    <>{
                                                                                        project?.shared_access.length > 0 ?
                                                                                            <Flex gap={1} color={useTextColor} flexDir="column" pl="3" w="full">
                                                                                                {
                                                                                                    project.shared_access.map((access, index) => (
                                                                                                        <Flex key={index} gap={1} w="full">
                                                                                                            <Tooltip label={access.control_user_email} hasArrow>
                                                                                                                <Text isTruncated maxW="full" onClick={() => setSelectedEmail(access.control_user_email)} color={selectedEmail === access.control_user_email ? "green.300" : useTextColor} textDecoration={selectedEmail === access.control_user_email ? 'underline' : 'none'} cursor="pointer" _hover={{ color: "blue.300", textDecoration: 'underline' }}>
                                                                                                                    {access.control_user_email}
                                                                                                                </Text>
                                                                                                            </Tooltip>
                                                                                                        </Flex>
                                                                                                    ))}
                                                                                            </Flex> :

                                                                                            <Alert status='info' h="fit-content" fontSize="sm" p="1" mt="2">
                                                                                                <AlertIcon />
                                                                                                Project not shared with anyone
                                                                                            </Alert>
                                                                                    }

                                                                                    </>
                                                                                }
                                                                            </Card>

                                                                        ))
                                                                    }
                                                                </Flex>
                                                            </>
                                                    }
                                                </Flex>
                                            </Card>
                                        </Box>
                                    </Box>
                                ) : (
                                    <Box
                                        w="5%"
                                        p="3"
                                        borderRadius="10px"
                                        bg={brandBg}
                                        minH="100%"
                                        ml="2"
                                        pl="5"
                                    >
                                        <Box w={"100%"} h="100%">
                                            <AiFillLeftCircle
                                                cursor="pointer"
                                                onClick={() => {
                                                    setConfiguationCollapseFlag(
                                                        !configurationCollapseFlag
                                                    );
                                                }}
                                                size="20px"
                                            />
                                            <Text
                                                fontSize="16px"
                                                mt="2"
                                                css={{
                                                    writingMode: "vertical-lr",
                                                    textOrientation: "mixed",
                                                }}
                                            >
                                                Expand Management Settings
                                            </Text>
                                        </Box>
                                    </Box>
                                )}
                            </Flex>
                        </Card>
                    </TabPanel>
                    { showMetricesTab ? (
                        <TabPanel h="full" p="0.5">
                            <Card h="full" p="1">
                                <Flex h="full" overflow="hidden">
                                    <MetricsDashboard metrics={metrics} getDocAiMetrics={getDocAiMetrics} loading={loading} />
                                </Flex>
                            </Card>
                        </TabPanel>
                    ) : null }
                </TabPanels>
            </Tabs>



        </Flex>
    );
}

export default Management