import React, { useState } from "react";
import { Button, Stack, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure, Radio, RadioGroup } from "@chakra-ui/react";

function AddUpdateProofDetails() {
	const btnRef = React.useRef();
	const [value, setValue] = useState("1");
	const { isOpen, onOpen, onClose } = useDisclosure();


	return (
		<>

			<Button ref={btnRef} borderColor="black" fontWeight={"400"} onClick={onOpen} _hover={{ bg: 'transparent' }} bg='transparent' textAlign={'left'} p='0'>
				Update Status
			</Button>

			<Drawer size="md" isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={btnRef}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>Details</DrawerHeader>

					<DrawerBody>
						<RadioGroup onChange={setValue} value={value}>
							<Stack>
								<Radio size="md" value="1" colorScheme="green">
									Language Associate 1
								</Radio>
								<Radio size="md" value="2" colorScheme="green">
									Language Associate 2
								</Radio>
							</Stack>
						</RadioGroup>
					</DrawerBody>

					<DrawerFooter>
						<Button variant="outline" mr={3} onClick={onClose}>
							Cancel
						</Button>
						<Button colorScheme="blue">Assign</Button>
					</DrawerFooter>
				</DrawerContent>
			</Drawer >
		</>
	);
}

export default AddUpdateProofDetails;
