import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Flex,
  IconButton,
  Text,
  VStack,
  Tooltip,
  Image,
} from "@chakra-ui/react";
import { ChevronLeftIcon, HamburgerIcon } from "@chakra-ui/icons";
import klogo from "../../../media/images/Lingolet-2C-large.png"
import { useEffect } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";

const Sidebar = ({ menuItems }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const userEmail = useSelector((state) => state.SharedSpaceUserData?.userEmail);
  const toggleSidebar = () => setIsCollapsed(!isCollapsed);

  const handleMenuItemClick = (item) => {
    navigate(item.path);
  };

  const isActive = (path) => {
    const basePath = "/translation/documents";
    if (path === "") {
      return location.pathname === basePath;
    }
    return location.pathname === `${basePath}/${path}`;
  };

  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const checkOverflow = () => {
      const element = textRef.current;
      if (element) {
        setIsOverflowing(element.scrollWidth > element.offsetWidth);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => window.removeEventListener("resize", checkOverflow);
  }, []);

  return (
    <Flex>
      <Box
        as="nav"
        borderRight="1px solid"
        borderColor="gray.200"
        w={isCollapsed ? "70px" : "250px"}
        transition="width 0.2s ease-in-out"
        position="relative"
      >
        <Flex textAlign="right" p={2} borderBottom="1px solid" borderColor="gray.200" justifyContent="space-between" alignItems="center">
          {!isCollapsed && <Image
            h="60px"
            w="60%"
            src={klogo}
            objectFit={"contain"}
          />}
          <IconButton
            aria-label="Toggle Sidebar"
            icon={isCollapsed ? <HamburgerIcon /> : <ChevronLeftIcon />}
            onClick={toggleSidebar}
            size="lg"
            variant="ghost"
          />
        </Flex>

        <VStack spacing={2} align="stretch" mt={4}>
          {menuItems.map((item) => (
            <Tooltip
              key={item.name}
              label={isCollapsed ? item.name : ""}
              placement="right"
            >
              <Flex
                align="center"
                p={3}
                mx={2}
                borderRadius="md"
                bg={isActive(item.path) ? "blue.100" : "transparent"}
                color={isActive(item.path) ? "blue.600" : "gray.700"}
                _hover={{ bg: "gray.100", cursor: "pointer" }}
                onClick={() => handleMenuItemClick(item)}
              >
                {item.icon}
                {!isCollapsed && (
                  <Text ml={4} fontSize="sm" fontWeight="medium">
                    {item.name}
                  </Text>
                )}
              </Flex>
            </Tooltip>
          ))}
        </VStack>
        {(!isCollapsed && userEmail) && <Flex position="absolute" bottom="0" p="1" pb="4" justifyContent="center" w="full" bg="#EFF4FB">
          <Tooltip
            label={userEmail} 
            isDisabled={!isOverflowing}
            placement="top"
            hasArrow
          >
            <Text
              ref={textRef}
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              maxWidth="90%"
              display="block"
            >
              {userEmail}
            </Text>
          </Tooltip>
        </Flex>
        }
      </Box>
    </Flex>
  );
};

export default Sidebar;
