import { Box, Flex, Icon } from "@chakra-ui/react";
import { Route, Routes, useSearchParams } from "react-router-dom";
import Sidebar from "../../documentai/shareControl/SideBar";
import { AiOutlineDashboard } from "react-icons/ai";
import Documents from "../../documentai/shareControl/Documents";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { SET_SHARED_ACCESS_SPACE, SET_SHARED_ACCESS_TYPE } from "../../../store/actionTypes";
import { useEffect } from "react";
import UploadContents from "../../documentai/components/uploadContents";
import { useSelector } from "react-redux";

const menuItemsList = [
    {
        name: "Files",
        path: "",
        icon: <Icon as={AiOutlineDashboard} width="25px" height="25px" />,
        component: <Documents />,
    }

];

const DocumentControlAccess = () => {
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.SharedSpaceUserData?.token);
    const email = useSelector((state) => state.SharedSpaceUserData?.userEmail);
    const project_uuid = useSelector((state) => state.SharedSpaceUserData?.projectUuid);
    const [menuItems, setMenuItems] = useState(menuItemsList)
    useEffect(() => {
        if (searchParams.get('type')) {
            dispatch({ type: SET_SHARED_ACCESS_TYPE, value: searchParams.get('type') })
        }
        if (searchParams.get('space')) {
            dispatch({ type: SET_SHARED_ACCESS_SPACE, value: searchParams.get('space') })
        }
    }, [searchParams])

    useEffect(() => {
        if (token && project_uuid && email) {
            setMenuItems(items => ([...items, {
                name: "Upload Files",
                path: "upload",
                icon: <Icon as={AiOutlineDashboard} width="25px" height="25px" />,
                component: <UploadContents email={email} token={token} projectUuid={project_uuid} />,
            }]))

        }else{
            console.log(project_uuid)
        }

    }, [email, project_uuid, token])



    return (
        <Flex height="100vh">
            <Sidebar menuItems={menuItems} />
            <Box flex="1" overflow="auto">
                <Routes>
                    {menuItems.map((item) => (
                        <Route
                            key={item.name}
                            path={item.path}
                            element={item.component}
                        />
                    ))}
                </Routes>
            </Box>
        </Flex>
    );
};

export default DocumentControlAccess;
