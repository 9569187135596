// Chakra Imports
import { FiRefreshCw } from "react-icons/fi";
import React, { useState, useEffect } from "react";
import { Flex, Button, Input, HStack, Box, Text, useColorModeValue, Alert, AlertIcon, Spinner, IconButton } from "@chakra-ui/react";

// Component Imports
import Card from "../../../components/card/Card";
import Pagination from "../../../components/pagination/Pagination";
import { getConsumerProfreadingDetails, consumerProfWorkflowStatus, consumerProfProgressStatus } from "../../../services/accountServices";

function ProofreadingHome() {
	const [currPage, setCurrPage] = useState(1);
	const [loading, isLoading] = useState(false);
	const [totalPages, setTotalPages] = useState(1);
	const [profeadingList, setProfeadingList] = useState([]);
	const tabBg = useColorModeValue("#eeeeee", "#707070");
	const [recordsPerPage, setRecordsPerPage] = useState(10);

	//Fetch Proofreading Data
	useEffect(() => {
		collectProofreadingData();
	}, []);

	const collectProofreadingData = () => {
		isLoading(true);
		let paramObj = {
			filter_key: "started",
			records_per_page: recordsPerPage,
			page_id: 1,
		};
		getConsumerProfreadingDetails(paramObj)
			.then(res => {
				isLoading(false);
				if (res?.data[0]?.status) {
					isLoading(false);
					setProfeadingList(res?.data);
				} else {
					isLoading(false);
					setProfeadingList([]);
				}
			})
			.catch(err => {
				isLoading(false);
				setProfeadingList([]);
			});
	};

	// Todo:Check progress Status
	const checkProgress = data => {
		let objBody = {
			prp_uuid: data?.prp_uuid,
		};
		consumerProfProgressStatus(objBody)
			.then(res => {
				console.log(res);
			})
			.catch(err => {
				console.log(err);
			});
	};

	// Todo:Check Workflow Info
	const checkWorkflow = data => {
		let objBody = {
			filter_key: "Completed",
			records_per_page: recordsPerPage,
			page_id: 1,
		};
		consumerProfWorkflowStatus(objBody)
			.then(res => {
				console.log(res);
			})
			.catch(err => {
				console.log(err);
			});
	};

	// Todo: Render Data
	const renderproofDataData =
		profeadingList?.length > 0 ? (
			profeadingList.map((data, index) => (
				<Flex key={index} variant="enclosed" size="md" borderWidth="1px" borderRadius="10px" mb="3" p="10px" justifyContent="space-between">
					<Box w="100%">
						<Flex justifyContent={"space-between"} alignItems={"start"}>
							<Box>
								<Text fontSize="18px" fontWeight="500">
									Job Type {data?.job_type}
								</Text>
								<Text>{data?.source_language}</Text>
								<Text>{data?.target_language}</Text>
								<Text>{data?.current_status}</Text>
							</Box>
							<Box>
								<Button
									size="md"
									mr="2"
									borderRadius={"0"}
									onClick={() => {
										checkProgress(data);
									}}
								>
									Progress
								</Button>
								<Button
									size="md"
									mr="2"
									borderRadius={"0"}
									onClick={() => {
										checkWorkflow(data);
									}}
								>
									Workflow Info
								</Button>
							</Box>
						</Flex>
					</Box>
				</Flex>
			))
		) : (
			<Alert status="warning" mt="4">
				<AlertIcon />
				<Text>No data added yet.</Text>
			</Alert>
		);

	return (
		<Flex direction="column">
			<Card w={"100%"} rounded={"md"} mb={2} p="20px">
				<HStack gap={1} h={"full"} mb="3" justifyContent="space-between">
					<Box>
						<Input size={"sm"} bg={tabBg} fontWeight={"500"} fontSize={"16px"} w="300px" placeholder="Filter <>" borderRadius={0} rounded={"none"} borderColor={"gray.400"} mr="3" />
						<Input size={"sm"} bg={tabBg} fontWeight={"500"} fontSize={"16px"} w="300px" placeholder="Search <>" borderRadius={0} rounded={"none"} borderColor={"gray.400"} mr="3" />
						<Button size={"sm"} bg={tabBg} fontWeight={"500"} fontSize={"16px"} borderRadius={0} rounded={"none"} borderWidth={"1px"} borderColor={"gray.400"} px={5} mb="1px">
							GO
						</Button>
					</Box>
					<Flex alignItems={"start"}>
						<IconButton
							ml={2}
							size={"sm"}
							bg={tabBg}
							borderRadius={"5px"}
							borderWidth={"1px"}
							borderColor={"gray.800"}
							pr={2}
							rightIcon={<FiRefreshCw />}
							onClick={() => {
								collectProofreadingData();
							}}
						></IconButton>
					</Flex>
					{/*  */}
				</HStack>
				{loading ? (
					<Flex alignItems="center" justifyContent="center" h="50vh">
						<Spinner size="lg" />
					</Flex>
				) : (
					<>
						{renderproofDataData}
						{profeadingList.length ? <Pagination currentPage={currPage} recordsPerPage={recordsPerPage} setCurrentPage={setCurrPage} setRecordsPerPage={setRecordsPerPage} totalPages={totalPages} /> : null}
					</>
				)}
			</Card>
		</Flex>
	);
}

export default ProofreadingHome;
