import React from "react";
import {
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerBody,
    DrawerCloseButton,
    Button,
    Flex,
    Text,
    Box,
    DrawerFooter,
} from "@chakra-ui/react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import ReactAudioPlayer from "react-audio-player";

const TranslatedDocumentView = ({ isOpen, onOpen, onClose, uri, setUri, contentType }) => {
    function handleCLose() {
        setUri(null);
        onClose();
    }
    console.log(uri, contentType);

    return (
        <>
            <Drawer isOpen={isOpen} placement="right" onClose={handleCLose} size="lg">
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Document Viewer</DrawerHeader>

                    <DrawerBody overflow={"hidden"}>
                        <Box w={'100%'} h={'100%'} overflow="auto" className="custom-scrollbar">
                            {/* {contentType === 'text'
                                ? <Flex w={'100%'} direction={'column'}>
                                    <Card w={'100%'} mt={1}>
                                        <Text>{documentTitle}</Text>
                                    </Card>
                                    <Card w={'100%'}
                                        h="calc(100vh - 300px)"
                                        overflow="auto"
                                        borderTopWidth="0px"
                                        css={{
                                            '&::-webkit-scrollbar': {
                                                width: '4px',
                                            },
                                            '&::-webkit-scrollbar-track': {
                                                width: '6px',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                borderRadius: '24px',
                                            },
                                        }}
                                        mt={1}>
                                        <pre style={{ whiteSpace: 'pre-wrap', fontFamily: 'inherit' }}>{documentText}</pre>
                                    </Card>
                                </Flex> : null} */}
                            {contentType === 'doc' && uri != null
                                ? <Flex w={'100%'} direction={'column'}>
                                    {contentType === 'pdf'
                                        ? <DocViewer
                                            pluginRenderers={DocViewerRenderers}
                                            documents={[{ uri: uri, fileName: "Translated File", fileType: 'pdf' }]}
                                            config={{
                                                theme: {
                                                    'pdf-download': true
                                                },
                                                header: {
                                                    disableHeader: true,
                                                    disableFileName: false,
                                                    retainURLParams: true,
                                                },
                                                csvDelimiter: ",", // "," as default,
                                                pdfZoom: {
                                                    defaultZoom: 1.1, // 1 as default,
                                                    zoomJump: 0.2, // 0.1 as default,
                                                },
                                                pdfDownload: true,
                                                pdfVerticalScrollByDefault: false, // false as default
                                            }}
                                        /> : null}
                                    {contentType === 'doc'
                                        ? <DocViewer
                                            style={{ width: 600, height: 1200 }}
                                            pluginRenderers={DocViewerRenderers}
                                            documents={[{ uri: uri, fileName: "Translated File", fileType: 'doc' }]}
                                            config={{
                                                theme: {
                                                    // 'pdf-download':true
                                                    height: "100%"
                                                },
                                                header: {
                                                    disableHeader: true,
                                                    disableFileName: false,
                                                    retainURLParams: true,
                                                },
                                                // csvDelimiter: ",", // "," as default,
                                                // pdfZoom: {
                                                //     defaultZoom: 1.1, // 1 as default,
                                                //     zoomJump: 0.2, // 0.1 as default,
                                                // },
                                                // pdfDownload: true,
                                                // pdfVerticalScrollByDefault: false, // false as default
                                            }}
                                        /> : null}
                                </Flex> : null}
                            {contentType === 'audio' && uri != null
                                ? <Flex w={'100%'} direction={'column'}>
                                    <Text fontSize={'lg'}>Play your Audio Content</Text>
                                    <ReactAudioPlayer
                                        src={uri}
                                        autoPlay={false}
                                        controls
                                    />
                                </Flex> : null}
                        </Box>
                    </DrawerBody>
                    <DrawerFooter>
                        <Button variant="outline" mr={3} onClick={handleCLose}>
                            Cancel
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default TranslatedDocumentView;
