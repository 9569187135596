import React, { useEffect } from "react";
import { Box, Button, Text, VStack, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, useToast } from "@chakra-ui/react";
import { motion } from "framer-motion";

import { toastFunctionToaster } from '../../toastFunction';

const MotionBox = motion(Box);

const CallHandler = ({ call, onAccept, onReject }) => {
    const toast = useToast();

    useEffect(() => {
        if (!call) {
            toast(toastFunctionToaster('Call not available.', 'error'));
        }
    }, [call, toast]);

    if (!call) return null;

    return (
        <Modal isOpen={!!call} onClose={onReject} isCentered>
        <ModalOverlay />
        <MotionBox
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
        >
            <ModalContent rounded="lg" shadow="lg" p={4}>
            <ModalHeader textAlign="center" fontSize="xl" fontWeight="bold">
                Incoming Call
            </ModalHeader>
            <ModalBody>
                <VStack spacing={4}>
                <Text fontSize="lg" color="gray.600">
                    From: {call.callerId || "Unknown Caller"}
                </Text>
                <VStack spacing={2} w="100%">
                    <Button
                    colorScheme="green"
                    size="md"
                    width="full"
                    onClick={onAccept}
                    >
                    Accept
                    </Button>
                    <Button
                    colorScheme="red"
                    size="md"
                    width="full"
                    onClick={onReject}
                    >
                    Reject
                    </Button>
                </VStack>
                </VStack>
            </ModalBody>
            </ModalContent>
        </MotionBox>
        </Modal>
    );
};

export default CallHandler;