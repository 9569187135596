import axios from "./axios";
import { serviceAPIs } from "./serviceApis";

// --------------------
// LingoLink Services
// --------------------
export const launchPageEx = (data, config = null, axiosInstance = axios) => {
    return axiosInstance.post(serviceAPIs().externalPageLaunch, data, config);
};
export const verifyPageEx = (data, config = null, axiosInstance = axios) => {
    return axiosInstance.post(serviceAPIs().verifyPageLaunch, data, config);
};
export const accessibilityPageEx = (data, config = null, axiosInstance = axios) => {
    return axiosInstance.post(serviceAPIs().verifyCallAccessibility, data, config);
};
export const premiumCallStartedEx = (data, config = null, axiosInstance = axios) => {
    return axiosInstance.post(serviceAPIs().piCallSetupApi, data, config);
};
export const callStartedEx = (data, config = null, axiosInstance = axios) => {
    return axiosInstance.post(serviceAPIs().callStartedApi, data, config);
};
export const basicAiCallSetupEx = (data, config = null, axiosInstance = axios) => {
    return axiosInstance.post(serviceAPIs().biCallSetupApi, data, config);
};
export const callCompletedEx = (data, config = null, axiosInstance = axios) => {
    return axiosInstance.post(serviceAPIs().callCompletedApi, data, config);
};
export const addFeedBack = (data, config = null, axiosInstance = axios) => {
    return axiosInstance.post(serviceAPIs().addlingocallFeedBack, data, config);
};

// --------------------
// lingoLink Management Services
// --------------------
export const manageLinkStartEx = (data, config = null, axiosInstance = axios) => {
    return axiosInstance.post(serviceAPIs().manageLinkStarter, data, config);
};
export const manageLinkVerifyEx = (data, config = null, axiosInstance = axios) => {
    return axiosInstance.post(serviceAPIs().manageLinkVerifier, data, config);
};

export const uploadDocAiContent = (data, config = null, axiosInstance = axios) => {
    return axiosInstance.post(serviceAPIs().uploadDocAiContent, data, config);
};
