import React from 'react'
import Card from '../../components/card/Card';
import {
    Alert,
    AlertIcon,
    Icon,
    Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter,
    DrawerHeader, DrawerOverlay, Flex, FormControl, FormLabel, Image, Input, Menu,
    MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent,
    ModalFooter, ModalHeader, ModalOverlay, Radio, RadioGroup, Spacer, Spinner, Stack,
    Text, Textarea, Tooltip, useDisclosure, useToast
}
    from '@chakra-ui/react';


import { useBrandBg } from '../../theme/globalColorTheme';
import { EXTERNAL_STORAGE } from './constants';
import { useState } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { toastFunctionToaster } from '../../utils/toastFunction';
import { useEffect } from 'react';

import { AiFillCheckCircle, AiOutlineCheckCircle, AiFillExclamationCircle, AiOutlineExclamationCircle } from "react-icons/ai";
import { authorizeGoogleExternalStorage, createExternalStorage, disconnectStorage, listExternalStorage, manageExternalStorage, updateExternalStorage } from '../../services/accountServices';

import AzureBlobIcon from '../../assets/img/storages/azure_blob_storage.png';
import AwsS3Icon from '../../assets/img/storages/s3_bucket.png';
import BoxIcon from '../../assets/img/storages/box.png';
import DropboxIcon from '../../assets/img/storages/drop_box.png';
import GoogleDriveIcon from '../../assets/img/storages/google_drive.png';
import JsonInput from './components/JsonInput';


const ExternalStorage = ({tab}) => {
    const brandBg = useBrandBg()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [selectedStorage, setSelectedStorage] = useState(null);
    const [storageName, setStorageName] = useState('');
    const [credentials, setCredentials] = useState({});
    const [config, setConfig] = useState({});
    const [btnLoading, setBtnLoading] = useState(false);
    const [storageList, setStorageList] = useState([]);
    const [storageToUpdate, setStorageToUpdate] = useState(null);
    const [jsonInput, setJsonInput] = useState(null)
    const [credInputType, SetCredInputType] = useState(EXTERNAL_STORAGE.INPUT_TYPES.MANUAL_INPUT)
    const toast = useToast()
    const [authClicked, setAuthClicked] = useState(null)
    const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure()
    const handleCredentialsChange = (field, value) => {
        setCredentials(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleConfigChange = (field, value) => {
        setConfig(prev => ({
            ...prev,
            [field]: value
        }));
    };

    function closeDrawer() {
        onClose()
        setSelectedStorage(null)
        setStorageName(null)
        setCredentials({})
        setConfig({})
        setStorageToUpdate(null)
    }
    function createStorage() {
        try {
            let localJson;
            if (!selectedStorage) {
                toast(toastFunctionToaster("Please select a storage type.", "error"));
                return;
            }
            if (!storageName.trim()) {
                toast(toastFunctionToaster("Storage Name is required.", "error"));
                return;
            }
            if (credInputType === EXTERNAL_STORAGE.INPUT_TYPES.MANUAL_INPUT) {
                for (const field of selectedStorage.credentials || []) {
                    if (field.required && !credentials[field.field]?.trim()) {
                        console.log("inside credential for ", field)
                        let msg = `${field.label} is required.`;
                        toast(toastFunctionToaster(msg, "error"));
                        return;
                    }
                }
                for (const field of selectedStorage.config || []) {
                    if (field.required && !config[field.field]?.trim()) {
                        let msg = `${field.label} is required.`;
                        toast(toastFunctionToaster(msg, "error"));
                        return;
                    }
                }
            } else {
                try {
                    localJson = JSON.parse(jsonInput)
                } catch {
                    toast(toastFunctionToaster("Invalid Json", "error"))
                    return
                }
            }

            setBtnLoading(btnLoading => ({ ...btnLoading, createStorage: true }))
            const params = {
                docai_store_type: selectedStorage.value,
                docai_store_name: storageName,
                docai_store_config: credInputType === EXTERNAL_STORAGE.INPUT_TYPES.JSON_INPUT ? {} : { ...config },
                docai_store_credentials: credInputType === EXTERNAL_STORAGE.INPUT_TYPES.JSON_INPUT ? { ...localJson } : { ...credentials },
                docai_store_credentials_type: credInputType
            };

            createExternalStorage(params).then(res => {
                if (res?.data[0]?.status) {
                    toast(toastFunctionToaster("Storage created successfully.", "success"));
                    handleListStorage()
                    closeDrawer()
                } else {
                    toast(toastFunctionToaster("Failed to create storage.", "error"));
                }
            }).catch(err => {
                toast(toastFunctionToaster("Failed to create storage.", "error"));
            }).finally(() => {
                setBtnLoading(btnLoading => ({ ...btnLoading, createStorage: false }))
            })
        }
        catch (err) {
            console.log(err)
        }

    }

    function prepareStorageUpdate(storage) {
        setSelectedStorage(EXTERNAL_STORAGE.STORAGE_TYPES.find(item => item.value === storage?.storage_type))
        setStorageName(storage?.storage_name)
        SetCredInputType(storage?.storage_input_type)
        storage?.storage_input_type === EXTERNAL_STORAGE.INPUT_TYPES.JSON_INPUT && setJsonInput(storage?.storage_credentials)
        storage?.storage_config && setConfig(JSON.parse(storage?.storage_config))
        storage?.storage_credentials && setCredentials(JSON.parse(storage?.storage_credentials))
        setStorageToUpdate(storage)
        onOpen()
    }

    function handleUpdateStorageType(item) {
        setSelectedStorage(item)
        setCredentials({})
        setConfig({})
        setStorageName('')
    }

    function handleListStorage(item) {
        setBtnLoading(btnLoading => ({ ...btnLoading, listStorage: true }))
        if(item){
            setBtnLoading(btnLoading => ({...btnLoading, checkAuth:item?.storage_uuid}))
        }
        const paramObj = {
            storage_type:"all"
        }
        listExternalStorage(paramObj).then(res => {
            if (res?.data[0]?.status) {
                setStorageList(res?.data[0]?.data);
            } else {
                toast(toastFunctionToaster("Failed to fetch storage list.", "error"));
                setStorageList([])
            }
        }).catch(err => {
            toast(toastFunctionToaster("Failed to fetch storage list.", "error"));
            setStorageList([])
        }).finally(() => {
            setBtnLoading(btnLoading => ({ ...btnLoading, listStorage: false }))
            if(item){
                setBtnLoading(btnLoading => ({...btnLoading, checkAuth:null}))
            }
        })
    }

    function updateStorage() {
        setBtnLoading(btnLoading => ({ ...btnLoading, updateStorage: true }))
        let localJson;
        if (!selectedStorage) {
            toast(toastFunctionToaster("Please select a storage type.", "error"));
            return;
        }
        if (!storageName.trim()) {
            toast(toastFunctionToaster("Storage Name is required.", "error"));
            return;
        }
        if (credInputType === EXTERNAL_STORAGE.INPUT_TYPES.MANUAL_INPUT) {
            for (const field of selectedStorage.credentials || []) {
                if (field.required && !credentials[field.field]?.trim()) {
                    console.log("inside credential for ", field)
                    let msg = `${field.label} is required.`;
                    toast(toastFunctionToaster(msg, "error"));
                    return;
                }
            }
            for (const field of selectedStorage.config || []) {
                if (field.required && !config[field.field]?.trim()) {
                    let msg = `${field.label} is required.`;
                    toast(toastFunctionToaster(msg, "error"));
                    return;
                }
            }
        } else {
            try {
                localJson = JSON.parse(jsonInput)
            } catch {
                toast(toastFunctionToaster("Invalid Json", "error"))
                return
            }
        }

        const params = {
            "docai_store_uuid": storageToUpdate?.storage_uuid,
            "docai_store_name": storageName,
            docai_store_config: credInputType === EXTERNAL_STORAGE.INPUT_TYPES.JSON_INPUT ? {} : { ...config },
            docai_store_credentials: credInputType === EXTERNAL_STORAGE.INPUT_TYPES.JSON_INPUT ? { ...localJson } : { ...credentials },
            docai_store_credentials_type: credInputType
        }
        updateExternalStorage(params).then(res => {
            if (res?.data[0]?.status) {
                toast(toastFunctionToaster("Storage updated successfully.", "success"));
                handleListStorage()
                closeDrawer()
            } else {
                toast(toastFunctionToaster("Failed to update storage.", "error"));
            }
        }).catch(err => {
            toast(toastFunctionToaster("Failed to update storage.", "error"));
        }).finally(() => {
            setBtnLoading(btnLoading => ({ ...btnLoading, updateStorage: false }))
        })
    }

    useEffect(() => {
        if (tab === 1) {
            handleListStorage();
            
            const handleFocus = () => {
                console.log(tab);
                handleListStorage();
            };
    
            window.addEventListener("focus", handleFocus);
    
            return () => {
                window.removeEventListener("focus", handleFocus);
            };
        }
    }, [tab]);
    

    function getImage(value) {
        switch (value) {
            case 'awss3':
                return AwsS3Icon
            case 'googledrive':
                return GoogleDriveIcon
            case 'box':
                return BoxIcon
            case 'dropbox':
                return DropboxIcon
            case 'azureblob':
                return AzureBlobIcon
            default:
                return null
        }
    }

    function handleOpenModal(item) {
        setSelectedStorage(item)
        onModalOpen()
    }

    function handleModalClose() {
        setSelectedStorage(null)
        onModalClose()
    }

    function handleManageStorage() {
        setBtnLoading(btnLoading => ({ ...btnLoading, manage: true }))
        const paramObj = {
            docai_store_uuid: selectedStorage?.storage_uuid,
            docai_store_enabled: !selectedStorage?.storage_enabled
        }
        manageExternalStorage(paramObj).then(res => {
            if (res?.data[0]?.status) {
                toast(toastFunctionToaster("Storage updated successfully", "success"))
                handleListStorage()
                handleModalClose()
            } else {
                toast(toastFunctionToaster("Failed to manage storage.", "error"));
            }
        }).catch(err => {
            toast(toastFunctionToaster("Failed to manage storage.", "error"));
            setStorageList([])
        }).finally(() => {
            setBtnLoading(btnLoading => ({ ...btnLoading, manage: false }))
        })

    }

    function handleAuthExternalStorage(item) {
        setBtnLoading(btnLoading => ({ ...btnLoading, auth: item?.storage_uuid }))
        setAuthClicked(item?.storage_uuid)
        const paramObj = {
            "provider": "googledrive",
            "docai_store_uuid": item?.storage_uuid
        }
        authorizeGoogleExternalStorage(paramObj).then(resp => {
            if (resp?.data[0]?.status) {
                openAuthWindow(resp?.data[0]?.auth_url)
            }
            else {
                toast(toastFunctionToaster("Failed to Authorize", "error"))
            }
        }).catch(err => {
            toast(toastFunctionToaster("Failed to Authorize", "error"))
        }).finally(() => {
            setBtnLoading(btnLoading => ({ ...btnLoading, auth: false }))
        })
    }

    function openAuthWindow(url) {
        const width = 700;
        const height = 600;
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;
      
        const windowFeatures = `width=${width},height=${height},top=${top},left=${left},scrollbars=yes,resizable=yes`;
      
        const authWindow = window.open(url, 'AuthWindow', windowFeatures);
        
        if (authWindow) {
          authWindow.focus();
        }
      }
      

    function handleDisconnectStorage(item){
        setBtnLoading(btnLoading=>({...btnLoading, disconnect:item?.storage_uuid}))
        const paramObj = {
            "doc_storage_uuid":item?.storage_uuid
        }
        disconnectStorage(paramObj).then(resp=>{
            if(resp?.data[0]?.status){
                toast(toastFunctionToaster(resp?.message, "success"))
                handleListStorage()
                return
            }
            toast(toastFunctionToaster(resp?.message, "error"))
        }).catch(e=>{
            toast(toastFunctionToaster("Failed to disconnect storage", "success"))
            
        }).finally(()=>{
            setBtnLoading(btnLoading=>({...btnLoading, disconnect:null}))

        })
    }

    return (
        <Flex direction="column" flex={1}>
            <Flex p="1">
                <Spacer />
                <Button 
                    rounded={'lg'}
                    colorScheme='blue' size="sm" onClick={onOpen}>
                    Add new storage
                </Button>
            </Flex>
            <Card p="1" flex={1}>
                <Flex minH="full" overflow="hidden" flex={1}>
                    <Box
                        w={"full"}
                        bg={brandBg}
                        p="2"
                        borderRadius={"10px"}
                        minH="full"
                        className="custom-scrollbar"
                        overflowY="auto"
                    >
                        <Flex h="full" w="full">
                            {
                                btnLoading?.listStorage ? <Flex h="full" justify-content="center" align-items="center" w="full">
                                    <Box>
                                        <Spinner size="xl" />
                                    </Box>
                                </Flex>
                                    : <Flex flexDir="column" gap="2" w="full">
                                        {
                                            storageList.length > 0 ?
                                                storageList.map(item => (
                                                    <>
                                                        <Card w="full" h="fit-content" p="4" borderColor={item?.storage_enabled ? "#c0edcf" : "#fccccc"}
                                                            borderWidth="0.5px" borderRadius="5px" borderLeftWidth="7px">
                                                            <Flex gap="2" w="full" >
                                                                <Image src={getImage(item?.storage_type)} alt={item?.storage_type} w="30px" h="30px" />
                                                                <Flex w={'200px'}>
                                                                    <Text>{item?.storage_name}</Text>
                                                                </Flex>
                                                                <Flex w={'100px'}>
                                                                    {item?.storage_credentials_verified
                                                                        ? <Icon as={AiFillCheckCircle} h={6} w={6} color={'green.400'} />
                                                                        : <Icon as={AiFillExclamationCircle} h={6} w={6} color={'red.400'} />}
                                                                </Flex>
                                                                {/* <Text>
                                                                    {item?.storage_credentials_verified 
                                                                    ?'Verified' : 'Note Verified'}
                                                                </Text> */}
                                                                <Spacer />
                                                                <Flex>
                                                                    <Flex gap="1">
                                                                        {
                                                                            !item?.storage_credentials_verified ?
                                                                               authClicked === item?.storage_uuid ? <Flex gap="2">
                                                                               <Button size="sm" colorScheme="blue" onClick={()=>handleListStorage(item)} w="fit-content" isLoading={item?.storage_uuid === btnLoading?.checkAuth}>Check Authorization</Button>
                                                                               <Button size="sm" colorScheme="green" variant="outline" onClick={() => handleAuthExternalStorage(item)} isLoading={btnLoading?.auth === item?.storage_uuid}>Re-connect</Button>
                                                                               </Flex>
                                                                                : <Button size="sm" colorScheme="green" variant="outline" onClick={() => handleAuthExternalStorage(item)} isLoading={btnLoading?.auth === item?.storage_uuid}>Connect</Button> :
                                                                                <Button size="sm" colorScheme="red" variant="outline" onClick={()=>handleDisconnectStorage(item)} isLoading={btnLoading?.disconnect === item?.storage_uuid}>Disconnect</Button>
                                                                        }
                                                                        <Button size="sm" colorScheme="green" variant="outline" onClick={() => prepareStorageUpdate(item)}>Update</Button>
                                                                        <Button size="sm" colorScheme="red" onClick={() => handleOpenModal(item)}>Manage</Button>
                                                                    </Flex>
                                                                </Flex>
                                                            </Flex>
                                                        </Card>
                                                    </>
                                                ))
                                                :
                                                <Alert status='info'>
                                                    <AlertIcon />
                                                    No External Storage Found!!
                                                </Alert>
                                        }
                                    </Flex>
                            }
                        </Flex>
                    </Box>
                </Flex>
            </Card>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={closeDrawer}
                size="md"
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerBody>

                        <Flex flexDir="column" mt={10} gap={6}>
                            <form>


                                <Flex flexDir="column">
                                    <Text fontWeight="bold" fontSize="md">External Storage Type:</Text>
                                    <Menu w="full" placement="bottom">
                                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} w="full">
                                            {selectedStorage ? selectedStorage.name : 'Select Storage type'}
                                        </MenuButton>
                                        <MenuList w="full">
                                            {
                                                EXTERNAL_STORAGE.STORAGE_TYPES.map(item => (
                                                    <MenuItem key={item.value} onClick={() => handleUpdateStorageType(item)}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </MenuList>
                                    </Menu>
                                </Flex>

                                <Flex flexDir="column">
                                    <Flex>
                                        <Text fontWeight="bold" fontSize="md">
                                            Storage Name:
                                        </Text>
                                        <Tooltip label="This field is required" fontSize="sm">
                                            <Text color="red.500" ml={1} as="span">*</Text>
                                        </Tooltip>
                                    </Flex>
                                    <Input
                                        type="text"
                                        placeholder="Storage Name"
                                        value={storageName}
                                        required
                                        onChange={e => setStorageName(e.target.value)}
                                    />
                                </Flex>

                                {selectedStorage?.jsonAllowed &&
                                    <RadioGroup onChange={SetCredInputType} value={credInputType} mt="3" mb="1">
                                        <Stack direction='row'>
                                            <Radio value={EXTERNAL_STORAGE.INPUT_TYPES.MANUAL_INPUT}>Manual Input</Radio>
                                            <Radio value={EXTERNAL_STORAGE.INPUT_TYPES.JSON_INPUT}>JSON Input</Radio>
                                        </Stack>
                                    </RadioGroup>
                                }

                                <>
                                    {
                                        selectedStorage && (credInputType === EXTERNAL_STORAGE.INPUT_TYPES.JSON_INPUT) ?
                                            <JsonInput setText={setJsonInput} text={jsonInput} /> :
                                            <>
                                                {selectedStorage && selectedStorage.credentials && (
                                                    <Flex flexDir="column" mt="4" gap="2">
                                                        <Text fontWeight="bold" fontSize="md">Credentials:</Text>
                                                        <Flex gap={4} ml={2} flexDir="column">
                                                            {selectedStorage.credentials.map(field => (
                                                                <Flex key={field.field} flexDir="column">
                                                                    <FormControl isRequired={field.required}>
                                                                        <FormLabel>{field.label}</FormLabel>
                                                                        <Input
                                                                            type={field.type}
                                                                            placeholder={field.label}
                                                                            required={field.required}
                                                                            onChange={e => handleCredentialsChange(field.field, e.target.value)}
                                                                            value={credentials[field.field]}
                                                                            defaultValue={field.defaultValue || ''}
                                                                        />
                                                                    </FormControl>
                                                                </Flex>
                                                            ))}
                                                        </Flex>
                                                    </Flex>
                                                )}

                                                {selectedStorage && selectedStorage.config && (
                                                    <Flex flexDir="column" gap={2} mt="4">
                                                        <Text fontWeight="bold" fontSize="md">Configuration:</Text>
                                                        <Flex ml="2" flexDir="column">
                                                            {selectedStorage.config.map(field => (
                                                                <Flex key={field.field} flexDir="column">
                                                                    <FormControl isRequired={field.required}>
                                                                        <FormLabel>{field.label}</FormLabel>
                                                                        <Input
                                                                            type={field.type}
                                                                            placeholder={field.label}
                                                                            defaultValue={field.defaultValue || ''}
                                                                            onChange={e => handleConfigChange(field.field, e.target.value)}
                                                                            value={config[field.field]}
                                                                        />
                                                                    </FormControl>
                                                                </Flex>
                                                            ))}
                                                        </Flex>
                                                    </Flex>
                                                )}
                                            </>
                                    }
                                </>


                            </form>
                        </Flex>


                    </DrawerBody>
                    <DrawerFooter>
                        <Button variant='outline' mr={3} onClick={closeDrawer}>
                            Cancel
                        </Button>
                        {
                            storageToUpdate ? <Button colorScheme='blue' isLoading={btnLoading?.updateStorage} onClick={updateStorage}>Update</Button> :
                                <Button colorScheme='blue' onClick={createStorage} isLoading={btnLoading?.createStorage}>Create</Button>
                        }
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>

            <Modal isOpen={isModalOpen} onClose={handleModalClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{
                        selectedStorage?.storage_enabled ? 'Disable Storage' : "Enable Storage"
                    }</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text fontSize="xl">
                            {`Do You want to ${selectedStorage?.storage_enabled ? 'Disable' : 'Enable'} `}
                            <Text display="inline" fontWeight="bold">{`${selectedStorage?.storage_name} `}</Text>
                            Storage
                        </Text>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={handleModalClose}>
                            Close
                        </Button>
                        <Button colorScheme={selectedStorage?.storage_enabled ? 'red' : 'green'} isLoading={btnLoading?.manage} onClick={handleManageStorage}>{
                            `${selectedStorage?.storage_enabled ? 'Disable' : 'Enable'} Storage`
                        }</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    )
}

export default ExternalStorage