import { toastFunctionToaster } from '../../toastFunction';

const sendDTMF = (session, isCallConnected, tone, setLogMessages, toast) => {
    if (session && isCallConnected) {
        try {
            // Send DTMF tone
            const options = {
                requestOptions: {
                    body: {
                        contentDisposition: "render",
                        contentType: "application/dtmf-relay",
                        content: `Signal=${tone}\r\nDuration=100\r\nInterToneGap=50`
                    }
                }
            };

            session.info(options);
            setLogMessages((prev) => [...prev, `DTMF sent: ${tone}`]);
            toast(toastFunctionToaster(`DTMF tone sent: ${tone}`, 'success'));
        } catch (error) {
            console.error('Error sending DTMF:', error);
            setLogMessages((prev) => [...prev, `Error sending dtmf: ${error.message}`]);
            toast(toastFunctionToaster('Error sending dtmf tone.', 'error'));
        }
    } else {
        toast(toastFunctionToaster('No active session for dtmf.', 'error'));
    }
};


// Play DTMF tone locally
const playDTMFTone = (tone, toast) => {
    try {

        const audioContext = new window.AudioContext();
        
        // DTMF frequency pairs
        const dtmfFrequencies = {
            '1': [697, 1209], '2': [697, 1336], '3': [697, 1477],
            '4': [770, 1209], '5': [770, 1336], '6': [770, 1477],
            '7': [852, 1209], '8': [852, 1336], '9': [852, 1477],
            '*': [941, 1209], '0': [941, 1336], '#': [941, 1477]
        };
        
        const frequencies = dtmfFrequencies[tone];
        if (!frequencies) return;
        
        // Create and connect oscillators for both frequencies
        const osc1 = audioContext.createOscillator();
        const osc2 = audioContext.createOscillator();
        const gainNode1 = audioContext.createGain();
        const gainNode2 = audioContext.createGain();
        
        osc1.frequency.value = frequencies[0];
        osc2.frequency.value = frequencies[1];
        
        osc1.connect(gainNode1);
        osc2.connect(gainNode2);
        gainNode1.connect(audioContext.destination);
        gainNode2.connect(audioContext.destination);
        
        gainNode1.gain.value = 0.1;
        gainNode2.gain.value = 0.1;
        
        osc1.start();
        osc2.start();
        
        // Stop after 100ms
        setTimeout(() => {
            osc1.stop();
            osc2.stop();
            audioContext.close();
        }, 100);
    } catch (error) {
        console.error('Error playing DTMF tone:', error);
        toast(toastFunctionToaster('Error playing dtmf tone.', 'error'));
    }
};

export const handleDTMF = (session, isCallConnected, tone, setLogMessages, setDtmfHistory, toast) => {
    if (session && isCallConnected) {
        try {

            // Send DTMF tone
            sendDTMF(session, isCallConnected, tone, setLogMessages, toast);
    
            // Play local DTMF tone
            playDTMFTone(tone, toast);
    
            // Add to DTMF history
            setDtmfHistory(prev => [...prev, {
                tone,
                timestamp: new Date().toISOString()
            }]);
    
            console.log(`Sent DTMF tone: ${tone}`);
        } catch (error) {
            console.error('Error sending dtmf tone.', error);
            toast(toastFunctionToaster('Error sending dtmf tone.', 'error'));
        }
    } else {
        console.error('No active session for DTMF.');
        toast(toastFunctionToaster('No active session for dtmf.', 'error'));
    }
};