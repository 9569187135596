import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";


// Chakra imports
import {
    Box,
    Spacer,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    useToast,
    useColorModeValue,
    Text,
} from "@chakra-ui/react";

// Custom components
import CenteredAuth from "../../../layouts/auth/types/Centered";
import { getPageConfig, trialRegister } from "../../../services/sharedServices";

function TrialRegister() {
    // Defined variables
    const toast = useToast();
    const navigate = useNavigate();

    const [error, setError] = useState({});
    const [loading, isLoading] = useState(false);
    const [registerForm, setRegisterForm] = useState({
        email: "",
        firstname: "",
        lastname: "",
        businessName: "",
        businessUrl: "",
        businessType: "",
    });

    const [name, setName] = useState(null);
    const [version, setVersion] = useState(null);
    const [pageLogo, setPageLogo] = React.useState(null);
    const [pageBackgound, setPageBackground] = React.useState(null);

    const pageBackgroundImages = [
        'https://lingoletpub.s3.us-west-1.amazonaws.com/open/gray-bg.jpg',
        'https://lingoletpub.s3.us-west-1.amazonaws.com/open/abs_lines_11.jpg',
        'https://lingoletpub.s3.us-west-1.amazonaws.com/open/abs_lines_12.jpg',
        'https://lingoletpub.s3.us-west-1.amazonaws.com/open/abs_lines_13.jpg'
    ];

    const subdomain = window.location.origin.split('.')[0] ? window.location.origin.split('.')[0] : null;
    // Chakra color mode
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";

    const textColorBrand = useColorModeValue("brand.500", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const buttonBg = useColorModeValue("blue.200", "gray.600");
    const googleText = useColorModeValue("navy.700", "white");


    useEffect(() => {
        if (subdomain != null) {
            let subdomain_val = subdomain.replace(window.location.protocol + "//", '');
            fetchConfDetails(subdomain_val);
        }
    }, []);

    const fetchConfDetails = (sub_domain) => {
        let paramObj = {
            subdomain: sub_domain,
        };
        getPageConfig(paramObj)
            .then((res) => {
                if (res?.data?.length > 0) {
                    if (res?.data[0]?.logo) {
                        setPageLogo(res?.data[0]?.logo);
                    }
                    if (res?.data[0]?.background) {
                        setPageBackground(res?.data[0]?.background)
                    }
                    if (res?.data[0]?.info?.name) setName(res?.data[0]?.info?.name)
                    if (res?.data[0]?.info?.version) setVersion(res?.data[0]?.info?.version)
                }
            })
            .catch((err) => {
                // Do Nothing
            });
    };

    // Todo:Set form value
    const onUpdateField = e => {
        const nextFormState = {
            ...registerForm,
            [e.target.name]: e.target.value,
        };
        setRegisterForm(nextFormState);
    };

    // Todo:Validation Function
    const validateFieldForm = () => {
        let errors = {};
        let formIsValid = true;
        let fields = registerForm;
        let emailPattern = new RegExp(/^[a-zA-Z0-9._!#$%&'*+/=?^`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "*Please enter email.";
        } else if (!emailPattern.test(fields["email"])) {
            formIsValid = false;
            errors["email"] = "*Please enter valid email address.";
        }
        if (!fields["firstname"]) {
            formIsValid = false;
            errors["firstname"] = "*Please enter first name.";
        }
        if (!fields["lastname"]) {
            formIsValid = false;
            errors["lastname"] = "*Please enter last name.";
        }
        if (!fields["businessName"]) {
            formIsValid = false;
            errors["businessName"] = "*Please enter business name.";
        }
        if (!fields["businessUrl"]) {
            formIsValid = false;
            errors["businessUrl"] = "*Please enter business URL.";
        }
        if (!fields["businessType"]) {
            formIsValid = false;
            errors["businessType"] = "*Please enter business type.";
        }
        setError(errors);
        return formIsValid;
    };

    const handleKeyPress = event => {
        if (event.key === "Enter") {
            handleSubmit();
        }
    };

    const handleSubmit = async () => {
        if (validateFieldForm()) {
            console.log("SUBMIT WORKING")
            isLoading(true);
            try {
                const res = await trialRegister(registerForm);
                isLoading(false);
                if (res?.result) {
                    toast({
                        title: "Registered Successfully",
                        status: "success",
                        isClosable: true,
                        duration: 3000,
                    });
                    navigate("/login");
                } else {
                    toast({
                        title: res?.message,
                        status: "error",
                        isClosable: true,
                        duration: 3000,
                    });
                }
            } catch (err) {
                isLoading(false);
                if (err?.response?.data?.detail?.message) {
                    toast({
                        title: err?.response?.data?.detail?.message,
                        status: "error",
                        isClosable: true,
                        duration: 3000,
                    });
                } else {
                    toast({
                        title: "An error occurred",
                        status: "error",
                        isClosable: true,
                        duration: 3000,
                    });
                }
            }
        }
    };

    return (
        <CenteredAuth
            cardTop={{ base: "140px", md: "14vh" }}
            cardBottom={{ base: "50px", lg: "auto" }}
            pageLogo={pageLogo ? pageLogo : null}
            pageBackground={pageBackgound ? pageBackgound : pageBackgroundImages[3]}
            confName={name}
            confVersion={version}
        >
            <Box w="100%" className="lsp">
                <Flex
                    maxW={{ base: "100%", md: "max-content" }}
                    w="100%"
                    mx={{ base: "auto", lg: "0px" }}
                    me="auto"
                    justifyContent="center"
                    px={{ base: "20px", md: "0px" }}
                    flexDirection="column">
                    <Box me="auto">
                        <Heading color={textColor} fontSize="36px" mb="10px">
                            Register
                        </Heading>
                        <Text mb="36px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md">
                            Enter your details to create an account!
                        </Text>
                    </Box>
                    <Flex
                        zIndex="2"
                        direction="column"
                        w={{ base: "100%", md: "420px" }}
                        maxW="100%"
                        background="transparent"
                        borderRadius="15px"
                        mx={{ base: "auto", lg: "unset" }}
                        me="auto"
                        mb={{ base: "20px", md: "auto" }}>
                        <FormControl>
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px">
                                Email<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                isRequired={true}
                                variant="auth"
                                fontSize="sm"
                                ms={{ base: "0px", md: "0px" }}
                                type="email"
                                placeholder="email@businessdomain.com"
                                mb="14px"
                                fontWeight="500"
                                size="lg"
                                name="email"
                                onChange={onUpdateField}
                                value={registerForm.email}
                            />
                            <Text color="red" mb="2">
                                {error?.email}
                            </Text>
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px">
                                First Name<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                isRequired={true}
                                variant="auth"
                                fontSize="sm"
                                ms={{ base: "0px", md: "0px" }}
                                type="text"
                                placeholder="First Name"
                                mb="14px"
                                fontWeight="500"
                                size="lg"
                                name="firstname"
                                onChange={onUpdateField}
                                value={registerForm.firstname}
                            />
                            <Text color="red" mb="2">
                                {error?.firstname}
                            </Text>
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px">
                                Last Name<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                isRequired={true}
                                variant="auth"
                                fontSize="sm"
                                ms={{ base: "0px", md: "0px" }}
                                type="text"
                                placeholder="Last Name"
                                mb="14px"
                                fontWeight="500"
                                size="lg"
                                name="lastname"
                                onChange={onUpdateField}
                                value={registerForm.lastname}
                            />
                            <Text color="red" mb="2">
                                {error?.lastname}
                            </Text>
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px">
                                Business Name<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                isRequired={true}
                                variant="auth"
                                fontSize="sm"
                                ms={{ base: "0px", md: "0px" }}
                                type="text"
                                placeholder="Business Name"
                                mb="14px"
                                fontWeight="500"
                                size="lg"
                                name="businessName"
                                onChange={onUpdateField}
                                value={registerForm.businessName}
                            />
                            <Text color="red" mb="2">
                                {error?.businessName}
                            </Text>
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px">
                                Business URL<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                isRequired={true}
                                variant="auth"
                                fontSize="sm"
                                ms={{ base: "0px", md: "0px" }}
                                type="text"
                                placeholder="Business URL"
                                mb="14px"
                                fontWeight="500"
                                size="lg"
                                name="businessUrl"
                                onChange={onUpdateField}
                                value={registerForm.businessUrl}
                            />
                            <Text color="red" mb="2">
                                {error?.businessUrl}
                            </Text>
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px">
                                Business Type<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                isRequired={true}
                                variant="auth"
                                fontSize="sm"
                                ms={{ base: "0px", md: "0px" }}
                                type="text"
                                placeholder="Business Type"
                                mb="14px"
                                fontWeight="500"
                                size="lg"
                                name="businessType"
                                onChange={onUpdateField}
                                value={registerForm.businessType}
                            />
                            <Text color="red" mb="2">
                                {error?.businessType}
                            </Text>
                            <Flex justifyContent="space-between" align="center" mb="24px">
                                <Spacer />
                                <NavLink to="/login">
                                    <Text color={textColorBrand} fontSize="sm" w="124px" fontWeight="500">
                                        Already have an account?
                                    </Text>
                                </NavLink>
                            </Flex>
                            <Button
                                fontSize="sm"
                                bg={buttonBg}
                                color={googleText}
                                fontWeight="500"
                                w="100%"
                                h="50"
                                mb="24px"
                                onClick={handleSubmit}
                                isDisabled={loading ? true : false}
                                isLoading={loading ? true : false}>
                                Register
                            </Button>
                        </FormControl>
                    </Flex>
                </Flex>
            </Box>
        </CenteredAuth>
    );
}

export default TrialRegister;

