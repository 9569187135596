import { combineReducers } from 'redux';
import notificationHeader from './notificationHeader';
import wizardHeader from './wizardHeader';
import SharedSpaceUserData from './SharedSpacesUserData';
import dialerReducer from './dialerReducer';

export default combineReducers({
  notificationHeader,
  wizardHeader,
  SharedSpaceUserData,
  dialerReducer
});
