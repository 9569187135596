import React, { useMemo, useState, useEffect } from "react";
import {
	Box,
	Button,
	Spinner,
	Flex,
	Checkbox,
	Spacer,
	Select,
	Divider,
	Icon,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Stack,
	Tooltip,
	Badge,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	Switch,
	useToast,
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	useDisclosure,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	useColorModeValue,
	Textarea,
	CheckboxGroup,
} from "@chakra-ui/react";
import {
	BsFiletypePdf,
	BsFiletypeAi,
	BsFiletypeMp3,
	BsFiletypeDocx,
	BsFiletypeTxt,
	BsFiletypeMp4,
} from "react-icons/bs";
import { BiCommentEdit, BiBookReader } from "react-icons/bi";
import { CgAsterisk } from "react-icons/cg";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import InputField from "../../../components/fields/InputField.js";
import { SearchBar } from "../../../components/navbar/searchBar/SearchBar.js";
import { addNewContentWorkflowOpen, addUpdateProjectContent, getContentDetails } from "../../../services/moduleServices.js";
import { useCustomColorModeValues } from "../../../utils/ColorMode.js";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import Card from "../../../components/card/Card.js";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Multiselect from "multiselect-react-dropdown";

import moment from "moment";
import { toastFunctionToaster } from "../../../utils/toastFunction.js";
// import { languageList } from '../../../../../utils/languageList';
import { translationLanguages } from "../../../utils/TranslationLanguages.js";
import { transcriptionLanguages } from "../../../utils/TranscriptionLanguage.js";

// API
import { addNewContentWorkflow } from "../../../services/moduleServices.js";

function ContentWorkflow(props) {
	const toast = useToast();
	const { columnsData, tableData, token } = props;
	const pageContent = props?.pageContent ? props?.pageContent : null;
	const workflowType = props?.workflowType ? props?.workflowType : null;
	const projectUuid = props?.projectUuid ? props?.projectUuid : null;
	const [contentUuid, setContentUuid] = useState(null);

	const [transTargetLangs, setTransTargetLangs] = useState(false);

	const [contentType, setContentType] = useState(null);
	const [documentUrl, setDocumentUrl] = useState(null);
	const [documentText, setDocumentText] = useState(null);
	const [documentTitle, setDocumentTitle] = useState(null);

	const [loading, isLoading] = useState(false);
	const [sourceLanguage, setSourceLanguage] = useState([]);
	const [srcLanguagesData, setSrcLanguagesData] = useState([]);

	const [allLanguages, setAllLanguages] = useState([]);
	const [allLanguagesData, setAllLanguagesData] = useState([]);

	const [priortyAdded, isPriortyAdded] = useState(false);
	const [proofreadingAdded, isProofreadingAdded] = useState(false);
	const [resultResponseAdded, isResultResponseAdded] = useState(false);
	const [textToSpeech, isTextToSpeech] = useState(false);

	// const [skillsKeyword, setSkillsKeyword] = useState([]);
	// const [workflowDataArr, setWorkflowDataArr] = useState([]);

	const [errorMessage, setErrorMessage] = useState(null);

	useEffect(() => {
		if (pageContent != null) {
			checkContentType();
			let contentUuid = pageContent?.uuid ? pageContent?.uuid : null;
			if (projectUuid != null && contentUuid != null) {
				setContentUuid(contentUuid);
				setDocumentTitle(pageContent?.title);
			}
		}
	}, [pageContent]);

	const checkContentType = () => {
		let contentType = pageContent?.type ? pageContent?.type : null;
		if (contentType != null) {
			if (contentType.includes("pdf") || contentType.includes("doc")) {
				setContentType("doc");
			} else if (contentType.includes("txt") || contentType.includes("text")) {
				setContentType("text");
			} else if (contentType.includes("audio")) {
				setContentType("audio");
			}
		}
	};

	const createNewWorkflow = () => {
		setErrorMessage(null);
		if (!transTargetLangs) {
			setAllLanguages(["none"]);
		}
		if (allLanguages.length > 0 && sourceLanguage.length > 0) {
			let objData = {
				project_uuid: projectUuid,
				content_uuid: contentUuid,
				code: workflowType,
				source_language: sourceLanguage,
				languages: allLanguages,
				priority: priortyAdded,
				proofreading: proofreadingAdded,
				attachment: resultResponseAdded,
			};
			isLoading(true);
			addNewContentWorkflow(objData)
				.then(res => {
					isLoading(false);
					if (res?.result && res?.data?.length > 0) {
						if (res?.data[0]?.result === "done") {
							isLoading(false);
							toast(toastFunctionToaster("Workflow is created successfully", "success"));
							props?.closePanelHandler();
						} else if (res?.data[0]?.result === "dup") {
							isLoading(false);
							setErrorMessage("You already have a workflow added with same target languages.");
							//toast(toastFunctionToaster("You already have a workflow added with same target languages.", "warning"));
						} else {
							toast(toastFunctionToaster("Unable to complete the workflow", "error"));
						}
					} else {
						toast(toastFunctionToaster("Unable to complete the workflow", "error"));
					}
				})
				.catch(err => {
					isLoading(false);
					toast(toastFunctionToaster("Unable to complete the worflow", "error"));
				});
		}
	};

	const createNewWorkflowOpen = () => {
		setErrorMessage(null);
		if (!transTargetLangs) {
			setAllLanguages(["none"]);
		}
		if (allLanguages.length > 0 && sourceLanguage.length > 0) {
			let objData = {
				project_uuid: projectUuid,
				content_uuid: contentUuid,
				code: workflowType,
				source_language: sourceLanguage,
				languages: allLanguages,
				priority: priortyAdded,
				proofreading: proofreadingAdded,
				attachment: resultResponseAdded,
				token:token
			};
			isLoading(true);
			addNewContentWorkflowOpen(objData)
				.then(res => {
					isLoading(false);
					if (res?.result && res?.data?.length > 0) {
						if (res?.data[0]?.result === "done") {
							isLoading(false);
							toast(toastFunctionToaster("Workflow is created successfully", "success"));
							props?.closePanelHandler();
						} else if (res?.data[0]?.result === "dup") {
							isLoading(false);
							setErrorMessage("You already have a workflow added with same target languages.");
							//toast(toastFunctionToaster("You already have a workflow added with same target languages.", "warning"));
						} else {
							toast(toastFunctionToaster("Unable to complete the workflow", "error"));
						}
					} else {
						toast(toastFunctionToaster("Unable to complete the workflow", "error"));
					}
				})
				.catch(err => {
					isLoading(false);
					toast(toastFunctionToaster("Unable to complete the worflow", "error"));
				});
		}
	};

	// const renderAllLanguages = () => {
	//     if (allLanguages.length > 0){
	//         return(
	//             allLanguages.map((item, index) => {
	//                 return(
	//                     <Box key={index} m={1} p={1} bg={'gray.200'} rounded={'md'}>
	//                         {item}
	//                     </Box>
	//                 )
	//             })
	//         )
	//     }
	// }

	// const renderLanguageListData = languageList?.map((langData, index) => (
	//     <option
	//         key={langData.code}
	//         value={langData.name}
	//         style={{ color: 'black' }}
	//         >
	//         {langData.name}
	//     </option>
	// ));

	// const renderTranslateLanguageList = translationLanguages?.map((langData, index) => (
	//     <option
	//         key={langData.code}
	//         value={langData.name}
	//         style={{ color: 'black' }}
	//         >
	//         {langData.name}
	//     </option>
	// ));

	// const onUpdateField = (e) => {
	//     const localLanguages = allLanguages;
	//     localLanguages.push(e.target.value);
	//     setAllLanguages(localLanguages);
	//     setErrorMessage(null);
	// };

	// const onTagChange = tags => {
	//     setSkillsKeyword(tags);
	// };

	const handleSelect = selectedList => {
		if (selectedList.length) {
			let languagesArr = [];
			selectedList?.map(data => {
				languagesArr?.push(data?.code);
			});
			setAllLanguages(languagesArr);
			setAllLanguagesData(selectedList);
		}
	};

	const handleSingleSelect = selectedList => {
		if (srcLanguagesData.length === 0) {
			if (selectedList.length) {
				let languagesArr = [];
				selectedList?.map(data => {
					languagesArr?.push(data?.code);
				});
				setSourceLanguage(languagesArr);
				setSrcLanguagesData(selectedList);
			}
		} else {
		}
	};

	return (
		<>
			{/* <Text>
                {pageContent != null ?JSON.stringify(pageContent):<Text>Error</Text>}
            </Text> */}
			{/* <Text>{contentType}</Text> */}
			{loading ? (
				<Flex h="70vh" alignItems="center" justifyContent="center">
					<Spinner size="xl" />
				</Flex>
			) : (
				<Box w={"100%"} h={"100%"}>
					{/* <Text> Create {workflowType} Workflow </Text>
                    <Text> {contentType} - {contentUuid} </Text> */}
					<Card w={"100%"} p={5}>
						{contentType === "audio" ? (
							<Flex w={"100%"} fontSize={"lg"}>
								<Text>Create Transcription for </Text>
								<Text fontWeight={500} ml={1}>
									{documentTitle}
								</Text>
							</Flex>
						) : (
							<Flex w={"100%"} fontSize={"lg"}>
								<Text>Create Translation for </Text>
								<Text fontWeight={500} ml={1}>
									{documentTitle}
								</Text>
							</Flex>
						)}
						{/* <Flex w={'100%'} direction={'column'} mt={5} mb={2}>
                            <TagsInput
                                value={skillsKeyword}
                                onChange={onTagChange}
                                inputProps={{ placeholder: 'Select all Target Languages' }}
                            />
                        </Flex> */}
						{contentType === "audio" ? (
							<Flex w={"100%"} direction={"column"}>
								<Flex
									w={"100%"}
									direction={"column"}
									p={2}
									rounded={"md"}
									borderWidth={"0.5px"}
									borderColor={"gray.200"}
									mt={5}
									mb={2}>
									<Text>
										Select Source Audio Languages (
										<b>{transcriptionLanguages.length} Supported Languages</b>)
									</Text>
									<Multiselect
										// selectionLimit={2}
										// singleSelect={true}
										w={"200px"}
										options={transcriptionLanguages}
										selectedValues={srcLanguagesData}
										onSelect={selectedList => handleSingleSelect(selectedList)}
										onRemove={selectedList => handleSingleSelect(selectedList)}
										displayValue="name"
										placeholder="Select your source language"
									/>
								</Flex>
								<Flex
									w={"100%"}
									direction={"column"}
									mt={5}
									p={2}
									rounded={"md"}
									borderWidth={"0.5px"}
									borderColor={"gray.200"}
									mb={2}>
									<Flex w={"100%"} mt={1}>
										<Text>Do You want to translated above transcription to other languages? </Text>
										<Checkbox
											ml={2}
											isChecked={transTargetLangs}
											onChange={() => setTransTargetLangs(!transTargetLangs)}></Checkbox>
									</Flex>
									{transTargetLangs ? (
										<Flex w={"100%"} direction={"column"}>
											<Text mt={2}>
												Select all Target Languages (
												<b>{translationLanguages.length} Supported Languages</b>)
											</Text>
											<Multiselect
												w={"200px"}
												options={translationLanguages}
												selectedValues={allLanguagesData}
												onSelect={selectedList => handleSelect(selectedList)}
												onRemove={selectedList => handleSelect(selectedList)}
												displayValue="name"
												placeholder="Select your target languages (upto 5)"
											/>
										</Flex>
									) : null}
								</Flex>
							</Flex>
						) : (
							<Flex w={"100%"} direction={"column"}>
								<Flex
									w={"100%"}
									direction={"column"}
									p={2}
									rounded={"md"}
									borderWidth={"0.5px"}
									borderColor={"gray.200"}
									mt={5}
									mb={2}>
									<Text>
										Select Source Document Languages (
										<b>{translationLanguages.length} Supported Languages</b>)
									</Text>
									<Multiselect
										w={"200px"}
										// singleSelect={true}
										options={translationLanguages}
										selectedValues={srcLanguagesData}
										onSelect={selectedList => handleSingleSelect(selectedList)}
										onRemove={selectedList => handleSingleSelect(selectedList)}
										displayValue="name"
										placeholder="Select your source language"
									/>
								</Flex>
								<Flex
									w={"100%"}
									direction={"column"}
									p={2}
									rounded={"md"}
									borderWidth={"0.5px"}
									borderColor={"gray.200"}
									mt={5}
									mb={2}>
									<Text>
										Select all Target Languages (
										<b>{translationLanguages.length} Supported Languages</b>)
									</Text>
									<Multiselect
										w={"200px"}
										options={translationLanguages}
										selectedValues={allLanguagesData}
										onSelect={selectedList => handleSelect(selectedList)}
										onRemove={selectedList => handleSelect(selectedList)}
										displayValue="name"
										placeholder="Select your target languages (upto 5)"
									/>
								</Flex>
							</Flex>
						)}
						{/* <Flex w={'100%'} direction={'column'} mt={5} mb={2}>
                            <Text>Please add all the target languages:</Text>
                            {allLanguages.length > 0
                            ?<Flex w={'100%'}>
                                {renderAllLanguages()}
                            </Flex>:null}
                            <Flex w={'100%'} p={5}>
                                {renderTranslateLanguageList}
                                    <Select
                                        name="language_one"
                                        placeholder="Select Language"
                                        size="sm"
                                        borderRadius="3px"
                                        borderColor="gray.200"
                                        fontSize={'14px'}
                                        mr="10px"
                                        onChange={e => {
                                            onUpdateField(e);
                                        }}
                                    >
                                        {renderLanguageListData}
                                    </Select>
                            </Flex>
                        </Flex> */}
						<Divider />
						<Flex w={"100%"} direction={"column"} mt={5}>
							<Stack spacing={5} direction="row">
								<Checkbox
									isChecked={priortyAdded}
									onChange={e => isPriortyAdded(!priortyAdded)}
									colorScheme="green">
									Add Highest Priority into the {workflowType} workflow.
								</Checkbox>
							</Stack>
						</Flex>
						<Flex w={"100%"} direction={"column"} mt={2}>
							<Stack spacing={5} direction="row">
								<Checkbox
									isChecked={proofreadingAdded}
									onChange={e => isProofreadingAdded(!proofreadingAdded)}
									colorScheme="green">
									Add Proofreading into the {workflowType} workflow.
								</Checkbox>
							</Stack>
						</Flex>
						<Flex w={"100%"} direction={"column"} mt={2}>
							<Stack spacing={5} direction="row">
								<Checkbox
									isChecked={resultResponseAdded}
									onChange={e => isResultResponseAdded(!resultResponseAdded)}
									colorScheme="green">
									Add {workflowType} results into the email.
								</Checkbox>
							</Stack>
						</Flex>
						<Flex
							w={"100%"}
							direction={"column"}
							mt={2}
							p={2}
							rounded={"md"}
							borderWidth={"0.5px"}
							borderColor={"gray.200"}>
							<Text>Create Audio from generated Text</Text>
							<Stack spacing={5} mt={2} direction="row">
								<Checkbox
									isChecked={textToSpeech}
									onChange={e => isTextToSpeech(!textToSpeech)}
									colorScheme="green">
									Convert {workflowType} output text as Mp3 audio for all selected languages.
								</Checkbox>
							</Stack>
						</Flex>

						{errorMessage != null ? (
							<Flex w={"100%"} direction={"column"} mt={2}>
								<Text color={"red.500"}>{errorMessage}</Text>
							</Flex>
						) : null}
						<Flex w={"100%"} mt={2}>
							<Spacer />
							<Button onClick={token ? createNewWorkflowOpen : createNewWorkflow } size={"sm"} bg={"blue.300"} w={"200px"}>
								Submit workflow
							</Button>
						</Flex>
					</Card>
				</Box>
			)}
		</>
	);
}

export default ContentWorkflow;
