import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// Chakra imports
import {
	Box,
	Flex,
	Spacer,
	Icon,
	Text,
	Menu,
	Modal,
	Button,
	Switch,
	MenuList,
	MenuItem,
	useToast,
	ModalBody,
	MenuButton,
	ModalHeader,
	ModalFooter,
	ModalContent,
	ModalOverlay,
	useDisclosure,
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	ModalCloseButton,
	useColorModeValue,
} from "@chakra-ui/react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { AiFillUnlock, AiFillApi, AiFillLock, AiFillEdit, AiOutlineBuild } from "react-icons/ai";
import { RxSwitch } from "react-icons/rx";

// Custom components
import Card from "../../components/card/Card.js";
import { toastFunctionToaster } from "../../utils/toastFunction.js";
import ProjectAutomation from "./ProjectAutomation.js";

// Assets
import { MdOutlineWysiwyg } from "react-icons/md";

// Api Services
import { updateProjectStatusDetails, updateProjectAccessCode } from "../../services/businessServices.js";
import {
	useGreenBg, useLinkColor, useTextColor,
	usePillButtonTextColorHover, usePillButtonHighLight,
	usePillButtonTextColorSelected, usePillButtonBg, usePillButtonBgHover
} from "../../theme/globalColorTheme";
import { RiShareForward2Fill } from "react-icons/ri";

function ProjectCard(props) {
	const toast = useToast();
	const navigate = useNavigate();
	const pathName = window.location.pathname.split("/")[1];
	const { projectData } = props;
	const { isOpen, onOpen, onClose } = useDisclosure();

	const btnViewRef = React.useRef();
	const { isOpen: isViewOpen, onOpen: onViewOpen, onClose: onViewClose } = useDisclosure();

	const brandColor = useColorModeValue("brand.500", "white");
	const textColor = useColorModeValue("secondaryGray.900", "white");
	const [projectStatus, setProjectStatus] = useState(projectData?.enabled);
	const cardBg = useColorModeValue(
		"linear-gradient(180deg, #F4F7FE 0%, rgba(244, 247, 254, 0) 86.56%)",
		"linear-gradient(180deg, #1B254B 0%, rgba(27, 37, 75, 0) 86.56%)"
	);
	const pillButtonBg = usePillButtonBg()
	const pillButtonText = useTextColor()
	const pillButtonBgHighlight = usePillButtonHighLight()

	const pathname = window.location.pathname;
	const subroute = pathname.split('/')[1];


	// Todo:Change Project Status
	const updateProjectDetails = () => {
		let paramObj = {
			project_uuid: projectData?.project_uuid,
			enable: (!projectStatus).toString(),
		};
		updateProjectStatusDetails(paramObj)
			.then(res => {
				if (res?.result) {
					onClose();
					props?.reloadProjectDetails();
					toast(toastFunctionToaster(res.message, "success"));
				} else {
					onClose();
					props?.reloadProjectDetails();
					toast(toastFunctionToaster(res.message, "error"));
				}
			})
			.catch(err => {
				onClose();
				props?.reloadProjectDetails();
				toast(toastFunctionToaster(err.message, "error"));
			});
	};

	const updateProjAccCode = () => {
		let paramObj = {
			project_uuid: projectData?.project_uuid
		};
		updateProjectAccessCode(paramObj)
			.then(res => {
				if (res?.result) {
					onClose();
					props?.reloadProjectDetails();
					toast(toastFunctionToaster(res.message, "success"));
				} else {
					onClose();
					props?.reloadProjectDetails();
					toast(toastFunctionToaster(res.message, "error"));
				}
			})
			.catch(err => {
				onClose();
				props?.reloadProjectDetails();
				toast(toastFunctionToaster(err.message, "error"));
			});
	};

	return (
		<>
			<Card p={1} borderRadius="10px" boxShadow={'lg'} borderColor={projectData?.enabled ? "#c0edcf" : "#fccccc"}
				borderWidth="0.5px">
				<Flex alignItems={"center"} justifyContent={"space-between"} p={2}>
					<Flex alignItems="center" gap={2}>
						<Text mb="12px" fontSize="lg" lineHeight="100%" color={textColor} fontWeight="bold">
							{projectData?.project_title}
						</Text>
						<Text fontSize={'xs'} mb="12px" lineHeight="100%" color={textColor} rounded={'lg'} p={1} mt={1} bg={pillButtonBg}>
							{projectData?.created_by}
						</Text>
					</Flex>
					<Menu>
						<MenuButton>
							<BiDotsVerticalRounded />
						</MenuButton>
						<MenuList fontSize="14px">
							<MenuItem
								fontWeight={"500"}
								icon={<AiFillEdit />}
								onClick={() => {
									props?.returnProjectDetailsData(projectData);
								}}>
								Edit
							</MenuItem>
							{/* <MenuItem
								fontWeight={"500"}
								icon={<AiOutlineBuild />}
								onClick={() =>
									navigate(`/${pathName}/project/content`, {
										state: {
											projectUuid: projectData?.project_uuid,
										},
									})
								}>
								Content
							</MenuItem> */}
							<MenuItem
								ref={btnViewRef}
								onClick={() => onViewOpen()}
								fontWeight={"500"}
								icon={<AiFillApi />}>
								Automation
							</MenuItem>
							<MenuItem fontWeight={"500"} icon={<RxSwitch />} onClick={onOpen}>
								Status
							</MenuItem>
							<MenuItem fontWeight={"500"} icon={<RxSwitch />} onClick={() => updateProjAccCode()}>
								Access Code
							</MenuItem>
						</MenuList>
					</Menu>
				</Flex>
				<>
					<Flex align="center" px={2}>
						<Icon
							me="8px"
							h="16px"
							w="16px"
							color={projectData?.enabled ? "green.500" : "red.500"}
							as={projectData?.enabled ? AiFillUnlock : AiFillLock}
						/>
						<Text mr={1} color={projectData?.enabled ? "green.500" : "red.500"} fontSize="sm" fontWeight="bold">
							{projectData?.enabled ? "Enabled" : "Disabled"}
						</Text>
						<Spacer />
						<Text mr={1}>Translation Access Code:</Text>
						{projectData?.code > 0
							?<Text fontSize="sm" fontWeight="bold" 
								bg={usePillButtonTextColorHover} rounded={'lg'} px={1}>
								 {projectData?.code}
							</Text>
							: <Text fontSize="sm" bg={'red.100'} rounded={'lg'} px={1}>N/A</Text>
						}
					</Flex>
					<Flex
						mt={1}
						direction="column"
						p="16px"
						borderRadius="22px"
						bg={cardBg}
						h="120px"
						overflow="auto"
						className="custom-scrollbar">
						<Flex w="100%">
							<Box>
								<Icon h="58px" w="58px" mb="15px" color={brandColor} as={MdOutlineWysiwyg} />
							</Box>
							<Text pl={"2"} color="secondaryGray.700" fontSize="md" fontWeight="500">
								{projectData?.project_info}
							</Text>
						</Flex>
					</Flex>
					<Flex w={'100%'} px={2}>
						<Button
							rounded={'lg'}
							onClick={() =>
								navigate(`/${pathName}/project/content`, {
									state: {
										projectUuid: projectData?.project_uuid,
										externalStorageUuid: projectData?.external_storage_uuid,
										isenabled: projectData.enabled,
									},
								})
							}
							_hover={{ bg: pillButtonBgHighlight, color: "black" }}
							color={pillButtonText}
							bg={pillButtonBg}
							w={'100px'} size={'sm'}>Contents
						</Button>
						<Spacer />
						<Button 
							rounded={'lg'}
							_hover={{ bg: pillButtonBgHighlight, color: "black" }}
							color={pillButtonText}
							bg={pillButtonBg}
							w="fit-content" size={'sm'}
							onClick={() => navigate(`/${subroute}/project/share-control`, {
								state: { projectData }
							})}>
							Share Control
						</Button>
					</Flex>
				</>
			</Card>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Update {projectData?.project_title} Status</ModalHeader>
					<ModalCloseButton />
					<ModalBody p="4" borderTopWidth={"1px"} borderBottomWidth={"1px"}>
						<Flex alignItems={"center"} justifyContent={"space-between"}>
							<Text fontWeight={"500"} fontSize={"16px"}>
								Enable or Disable {projectData?.project_title}
							</Text>
							<Switch
								colorScheme="green"
								isChecked={projectStatus}
								onChange={e => {
									updateProjectDetails();
									setProjectStatus(!projectStatus);
								}}
							/>
						</Flex>
					</ModalBody>

					<ModalFooter textAlign="right">
						<Button colorScheme="blue" size="sm" onClick={onClose} m="0">
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Drawer isOpen={isViewOpen} placement="right" onClose={onViewClose} size="lg">
				<DrawerOverlay />
				<DrawerContent>
					<DrawerHeader>Automate Project Content</DrawerHeader>
					<DrawerCloseButton color={"red.100"} />
					<DrawerBody overflow={"hidden"}>
						<ProjectAutomation />
					</DrawerBody>
					<DrawerFooter>
						<Button variant="outline" mr={3} onClick={onViewClose}>
							Cancel
						</Button>
					</DrawerFooter>
				</DrawerContent>
			</Drawer>
		</>
	);
}

export default ProjectCard;
