// React + Chakra Imports
import { AiFillEdit } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import { Box, Flex, Text, Alert, AlertIcon, Spinner, useToast, Icon, useColorModeValue, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";

// Service + Component Imports
import { toastFunctionToaster } from "../../../../../../utils/toastFunction";
import AddUpdateAppoinment from "../bookingAppoinment/AddUpdateAppoinment";
import BookingAppoinmentList from "../bookingAppoinment/BookingAppoinmentList";
import { getBookingServicesList } from "../../../../../../services/accountServices";

function BookingServicesList({ bookingId, refreshList, onEdit }) {
	const toast = useToast();
	const [loading, isLoading] = useState(false);
	const [taxDetailsList, setTaxDetailsList] = useState([]);
	const [addressDetails, setAddressDetails] = useState(null);
	const [updateAddCounter, setUpdateAddCounter] = useState(null);
	const [selectedAddIndex, setSelectedAddIndex] = useState(null);

	const fields = [
		{ label: "Service Type", key: "booking_service_type" },
		{ label: "Service Location", key: "booking_service_location" },
		{ label: "Service Contact", key: "booking_service_contact" },
		{ label: "Service Venue", key: "booking_service_venue" },
		{ label: "Service Sub Location", key: "booking_service_sub_location" },
		{ label: "Instruction Internal", key: "booking_service_instruction_internal" },
		{ label: "Instruction Customer", key: "booking_service_instruction_customer" },
		{ label: "Instruction Interpreter", key: "booking_service_instruction_interpreter" },
	];

	// Todo:Fetch Data
	useEffect(() => {
		fetchBookingServiceDetails();
	}, [refreshList]);

	// Todo:Fetch Client Details
	const fetchBookingServiceDetails = () => {
		isLoading(true);
		const obj = {
			booking_uuid: bookingId,
			filter_key: "",
			filter_value: "",
			job_filter_key: "active",
			job_filter_value: true,
			page_id: 1,
			records_per_page: 10,
		};

		getBookingServicesList(obj)
			.then(res => {
				isLoading(false);
				if (res?.result) {
					setTaxDetailsList(res?.data?.[0]?.data?.length ? res?.data?.[0]?.data : []);
				} else {
					setTaxDetailsList([]);
					toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				isLoading(false);
				toast(toastFunctionToaster(err?.message, "error"));
			});
	};

	const renderCompanyDetails =
		taxDetailsList?.length > 0 ? (
			<Tabs variant="soft-rounded" borderRadius={"0px"} colorScheme="teal" w="100%">
				<TabList>
					{taxDetailsList.map((_, index) => (
						<Tab
							_selected={{
								borderWidth: "1px",
								borderStyle: "solid",
								borderColor: "#a5a5a5",
								fontWeight: "500",
								color: "black",
								borderBottom: "0px",
							}}
							sx={{
								bg: "green.100",
								color: "black",
								borderStyle: "solid",
								borderWidth: "1px",
								rounded: "md",
								fontWeight: "400",
								borderBottomLeftRadius: "0px",
								borderBottomRightRadius: "0px",
								mr: "2",
							}}
							borderRadius={"0px"}
							key={index}
						>{`Booking ${index + 1}`}</Tab>
					))}
				</TabList>

				{/* Tab Content */}
				<TabPanels p="0px">
					{taxDetailsList.map((data, index) => (
						<TabPanel key={index} p="0px" borderRadius={"0px"}>
							<Flex flexWrap={"wrap"} p="4" borderWidth="1px" alignItems={"start"} fontSize="15px" mb="4" borderColor={"#a5a5a5"} position={"relative"} justifyContent={"space-between"}>
								<Flex w="65%" flexWrap={"wrap"}>
									{fields.map(({ label, key }) => (
										<Flex mb="3" key={key} flexWrap={"wrap"} w="50%">
											<Text fontWeight="bold" w="45%">
												{label}:
											</Text>
											<Text w="50%" ml="2">
												{data[key] !== undefined ? data[key]?.toString() : "No data found"}
											</Text>
										</Flex>
									))}
								</Flex>
								<Box w="33%">Job Details</Box>

								{/* AddUpdateAppointment Component */}
								<Flex rounded={"md"} p={2} alignItems={"center"} align={"end"} cursor={"pointer"} position={"absolute"} right="8px" pr="0">
									<AddUpdateAppoinment bookingId={bookingId} bookingServiceId={data?.booking_service_uuid} passClientData={addressDetails} setAddressData={setAddressDetails} updateAppoinmentDataCounter={setUpdateAddCounter} idValue={selectedAddIndex} indexValue={index} />
								</Flex>

								{/* Edit Icon */}
								<Flex
									rounded={"md"}
									p={"6px"}
									alignItems={"center"}
									borderWidth={"1px"}
									borderColor={"gray.400"}
									align={"end"}
									cursor={"pointer"}
									onClick={() => {
										onEdit(data);
									}}
									borderRadius={"50px"}
									position={"absolute"}
									right="8px"
									top="60px"
								>
									<Icon as={AiFillEdit} rounded={"md"} fontSize={"14px"} cursor={"pointer"} />
								</Flex>

								{/* BookingAppointmentList Component */}
								<BookingAppoinmentList bookingId={bookingId} bookingServiceId={data?.booking_service_uuid} setclientData={setAddressDetails} idx={index} setIdxValue={setSelectedAddIndex} renderAppoinmentList={updateAddCounter} />
							</Flex>
						</TabPanel>
					))}
				</TabPanels>
			</Tabs>
		) : (
			<Alert status="warning" mb="12px">
				<AlertIcon />
				<Text>No booking services added yet.</Text>
			</Alert>
		);

	return (
		<Flex justifyContent={"space-between"} py="4" flexWrap={"wrap"} borderRadius={"5px"} mt="4px" w="100%" pb="0">
			{loading ? (
				<Flex alignItems="center" justifyContent="center" p="10px" pt="0" h="30vh" w="100%">
					<Spinner size="lg" />
				</Flex>
			) : (
				<>{renderCompanyDetails}</>
			)}
		</Flex>
	);
}

export default BookingServicesList;
